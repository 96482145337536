@import './../style/foundation/spacings';
@import './../style/foundation/typography';

.tooltip {
  position: relative;
  z-index: 3;

  display: block;

  transition: 0.3s;

  &__text {
    @extend %font-sans-SM;
    position: absolute;
    top: 20px;

    display: none;
    margin: $spacing-XS * 1.5 0 0 $spacing-XS * 1.5;
    margin: 0 0 0 $spacing-XS * 1.5;
    padding: $spacing-XS $spacing-SM;

    color: white;
    font-weight: 400;

    background: #5b6166;
    border-radius: 4px;
    transform: translateY(4px);
  }

  &:hover &__text {
    display: inline;
  }

  &__text--position-right {
    top: initial;
    bottom: 0;
    left: 35%;
  }

  &__text--position-bottom {
    top: initial;
    bottom: -125%;

    width: max-content;
  }

  &--overlay-active {
    z-index: 1;
  }
}

.tooltip-text-box {
  display: grid;
  grid-template-columns: 80% 20%;

  &__row-cell {
    white-space: nowrap;
    text-align: left;

    &:first-child {
      margin-right: $spacing-LG;
    }
  }

  &__row-cell--result-cell {
    border-top: 1px solid rgba(#000, 0.085);
  }
}
