@import './../../style/foundation/colors';
@import './../../style/foundation/typography';
@import './../../elements/Button';

$item-hover: rgb(255 255 255 / 65%);

.projects-manager {
  display: grid;
  grid-area: mid;

  .manager-page__list-item {
    &:hover {
      background-color: $item-hover;
    }
  }

  .manager-page__list-item-cell {
    cursor: pointer;
  }

  .manager-page__list-item-cell--number-area {
    cursor: text;
  }

  &--user {
    .manager-page__table table .manager-page__list-item {
      td:first-child {
        min-width: 300px;
        max-width: 500px;
      }

      td:nth-child(2) {
        min-width: 0;
        max-width: 150px;
      }
    }
  }

  &--company {
    .manager-page__table table .manager-page__list-item {
      td:first-child {
        min-width: 300px;
        max-width: 500px;
      }

      td:nth-child(2) {
        min-width: 0;
        max-width: 150px;
      }

      td:nth-child(3) {
        min-width: 0;
        max-width: 150px;
      }
    }
  }

  &--all {
    .manager-page__table table .manager-page__list-item {
      td:first-child {
        min-width: 0;
        max-width: 350px;
      }

      td:nth-child(2) {
        min-width: 0;
        max-width: 150px;
      }

      td:nth-child(3) {
        min-width: 0;
        max-width: 210px;
      }

      td:nth-child(4) {
        max-width: 150px;
      }
    }
  }

  &__table-header {
    @include respond-to('xl') {
      margin-top: $spacing-XXL;
    }
    display: grid;
    grid-row-gap: $spacing-MD * 1.5;
    grid-template-areas:
      'headline button'
      'tab-nav tab-nav'
      'search search'
      'table table';
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: 1fr auto;
    width: 100%;
    height: auto;
    margin-top: $spacing-XL;
  }

  &__tab-nav {
    display: flex;
    grid-area: tab-nav;
    box-sizing: border-box;
    margin: 0;

    list-style: none;
    border-bottom: solid 1px rgba(#000, 0.1);
    padding-inline-start: 0;
  }

  &__tab-nav-item {
    @extend %font-sans-MD;
    box-sizing: border-box;
    margin-right: $spacing-MD * 1.25;
    padding: $spacing-SM $spacing-XS;

    color: $text-dark-primary;
    text-decoration: none;

    border-bottom: solid 3px transparent;
    cursor: pointer;
  }

  &__tab-nav-item--active {
    color: $dh-red;

    border-bottom: solid 3px $dh-red;
  }

  &__project-list-cells {
    @extend %font-sans-LG;
    display: grid;
    grid-area: content;
    grid-template-areas: 'icon name . .';
    grid-template-columns: auto 1fr auto;
    align-items: center;

    color: $text-dark-secondary;

    border-bottom: $light-grey;
    cursor: pointer;
  }

  &__project-list-cell-number {
    @extend %font-sans-MD;
    display: inline-flex;
    grid-area: project-id;
    align-items: center;
    padding: 0 $spacing-XS * 1.25;

    color: $text-dark-disabled;
    font-weight: 400;
    font-family: 'Roboto Mono', monospace;
    line-height: 25px;
    letter-spacing: 0.75px;

    background-color: rgba(#000, 0.05);
    border-radius: 4px;
    cursor: text;

    transition-duration: 0.25s;
  }

  &__table {
    position: relative;

    grid-area: table;
    height: 43vh;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    thead {
      position: sticky;
      top: 0;
      z-index: 1;

      min-width: 20%;
      max-width: 40%;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-left: $spacing-SM;

      color: $text-dark-secondary;
      font-weight: 300;
      text-align: left;

      background-color: #f2f2f2;
      background-color: $light-grey;
    }

    tbody {
      height: 100px; /* Just for the demo          */
      overflow-x: hidden; /* Hide the horizontal scroll */
      overflow-y: auto; /* Trigger vertical scroll    */
    }
  }

  &__table-labels {
    @extend %font-sans-MD;
    color: $text-dark-secondary;
  }

  &__table-label {
    text-align: left;
  }

  &__table-label-icon {
    width: 60px;
  }

  &__table-label-options {
    width: 0;
  }

  &__project-list-item {
    display: grid;
    grid-template-areas: 'content project-id options';
    grid-template-rows: auto;
    grid-template-columns: 1fr auto auto auto 60px;
    align-items: center;
    margin-bottom: 2px;

    background-color: white;

    &:hover {
      background-color: $item-hover;

      .projects-manager__project-list-cell-number {
        color: $text-dark-secondary;
      }
    }
  }

  &__project-list-cell-icon {
    display: flex;
    grid-area: icon;
    align-items: center;
    margin: 0 $spacing-SM * 1.35;

    opacity: 0.34;
  }

  &__project-list-cell-name {
    grid-area: name;
  }

  &__project-list-cell-options {
    grid-area: options;
    margin-top: auto;
    margin-right: $spacing-SM;
    margin-bottom: auto;
    margin-left: $spacing-MD;
  }

  &__projects-container {
    height: 100%;
  }

  &__avatar {
    position: absolute;
    right: 10px;

    display: grid;
    grid-area: right;
    align-content: center;

    &:hover {
      cursor: pointer;
    }

    &::before {
      @extend %icon;
      font-size: 50 * $base-unit;

      content: 'account_circle';
    }
  }

  &__empty-state-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-XL;

    background-color: #ededed;
    box-shadow: 0 0 5px #ededed;
  }

  &__empty-state-text {
    @extend %display-sans-MD;
    color: rgb(0 0 0 / 34%);
  }
}
