@import './../../../style/foundation/spacings';
@import './../../../style/foundation/typography';
@import './../../../style/foundation/colors';
@import './../../../app/App';

.dynamic-sized-column {
  @include respond-to('lg') {
    max-width: calc(100vw - #{$parameters-column-width-LG});
  }
  @include respond-to('xl') {
    max-width: calc(100vw - #{$parameters-column-width-XL});
    height: calc(100vh - 65px);
  }
  display: flex;
  flex-direction: row;
  grid-area: test-values;
  width: fit-content;
  max-width: calc(100vw - #{$parameters-column-width-MD});
  height: calc(100vh - 65px);
  overflow-x: scroll;
  overflow-y: hidden;

  &--for-old-browsers {
    display: table !important;

    .column {
      display: table-cell !important;
    }
  }

  .column {
    @include respond-to('lg') {
      min-width: 385px;
    }
    @include respond-to('xl') {
      display: block;
    }
    min-width: 315px;

    &:nth-child(odd) {
      background-color: $almost-white;
    }

    &:nth-child(even) {
      background-color: $light-grey;
    }
  }

  &__test-search-option {
    display: flex;
    flex-direction: row;
  }

  &__info-items {
    display: grid;
    grid-template-rows: 20px 20px;
    grid-template-columns: auto auto;
  }
}
