// placeholder
@import '../style/foundation/typography';
@import '../style/foundation/spacings';
@import '../style/foundation/colors';

.confirmation-dialog {
  word-wrap: break-word;

  .mdc-dialog__container {
    height: auto;
    max-height: auto;
  }

  .dialog__title {
    display: flex;
    justify-content: center;

    font-size: 24px;

    border: none;
  }

  .dialog__header {
    grid-area: header;
    justify-items: center;
    padding: $spacing-LG * 1.5 $spacing-MD 0 $spacing-MD;

    border: none;
  }

  .dialog__content {
    @extend %font-sans-LG;
    display: flex;
    justify-content: center;
    padding: $spacing-MD $spacing-MD $spacing-LG $spacing-MD;

    color: $text-dark-secondary;
    text-align: center;
    word-break: break-word;
    hyphens: auto;
  }

  .dialog__footer {
    flex-direction: row-reverse;
    justify-content: center;
  }

  .mdc-dialog__surface {
    height: auto;
    min-height: auto;
    max-height: auto;
  }
}
