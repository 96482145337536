.window-area-help {
  &__message-heading {
    margin-top: 12px;
    margin-bottom: 6px;
  }

  &__message-paragraph {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}
