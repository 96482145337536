@import './../../style/foundation/colors';
@import './../../style/foundation/typography';
@import './../../elements/Button';

.template-dialog {
  grid-template-areas:
    'name name name . . .'
    'description description description . . .';

  &__name {
    grid-area: name;
  }

  &__description {
    grid-area: description;
  }
}
