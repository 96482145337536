@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';
@import '../../style/foundation/colors';
%input-field-wrapper {
  margin-right: $spacing-SM;
  margin-bottom: $spacing-MD;
}

.wind-deflector-view-dialog {
  width: 100%;
  overflow: auto;

  background-color: #fff;

  &__label-with-space {
    margin-left: $spacing-SM;
  }

  &__active-switch {
    margin-top: 40px;
  }

  &__table-cell-select-field {
    overflow: visible !important;
  }

  &__table-select-field {
    width: 122px;
  }

  &__layout-info {
    display: grid;
    grid-template-areas:
      'wlw-name wlw-name wlw-name wlw-name . .'
      'sheet-metal-wlw-heading . . . . .'
      'sheet-metal-wlw-max-lenght sheet-metal-wlw-max-lenght . sheet-metal-wlw-console sheet-metal-wlw-console .'
      'glas-wlw-heading . . . . .'
      'glas-wlw-max-lenght glas-wlw-max-lenght . glas-wlw-console glas-wlw-console .';
  }

  &__layout-halter-einzelklappe {
    display: grid;
    grid-template-areas:
      'heading1 heading1 . . . .'
      'name-eckhalter name-eckhalter name-eckhalter . . .'
      'heading2 heading2 . . . .'
      'wlw-sub-group-1 wlw-sub-group-1 wlw-sub-group-1 wlw-sub-group-1 wlw-sub-group-1 wlw-sub-group-1'
      'wlw-sub-group-2 wlw-sub-group-2 wlw-sub-group-2 wlw-sub-group-2 wlw-sub-group-2 wlw-sub-group-2';
  }

  &__layout-raw-table {
    display: grid;
    grid-template-areas:
      'header header header header header header'
      'table table table table table table';
  }

  // layout doppelklappe

  &__table {
    grid-area: table;
  }

  &__table-sub-header {
    grid-area: header !important;
    padding: $spacing-SM;
  }

  // layout einzelklappe kipp

  &__layout-einzelklappe-kipp {
    display: grid;
    grid-template-areas:
      'input-section input-section input-section input-section input-section input-section'
      'header header header header header header'
      'table table table table table table';
  }

  &__ekKipp-input-section {
    display: grid;
    grid-area: input-section;
    grid-template-areas:
      'r1-cell1 r1-cell2 r1-cell3 r1-cell4 r1-cell5 r1-cell6'
      'r2-cell1 r2-cell2 r2-cell3 r2-cell4 r2-cell5 r2-cell6';
    grid-template-columns: max-content max-content max-content max-content max-content max-content;
    padding: $spacing-MD;
  }

  &__ekKipp-input-section-row {
    display: flex;
    align-items: center;
  }

  &__input-section-select-field {
    width: 50px;
    margin-right: $spacing-SM;
    margin-left: $spacing-SM;
  }

  &__seperator {
    margin-right: 10px;
    padding-right: 10px;

    border-right: solid 1px $input-field-border;
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  // layout-info

  &__sheet-metal-wlw-heading {
    grid-area: sheet-metal-wlw-heading;
  }

  &__glas-wlw-heading {
    grid-area: glas-wlw-heading;
  }

  &__wlw-name {
    grid-area: wlw-name;
  }

  &__sheet-metal-wlw-max-lenght {
    grid-area: sheet-metal-wlw-max-lenght;
  }

  &__sheet-metal-wlw-console {
    grid-area: sheet-metal-wlw-console;
  }

  &__glas-wlw-max-lenght {
    grid-area: glas-wlw-max-lenght;
  }

  &__glas-wlw-console {
    grid-area: glas-wlw-console;
  }

  // layout halter ek

  &__sub-group-1 {
    display: grid;
    grid-area: wlw-sub-group-1;
    grid-template-areas:
      'heading heading . . . .'
      'row1column1 row1column2 row1column3 row1active . .'
      'row2column1 row2column2 row2column3 row2active . .';
  }

  &__sub-group-2 {
    display: grid;
    grid-area: wlw-sub-group-2;
    grid-template-areas:
      'heading heading . . . .'
      'row1column1 row1column2 row1column3 row1active . .'
      'row2column1 row2column2 row2column3 row2active . .'
      'row3column1 row3column2 row3column3 row3active . .';
  }

  &__row1-column1 {
    @extend %input-field-wrapper;
    grid-area: row1column1;
  }

  &__row1-column2 {
    @extend %input-field-wrapper;
    grid-area: row1column2;
  }

  &__row1-column3 {
    @extend %input-field-wrapper;
    grid-area: row1column3;
  }

  &__row1-active {
    //@extend %input-field-wrapper;
    grid-area: row1active;
  }

  &__row2-column1 {
    @extend %input-field-wrapper;
    grid-area: row2column1;
  }

  &__row2-column2 {
    @extend %input-field-wrapper;
    grid-area: row2column2;
  }

  &__row2-column3 {
    @extend %input-field-wrapper;
    grid-area: row2column3;
  }

  &__row2-active {
    //@extend %input-field-wrapper;
    grid-area: row2active;
  }

  &__row3-column1 {
    @extend %input-field-wrapper;
    grid-area: row3column1;
  }

  &__row3-column2 {
    @extend %input-field-wrapper;
    grid-area: row3column2;
  }

  &__heading1 {
    grid-area: heading1;
  }

  &__heading2 {
    grid-area: heading2;
  }

  &__name-eckhalter {
    grid-area: name-eckhalter;
  }

  // layout Halter Doppelklappe

  &__sub-group-wlw-glas-pultdach {
    display: grid;
    grid-area: wlw-sub-group-1;
    grid-template-areas:
      'heading heading . . . .'
      'row1column1 row1column2 row1column3 row1column4 row1column5 row1column6'
      'row2column1 row2column2 row2column3 row2column4 row2column5 row2column6';

    .wind-deflector-view-dialog__row1-active {
      grid-area: row1column5;
    }

    .wind-deflector-view-dialog__row2-active {
      grid-area: row2column5;
    }
  }

  &__row1-column4 {
    @extend %input-field-wrapper;
    grid-area: row1column4;
  }

  &__row2-column4 {
    @extend %input-field-wrapper;
    grid-area: row2column4;
  }

  &__sub-heading {
    display: grid;
    grid-area: heading;
  }
}

.wind-deflector-data {
  grid-area: data-view;
  max-height: 100%;

  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
