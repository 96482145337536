@import '../../style/foundation/spacings';

.series-data-view-dialog {
  display: grid;
  grid-template-areas:
    'name name active-switch short-name locking-available .'
    'info info preview-switch system material .'
    'info info . building-depth building-depth .'
    'info info . edge-length-connection sash-inner-area-to-angle .'
    'opening-type opening-type opening-type opening-type . .'
    'distance-to-hindge distance-to-hindge . exhaust-dimension exhaust-dimension .'
    'as-distance as-distance . steel-weight-calculation-values  steel-weight-calculation-values steel-weight-calculation-values'
    'sash-addition sash-addition sash-addition steel-weight-calculation-values  steel-weight-calculation-values steel-weight-calculation-values';
  height: 600px;

  background-color: #fff;

  &__locking-available {
    grid-area: locking-available;
    margin-top: 40px;
  }

  &__opening-type {
    grid-area: opening-type;
  }

  &__name {
    grid-area: name;
  }

  &__system {
    grid-area: system;
  }

  &__material {
    grid-area: material;
  }

  &__building-depth {
    grid-area: building-depth;
  }

  &__edge-length-connection {
    grid-area: edge-length-connection;
  }

  &__sash-inner-area-to-angle {
    grid-area: sash-inner-area-to-angle;
  }

  &__info {
    grid-area: info;
  }

  &__short-name {
    grid-area: short-name;
  }

  &__distance-to-hinge {
    grid-area: distance-to-hindge;
  }

  &__exhaust-dimension {
    grid-area: exhaust-dimension;
  }

  &__active-switch {
    grid-area: active-switch;
    margin-top: 40px;
  }

  &__preview-switch {
    grid-area: preview-switch;
    margin-top: 40px;
  }

  &__sash-addition {
    grid-area: sash-addition;
    margin-bottom: $spacing-SM;
  }

  &__as-distance {
    grid-area: as-distance;
  }

  &__steel-weight-calculation-values {
    display: flex;
    flex-direction: column;
    grid-area: steel-weight-calculation-values;
  }
}
