$results-column-width-MD: 400px;

.console-selection {
  display: grid;
  grid-area: console-selection;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;

  &--without-locking-console {
    grid-template-rows: 1fr;
  }

  &--without-drive-console {
    grid-template-rows: 1fr;
  }
}

.drive-console-area {
  display: grid;
  grid-template-areas: 'console-results console-details';
  grid-template-rows: 1fr;
  grid-template-columns: $results-column-width-MD minmax(200px, 1fr);
  height: 100%;
}

.locking-console-area {
  display: grid;
  grid-template-areas: 'console-results console-details';
  grid-template-rows: 1fr;
  grid-template-columns: $results-column-width-MD minmax(200px, 1fr);
  height: 100%;
}
