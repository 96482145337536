@import './../../../style/foundation/typography';
@import './../../../style/foundation/spacings';
@import './../../../style/foundation/colors';

.reveal-measure-box {
  display: grid;
  grid-column-gap: $spacing-MD;
  grid-template-rows: auto auto auto;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);

  &__title {
    @extend %font-sans-MD;
    grid-row: 1;
    grid-column: 1/3;
    margin-bottom: $spacing-SM;
  }

  &__triangles {
    display: grid;
    grid-row: 3;
    grid-column: 1/4;
    grid-column-gap: $spacing-MD;
    grid-template-rows: auto auto auto;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }

  &__opposite-hinge {
    grid-row: 2;
    grid-column: 1/2;
  }
}
