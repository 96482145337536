@import '../style/foundation/spacings';
@import '../style/foundation/typography';

.privacy-policy {
  margin-right: $spacing-LG;
  margin-left: $spacing-LG;

  table {
    width: 100%;
  }

  th {
    @extend %display-sans-SM;
    font-weight: 500;
    text-align: left;
    vertical-align: top;
  }

  ol {
    margin-top: $spacing-MD;
    margin-left: 0;
    padding-left: 1em; /* Adjust as needed */

    list-style-position: outside;

    li {
      margin-bottom: $spacing-MD;
    }
  }

  h2 {
    @extend %display-sans-MD;
    font-weight: 500;
  }

  &__google-table-max-storage {
    width: 130px;
  }

  td {
    vertical-align: top;
  }
}
