@import './src/style/foundation/typography';
@import './src/style/foundation/spacings';
@import './src/style/foundation/colors';

.table-header {
  width: 100%;

  &--large-header {
    height: $base-unit * 52;
  }

  th {
    @extend %font-sans-MD;
    position: sticky;
    top: 0;
    z-index: 2;

    min-width: 10px;
    padding-left: $spacing-SM;
    overflow: hidden;

    color: $text-dark-secondary;
    font-weight: regular;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;

    background-color: $light-grey;

    // resize: horizontal;
  }

  &__action-column {
    width: 136px;
    min-width: 136px;

    &::before {
      opacity: 0;

      content: '____________';
    }
  }
}
