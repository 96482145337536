@import './../../style/foundation/colors';
@import './../../style/foundation/typography';
@import './../../elements/Button';

.window-dialog {
  &__without-template-sub-form {
    display: grid;
    grid-template-areas:
      'building-area building-area building-area . . .'
      'subheadline subheadline subheadline subheadline subheadline subheadline'
      'switch-rwa switch-rwa switch-rwa switch-rwa switch-rwa switch-rwa'
      'switch-nrwg switch-nrwg switch-nrwg switch-nrwg switch-nrwg switch-nrwg'
      'subheadline-roa subheadline-roa subheadline-roa subheadline-roa subheadline-roa subheadline-roa'
      'radio-RoA radio-RoA radio-RoA radio-RoA radio-RoA radio-RoA';
    padding-top: 0;
  }

  .tab-nav-section__tab-nav {
    margin-right: $spacing-MD;
    margin-left: $spacing-MD;
  }

  &__layout {
    grid-row-gap: 0;
    grid-template-areas:
      'name name quantity . . .'
      'tab-nav tab-nav tab-nav tab-nav tab-nav tab-nav';
    grid-template-rows: auto;
    padding-top: $spacing-XS * 0.25;
  }

  &__with-templates-table {
    display: grid;
    grid-template-areas:
      'templates templates templates templates templates templates'
      'description description description description description description';
    grid-template-rows: 1fr auto;
    height: 358px;

    .result-table {
      grid-area: templates;
    }

    &--empty {
      height: 250px;
    }
  }

  .tab-nav-section__tab-nav-container {
    grid-area: tab-nav;
  }

  &__description {
    grid-area: description;
  }

  &__name {
    grid-area: name;
  }

  &__quantity {
    grid-area: quantity;
  }

  &__building-area {
    grid-area: building-area;
  }

  &__sub-headline-desmoking {
    @extend %display-sans-LG;
    grid-area: subheadline;
  }

  &__sub-headline-roa {
    @extend %display-sans-LG;
    grid-area: subheadline-roa;
    margin-bottom: 0;
  }

  &__radio-roa {
    @extend %display-sans-LG;
    grid-area: radio-RoA;
  }

  &__switch-rwa {
    grid-area: switch-rwa;
    margin-bottom: $spacing-MD;
  }

  &__switch-nrwg {
    grid-area: switch-nrwg;
  }

  .editable-box {
    width: 100px;
    margin: 0;
    padding-left: $spacing-XS;

    box-shadow: none;
  }

  .editable-box:last-child {
    margin-bottom: 0;
  }

  &__template-empty-state {
    @extend %font-sans-LG;
    display: flex;
    grid-area: templates;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;

    color: $text-dark-secondary;
    text-align: center;
  }
}
