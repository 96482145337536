@use '@material/checkbox/mdc-checkbox';
@import '../style/foundation/typography';
@import '../style/foundation/spacings';
@import '../style/foundation/colors';
@import './../elements/Button';

$button-red: $primary;

.download-dialog {
  padding: $spacing-MD * 1.5 0 0 $spacing-MD * 1.5;

  .mdc-checkbox {
    &__background {
      background-color: white !important;
      border-color: $text-dark-disabled !important;
      border-radius: 100% !important;
    }

    &--selected {
      .mdc-checkbox__background {
        background-color: $primary !important;
        border-color: $primary !important;
      }
    }

    &__background path {
      stroke-width: 3 !important;
    }

    ::after,
    ::before {
      background-color: rgba($button-red, 0.64) !important;
    }

    //&:indeterminate {
    //  ::after,
    //  ::before {
    //    background-color: rgba($blue, 0.64) !important;
    //  }
    //}
  }

  &__title-button {
    display: inline-block;
    box-sizing: border-box;
    height: 18px;
    margin-left: 4px;

    color: rgb(0 0 0 / 50%);
    font-weight: 500;
    font-size: 10px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    text-transform: uppercase;

    background-color: transparent;
    border: 1px solid #ededed;
    border-radius: 3px;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &--selected {
      color: white;

      background-color: #c2c2c2;
      border-color: #c2c2c2;
    }
  }

  &__section-header {
    margin-right: $spacing-MD;
    margin-bottom: $spacing-MD;
    margin-left: $spacing-MD;
  }

  &__subtitle {
    margin-bottom: $spacing-SM;
    margin-left: $spacing-MD * 1.5;

    font-size: 10pt;
  }

  &__title {
    @extend %font-sans-LG;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    color: $text-dark-secondary;

    cursor: pointer;

    &:hover {
      .download-dialog__title-button {
        border: 1px solid #ddd;
        box-shadow: 0 1px 0 #ddd;
      }

      .download-dialog__title-button.download-dialog__title-button--selected {
        border: 1px solid #c2c2c2;
        box-shadow: none;
      }
    }
  }

  &__content {
    position: relative;

    display: block;
    width: 100%;
    margin-bottom: $spacing-MD;
    margin-left: $spacing-MD;

    column-count: 2;

    &--section-following {
      margin-bottom: 0;
    }
  }

  &__loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    flex-direction: column;
    width: 100%;
    padding-top: $spacing-XXL;

    background-color: rgba(#fff, 0.9);
  }

  &__loading-overlay-animation {
    display: block;
    width: 100%;
    margin-bottom: $spacing-SM;
  }

  &__loading-overlay-text {
    @extend %font-sans-LG;
    display: block;
    width: 100%;

    color: $text-dark-secondary;
    text-align: center;
  }

  &__list-item {
    @extend %display-sans-MD;
    display: flex;
    margin-bottom: $spacing-MD;

    color: $text-dark-secondary;
    font-weight: 400;
    text-decoration: none;
    text-overflow: ellipsis;
    page-break-inside: avoid;
    -webkit-column-break-inside: avoid;
    break-inside: avoid;

    &:hover {
      color: $text-dark-primary;
      font-weight: 500;
      text-decoration: underline;
    }
  }

  &__list-item-container {
    &::before {
      @extend %display-sans-MD;
      @extend %icon;
      position: relative;

      display: inline-flex;
      margin-bottom: $spacing-MD;
      padding-right: $spacing-XS * 1.5;

      color: $dh-red;
      text-decoration: none !important;

      transform: translateY(-20%);

      content: 'arrow_downward';
    }
  }

  &__button-area {
    display: flex;
    flex-direction: column;
    grid-area: button;
  }

  &__button {
    display: flex;
    max-width: 330px;
    margin-bottom: $spacing-MD;

    text-decoration: none;

    cursor: pointer;
  }
}
