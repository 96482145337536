@import './../style/foundation/spacings';
@import './../style/foundation/typography';
@import './../style/foundation/colors';

.empty-state-container {
  display: flex;
  align-items: center;
  justify-content: center;

  color: $text-dark-disabled;

  background-color: #e8e8e8;
  box-shadow: 0 0 2px 1px #e8e8e8;

  &--lg {
    @extend %display-sans-MD;
    padding: $spacing-XL;
  }

  &--sm {
    @extend %font-sans-MD;
    padding: $spacing-MD * 1.5 $spacing-LG;

    font-weight: 400;
  }
}
