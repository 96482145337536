@import './src/style/foundation/typography';
@import './src/style/foundation/colors';
@import './src/style/foundation/spacings';

.table-container {
  position: relative;

  display: flex;

  // height: 80vh;
  overflow-y: auto;

  &--without-sub-navigation {
    //  height: calc(100vh - 250px);
  }

  .table {
    @extend %font-sans-LG;
    width: 100%;
    margin: 0;

    &__cell--multiline {
      padding: $spacing-SM;
    }

    &__cell-line {
      display: flex;
      align-items: center;

      white-space: nowrap;
    }
  }
}
