@import '../../style/foundation/spacings';

.exchange-profile-data-dialog {
  width: 100%;

  background-color: #fff;

  &__layout-basic {
    display: grid;
    grid-template-areas:
      'nav nav nav nav nav nav'
      'article-nr article-nr serie . . .'
      'info info . . . . '
      ' info info  . . . .'
      'switch . . . . .'
      'preview . . . . .';
  }

  &__layout-basic-information {
    display: grid;
    grid-template-areas:
      'measurement-inside measurement-outside . . . .'
      'building-depth . . . . .';
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__installation {
    grid-area: installation;
  }

  &__opening-direction {
    grid-area: opening-direction;
    margin-bottom: $spacing-SM;
  }

  &__mountage {
    grid-area: mountage;
    margin-bottom: $spacing-SM;
  }

  &__application {
    grid-area: application;
    margin-bottom: $spacing-SM;
  }

  &__tip-tronic {
    grid-area: tip-tronic;
  }

  &__integrated {
    grid-area: integrated;
    margin-top: $spacing-LG;
    margin-bottom: $spacing-SM * 1.5;
  }

  &__profile-type {
    grid-area: profile-type;
  }

  &__integrated-measurement {
    grid-area: integrated-measurement;
    margin-bottom: $spacing-SM * 1.5;
  }

  &__basic-profile {
    grid-area: basic-profile;
    margin-bottom: $spacing-MD;
  }

  &__exchange-profile {
    grid-area: exchange-profile;
    margin-bottom: $spacing-MD;
  }

  &__uf {
    grid-area: uf;
  }

  &__building-depth {
    grid-area: building-depth;
  }

  &__max-sash-addition {
    grid-area: max-sash-addition;
  }

  &__max-sash-weight {
    grid-area: max-sash-weight;
  }

  &__ix {
    grid-area: ix;
  }

  &__iy {
    grid-area: iy;
  }

  &__measurement-inside {
    grid-area: measurement-inside;
  }

  &__measurement-outside {
    grid-area: measurement-outside;
  }

  &__article-nr {
    grid-area: article-nr;
  }

  &__info {
    grid-area: info;
  }

  &__serie {
    grid-area: serie;
  }

  &__switch {
    grid-area: switch;
  }

  &__preview {
    grid-area: preview;
  }
}

.exchange-profile-data {
  grid-area: data-view;
  max-height: 100%;
  overflow: hidden;

  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
