@import './../style/foundation/colors';
@import './../style/foundation/typography';
/**
 * Main wrapper
 */

.select-search-options {
  margin-top: 4px !important;
  padding-left: 0;

  list-style: none;
}

.select-search-select {
  max-height: 300px;
  overflow: auto;

  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  box-shadow: 0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
}

.select-search-has-focus .select-search-select {
  border-color: var(--select-search-selected);
}

.select-search-container {
  @extend %font-sans-MD;
  position: relative;

  box-sizing: border-box;
  width: 300px;

  color: var(--select-search-text);

  cursor: pointer;

  &--menu-top {
    div.select-search-select {
      top: -314px !important;

      height: 300px;
    }
  }

  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: 'Roboto', sans-serif;
}
@supports (font-variation-settings: normal) {
  .select-search-container {
    --select-search-font: 'Roboto', sans-serif;
  }
}

body.is-dark-mode .select-search-container {
  --select-search-background: #000;
  --select-search-border: #313244;
  --select-search-selected: #89b4fa;
  --select-search-text: #fff;
  --select-search-subtle-text: #a6adc8;
  --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
  box-sizing: inherit;
}

.select-search-input {
  position: relative;
  z-index: 1;

  display: block;
  width: 100%;
  height: 48px;
  padding: 0 40px 0 16px;

  color: var(--select-search-text);
  font-size: 16px;
  font-family: var(--select-search-font);
  line-height: 48px;
  letter-spacing: 0.01rem;
  text-align: left;
  text-overflow: ellipsis;

  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  border-radius: 3px;
  outline: none;
  cursor: pointer;

  appearance: none;
}

.select-search-is-multiple .select-search-input {
  margin-bottom: -2px;

  border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
  appearance: none;
}

.select-search-input[readonly] {
  cursor: pointer;
}

.select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus
  .select-search-input,
.select-search-container:not(.select-search-is-disabled)
  .select-search-input:hover {
  border-color: var(--select-search-selected);
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
  position: absolute;
  top: 58px;
  right: 0;
  left: 0;
  z-index: 2;

  display: none;

  border-radius: 3px;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus
  .select-search-select {
  display: block;
}

.select-search-option,
.select-search-not-found {
  display: block;
  display: flex;
  align-items: center;
  width: 100%;
  height: 42px;
  padding: 0 16px;

  color: var(--select-search-text);
  font-size: 16px;
  font-family: var(--select-search-font);
  letter-spacing: 0.01rem;
  text-align: left;

  background: var(--select-search-background);
  border: none;
  outline: none;
  cursor: pointer;
}

.select-search-option:disabled {
  background: transparent !important;
  cursor: not-allowed;
  opacity: 0.5;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  background: var(--select-search-highlight);
}

.select-search-group-header {
  padding: 10px 16px;

  color: var(--select-search-subtle-text);
  font-size: 12px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;

  background: var(--select-search-border);
}

.select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}
