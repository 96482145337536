// _###Color Names###
$dh-red: #e10025;
$light-red: lighten($dh-red, 53%);
$light-green: #9ccc65;
$dark-green: #47ae5c;
$active-blue: #4fc3f7;
$yelling-yellow: #ffca28;
$dark-yellow: darken($yelling-yellow, 12%);
$white: #ffff;
$super-light-grey: rgb(0 0 0 / 3%);
$light-grey: #f5f5f5;
$almost-white: #fafafa;
$darker-grey: #e1e1e1;
$dark-grey: #929292;
$light-blue: #ecf7fc;
$dark-blue: #c7d7df;
$very-dark-blue: darken(
  $color: $dark-blue,
  $amount: 10%,
);

// __##Tones of Black##
$black-84: rgba(#000, 0.84);
$black-74: rgba(#000, 0.74);
$black-64: rgba(#000, 0.64);
$black-54: rgba(#000, 0.54);
$black-34: rgba(#000, 0.34);
$black-24: rgba(#000, 0.24);
$black-14: rgba(#000, 0.14);

// _###Color Functions###
// __##UI base colors##
$primary: $dh-red;
$item-selected: $light-blue;
$item-hover: $light-grey;
$text-dark-primary: $black-84;
$text-dark-secondary: $black-64;
$text-dark-disabled: $black-34;

// Element colors
$input-field-border: rgb(0 0 0 / 10%);
