@import '../style/foundation/typography';
@import '../style/foundation/spacings';
@import '../style/foundation/colors';
@import './../style/foundation/shadows';

.result-item {
  @include respond-to('xl') {
    margin-bottom: $spacing-SM * 1.75;
  }
  position: relative;
  z-index: 0;

  display: grid;
  grid-template-areas:
    'byline byline'
    'name icon'
    'item-properties item-properties';
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr auto;
  min-height: 46px;
  margin-bottom: $spacing-SM * 1.75;
  overflow: hidden;

  cursor: pointer;

  transition-duration: 0.3s;

  &__plus-icon {
    grid-area: icon;

    svg {
      @include respond-to('xl') {
        width: 20px;
        height: 20px;
      }
      width: 18px;
      height: 18px;

      opacity: 0.24;

      transition-duration: 0.3s;
      transition-duration: 0.25s;
    }
  }

  &__name {
    @extend %display-sans-MD;
    display: flex;
    grid-area: name;
    width: 100%;

    color: $text-dark-secondary;
    font-weight: 500;
    line-height: 22px;
  }

  &--selected,
  &:active {
    background-color: $item-selected;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%), 0 2px 10px 0 rgb(0 0 0 / 2.5%);

    .recommended-item__label::after {
      color: $text-dark-secondary;
    }

    .result-item__name {
      color: $text-dark-primary;
    }
  }

  &:hover {
    .result-item__name {
      color: $text-dark-primary;
      text-decoration: underline;
    }

    .result-item__plus-icon svg {
      transform: scale(1.2);
      opacity: 0.35;
    }
  }

  &:active {
    .result-item__plus-icon svg {
      transform: scale(1);
      opacity: 0.4;
    }
  }

  &.result-item--display-only {
    cursor: default;

    &:active {
      @extend %shadow-elevation-1;
      background-color: $white;
    }

    &:hover {
      .result-item__name {
        color: $text-dark-secondary;
        text-decoration: none;
      }
    }
  }

  &--with-amount {
    grid-template-areas:
      'byline amount'
      'name name'
      'item-properties item-properties' !important;
  }

  &--with-byline {
    grid-template-areas:
      'byline icon'
      'name name'
      'item-properties item-properties';
  }

  &--with-options {
    grid-template-areas:
      'byline amount options'
      'name name name'
      'item-properties item-properties item-properties' !important;

    .options-menu {
      margin-top: -7px;
      margin-right: -7px;
      margin-left: $spacing-SM;
    }
  }

  &__amount {
    @extend %font-sans-SM;
    grid-area: amount;

    color: $text-dark-secondary;
    font-weight: 400;
  }

  &__properties {
    @extend %font-sans-SM;
    grid-area: item-properties;

    color: $text-dark-secondary;
    font-weight: 400;
  }

  &__byline {
    @extend %font-sans-SM;
    grid-area: byline;

    color: $text-dark-secondary;
  }
}
