@import './../../../style/foundation/typography';
@import './../../../style/foundation/colors';

.recommendations-column {
  z-index: 2;

  grid-area: recommendations !important;

  background-color: $almost-white;

  &__empty-state-advice {
    @extend %display-sans-MD;
    width: 90%;
    margin-right: auto;
    margin-left: auto;

    color: $text-dark-secondary;
    text-align: center;
  }

  &__expand-button {
    @include respond-to('xl') {
      margin-top: $spacing-XXL * -1.15;
    }
    @extend %font-sans-MD;
    display: flex;
    align-self: center;
    margin-top: $spacing-XL * -1.65;
    margin-right: auto;
    margin-left: auto;
    padding: $spacing-XS $spacing-SM;

    color: $text-dark-disabled;

    background: none;
    border: solid 1px #eee;
    border-radius: 3px;

    &:hover {
      color: $text-dark-secondary;

      border-color: rgba(#000, 0.2);
      cursor: pointer;
    }
  }

  &__expand-button-icon {
    @extend %icon;
    margin-left: $base-unit * 4;
  }

  &__general-error-text {
    @extend %display-sans-MD;
    width: 268px;
    margin-bottom: $spacing-SM;

    color: rgb(33 33 33 / 84%);
    font-weight: 300;
    font-size: 24px;
    font-style: normal;
    line-height: 27px;
    text-align: center;
  }

  &__error-solution-list {
    @extend %font-sans-MD;
    display: flex;
    flex-direction: column;
    max-width: 260px;
    margin-right: auto;
    margin-left: auto;
    padding: 0;

    color: $text-dark-secondary;
    text-align: left;
  }

  &__error-solution {
    font-weight: 400;

    list-style-position: inside;
  }
}
