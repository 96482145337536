@import '../../style/foundation/spacings';

.system-data-view-dialog {
  display: grid;
  grid-template-areas:
    'name name switch short-name . .'
    'info info . . . .';

  background-color: #fff;

  &__name {
    grid-area: name;
  }

  &__info {
    grid-area: info;
  }

  &__short-name {
    grid-area: short-name;
  }

  &__switch {
    grid-area: switch;
    margin-top: 40px;
  }
}

.system-profile-data {
  grid-area: data-view;
  max-height: 100%;

  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
