@import '../../style/foundation/spacings';

.roof-frame-profile-data-dialog {
  width: 100%;

  background-color: #fff;

  &__layout-basic {
    display: grid;
    grid-template-areas:
      'nav nav nav nav nav nav'
      'article-nr article-nr . . . .'
      'info info info info  . .' 'serie serie . . . .'
      'switch switch switch . . .'
      'preview preview preview . . .';
  }

  &__layout-basic-information {
    display: grid;
    grid-template-areas:
      'strength strength depth depth . .'
      'inner-width-1 inner-width-1 inner-width-2 inner-width-2 . .';
  }

  &__layout-properties {
    display: grid;
    grid-template-areas:
      'isolation isolation . . . .'
      'opening-direction opening-direction . . . .'
      'installation installation installation . . . ';
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__installation {
    grid-area: installation;
  }

  &__opening-direction {
    grid-area: opening-direction;
    margin-bottom: $spacing-SM;
  }

  &__opening-direction--disabled {
    pointer-events: none;
  }

  &__isolation {
    grid-area: isolation;
  }

  &__exchange-profile {
    grid-area: exchange-profile;
    margin-bottom: $spacing-MD;
  }

  &__uf {
    grid-area: uf;
  }

  &__building-depth {
    grid-area: building-depth;
  }

  &__max-sash-weight {
    grid-area: max-sash-weight;
  }

  &__inner-width-2 {
    grid-area: inner-width-2;
  }

  &__inner-width-1 {
    grid-area: inner-width-1;
  }

  &__strength {
    grid-area: strength;
  }

  &__depth {
    grid-area: depth;
  }

  &__article-nr {
    grid-area: article-nr;
  }

  &__info {
    grid-area: info;
  }

  &__serie {
    grid-area: serie;
  }

  &__switch {
    grid-area: switch;
  }

  &__preview {
    grid-area: preview;
  }
}

.frame-profile-data {
  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
