@import 'fonts/fonts';
@import 'spacings';
@import 'breakpoints';
%font-sans-condensed-SM {
  @include respond-to('xl') {
    font-size: $base-unit * 16;
    line-height: $base-unit * 24;
  }
  font-weight: 400;
  font-size: 14 * $base-unit;
  font-family: 'Roboto Condensed', sans-serif;
  font-style: normal;
  line-height: 22 * $base-unit;
}
%font-sans-XS {
  font-weight: 400;
  font-size: $base-unit * 13;
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: $base-unit * 24;
  letter-spacing: 0.25px;
}
%font-sans-XXS {
  font-weight: 400;
  font-size: $base-unit * 11;
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: $base-unit * 24;
  letter-spacing: 0.25px;
}
%font-sans-SM {
  @include respond-to('xl') {
    font-size: $base-unit * 13;
    line-height: $base-unit * 24;
  }
  font-weight: 400;
  font-size: 12 * $base-unit;
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: 22 * $base-unit;
}
%font-sans-MD {
  @include respond-to('lg') {
    font-size: 14 * $base-unit;
    line-height: $base-unit * 21;
  }
  @include respond-to('xl') {
    font-size: $base-unit * 15;
    line-height: $base-unit * 22;
  }
  font-size: 13 * $base-unit;
  font-family: Roboto, sans-serif;
  font-style: normal;
  line-height: $base-unit * 16;

  &--italic {
    font-style: italic;
  }
}
%font-sans-LG {
  @include respond-to('md') {
    font-size: 14 * $base-unit;
    line-height: 22 * $base-unit;
  }
  @include respond-to('lg') {
    font-size: 15 * $base-unit;
    line-height: 25 * $base-unit;
  }
  @include respond-to('xl') {
    font-size: 18 * $base-unit;
    line-height: 26 * $base-unit;
  }
  font-weight: 400;
  font-family: Roboto, sans-serif;
  font-style: normal;
}
%display-sans-SM {
  font-weight: 400;
  font-size: $base-unit * 15;
  font-family: Roboto, sans-serif;
  line-height: $base-unit * 22;
}
%display-sans-MD {
  font-weight: 400;
  font-size: $base-unit * 19;
  font-family: Roboto, sans-serif;
  line-height: $base-unit * 28;
}
%display-sans-LG {
  @include respond-to('xl') {
    font-size: $base-unit * 28;
    line-height: $base-unit * 38;
  }
  font-weight: 400;
  font-size: 24 * $base-unit;
  font-family: Roboto, sans-serif;
  line-height: $base-unit * 29;
}
%display-sans-XL {
  @include respond-to('xl') {
    font-size: $base-unit * 36;
    line-height: $base-unit * 38;
  }
  font-weight: 300;
  font-size: 28 * $base-unit;
  font-family: Roboto, sans-serif;
  line-height: $base-unit * 32;
}
%display-sans-XXL {
  @include respond-to('xl') {
    font-size: $base-unit * 45;
    line-height: $base-unit * 54;
  }
  font-weight: 300;
  font-size: 34 * $base-unit;
  font-family: Roboto, sans-serif;
  line-height: $base-unit * 41;
}
%icon {
  font-family: 'Material Icons', sans-serif;
  font-feature-settings: 'liga=1';
  font-feature-settings: 'liga';
  font-feature-settings: 'liga' 1;
  font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@mixin first-letter-capitalized {
  &::first-letter {
    text-transform: capitalize;
  }
}
