@import './../../../style/foundation/typography';
@import './../../../style/foundation/spacings';
@import './../../../style/foundation/colors';

.tab-bar-with-values {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  margin-bottom: $spacing-SM * 1.5;

  background-color: #f2f2f2;
  border-radius: 5px;

  &__annotation {
    @extend %font-sans-SM;
    padding-top: $spacing-SM;

    color: $text-dark-secondary;
  }

  &__unit {
    @extend %font-sans-MD;
    margin-top: $spacing-XS;

    color: $text-dark-secondary;
    text-align: left;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
  }

  &__chain-icon {
    @extend %icon;
    display: inline-block;
    padding: 0 $spacing-SM;

    color: $text-dark-disabled;
    font-size: 21px;
    line-height: 0;

    transform: translateY(-51px);
    cursor: default;
  }

  .mdc-tab__ripple {
    display: none;
    box-sizing: border-box;
  }

  &__range {
    @extend %font-sans-SM;
    display: block;
    padding-top: $spacing-XS;

    color: $text-dark-secondary;
  }

  &__range--sashWeight {
    width: 56%;
    margin-right: auto;
    margin-bottom: 12px;
  }
}
