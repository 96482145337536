@import '../../style/foundation/spacings';
@import '../../style/foundation/colors';
@import '../../style/foundation/typography';
@import '../../app/App';

$column-top-inset-LG: $spacing-SM * 1.5;
$column-inset-XL: $spacing-MD * 3;
$column-background: #fdfdfd;
$column-top-inset-XL: $spacing-LG;
$column-inset-LG: $spacing-MD;

.column {
  @include respond-to('lg') {
    width: 334px;
  }
  @include respond-to('xl') {
    display: block;
    width: 390px;
  }
  @extend %display-sans-MD;
  width: 315px;
  height: 100vh;
  overflow-y: hidden;

  &__content {
    @include respond-to('xl') {
      padding: $column-top-inset-LG $column-inset-LG;
    }
    height: 100%;
    padding: $column-top-inset-LG $column-inset-LG;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    .tile.result-item:last-child {
      @include respond-to('xl') {
        margin-bottom: $spacing-XXL * 1.35;
      }
      margin-bottom: $spacing-XXL;
    }
  }

  &__title {
    @extend %display-sans-MD;
    position: relative;
    z-index: 0;

    display: inline-flex;
    align-items: center;
    margin-bottom: $spacing-SM * 1.5;

    color: $text-dark-secondary;

    svg {
      margin-right: $spacing-SM;
    }
  }

  &__info-icon {
    @extend %info-icon;
  }
}
