@import './../../style/foundation/colors';
@import './../../style/foundation/typography';
@import './../../elements/Button';

.documents-area {
  display: grid;
  grid-area: mid;

  .manager-page__list-item-options {
    cursor: pointer;
  }

  .manager-page__table table .manager-page__list-item {
    td:first-child {
      min-width: 360px;
      max-width: 460px;
    }

    td:nth-child(2) {
      min-width: 100px;
      max-width: 315px;
    }

    td:last-child {
      a {
        @extend %download-icon;
      }
    }
  }
  %download-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
  }
}
