@import '../style/foundation/breakpoints';
@import '../style/foundation/colors';
@import '../style/foundation/typography';
/* autoprefixer grid: no-autoplace */
$parameters-column-width-XL: 354px;
$parameters-column-width-LG: 334px;
$parameters-column-width-MD: 290px;
$results-column-width-MD: 400px;
$results-column-width-LG: 400px;
$results-column-width-XL: minmax(520px, 20%);

.view-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: hidden;
}

.app {
  display: grid;
  grid-template-areas:
    'header'
    'page';
  grid-template-rows: auto minmax(0, 1fr);
  grid-template-columns: 100%;
  min-width: 1200px;
  max-width: 100vw;
  height: 100vh;

  &__page {
    position: relative;

    grid-area: page;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__page--calculation {
    @include respond-to('lg') {
      grid-template-areas: 'parameters test-values performance recommendations drive-details';
      grid-template-rows: auto;
      grid-template-columns: $parameters-column-width-LG 1fr auto auto minmax(
          30%,
          10000fr
        );
      overflow: hidden;
    }
    @include respond-to('xl') {
      grid-template-areas: 'parameters test-values performance recommendations drive-details';
      grid-template-rows: auto;
      grid-template-columns: $parameters-column-width-XL 1fr auto auto minmax(
          50%,
          10000fr
        );
    }
    display: grid;
    grid-area: page;
    grid-template-areas: 'parameters test-values performance recommendations drive-details';
    grid-template-rows: auto;
    grid-template-columns: $parameters-column-width-MD 1fr auto auto minmax(
        360px,
        10000fr
      );
  }

  &__page--selected-products {
    @include respond-to('lg') {
      grid-template-rows: minmax(110px, 130px) auto;
      grid-template-columns: 60px minmax(323px, 50%) minmax(280px, 50%);
    }
    @include respond-to('xl') {
      grid-template-rows: minmax(110px, 130px) auto;
      grid-template-columns: 60px minmax(600px, 50%) minmax(200px, 50%);
    }
    z-index: 1;

    display: grid;
    grid-area: page;
    grid-template-areas:
      'release-drive-button drive-details console-column'
      'release-drive-button drive-details console-column';
    grid-template-rows: minmax(110px, 130px) auto;
    grid-template-columns: 60px minmax(800px, 50%) minmax(600px, 30%);
  }

  &__page--selected-products-nrwg {
    @include respond-to('lg') {
      grid-template-rows: minmax(110px, 130px) auto;
      grid-template-columns: 60px auto minmax(280px, 1fr);
      height: 100%;
    }
    @include respond-to('xl') {
      grid-template-rows: minmax(110px, 130px) auto;
      grid-template-columns: 60px auto minmax(200px, 1fr);
    }
    z-index: 1;

    display: grid;
    grid-area: page;
    grid-template-areas:
      'release-drive-button nrwg-products-column drive-details'
      'release-drive-button nrwg-products-column drive-details ';
    grid-template-rows: minmax(110px, 130px) auto;
    grid-template-columns: 60px auto 1fr;

    &.app__page--nrwg-locked {
      @include respond-to('lg') {
        grid-template-columns: 0 auto minmax(280px, 1fr);
        height: 100%;
      }
      @include respond-to('xl') {
        grid-template-columns: 0 auto minmax(200px, 1fr);
      }
      grid-template-rows: minmax(110px, 130px) auto;
      grid-template-columns: 0 auto 1fr;
    }
  }

  &__page--console-calculation {
    @include respond-to('lg') {
      grid-template-rows: 100%;
      grid-template-columns: 60px $parameters-column-width-LG minmax(200px, 1fr);
    }
    @include respond-to('xl') {
      grid-template-rows: 100%;
      grid-template-columns:
        60px
        $parameters-column-width-XL
        minmax(200px, 50%);
    }
    z-index: 1;

    display: grid;
    grid-area: page;
    grid-template-areas: 'release-drive-button console-parameters console-selection';
    grid-template-columns: 60px $parameters-column-width-MD minmax(200px, 1fr);

    background-color: $dark-blue;
  }

  &__page--projects-manager {
    @include respond-to('xl') {
      grid-template-columns: 1fr minmax(1280px, 70%) 1fr;
    }
    display: grid;
    grid-area: page;
    grid-template-areas: 'left mid right';
    grid-template-columns: 1fr minmax(920px, 75%) 1fr;

    background-color: $light-grey;
  }

  &__page--templates-manager {
    @include respond-to('xl') {
      grid-template-columns: 1fr minmax(1280px, 70%) 1fr;
    }
    display: grid;
    grid-area: page;
    grid-template-areas: 'left mid right';
    grid-template-columns: 1fr minmax(920px, 75%) 1fr;

    background-color: $light-grey;
  }

  &__page--documents-area {
    @include respond-to('xl') {
      grid-template-columns: 1fr minmax(1280px, 70%) 1fr;
    }
    display: grid;
    grid-area: page;
    grid-template-areas: 'left mid right';
    grid-template-columns: 1fr minmax(920px, 75%) 1fr;

    background-color: $light-grey;
  }

  &__page--entering {
    left: 0;
    z-index: 3;

    transition-timing-function: ease-out;
    transition-duration: 500ms;
  }

  &__page--entered {
    left: 0;
    z-index: 3;
  }

  &__page--exiting {
    left: 100%;

    transition-timing-function: ease-out;
    transition-duration: 500ms;
  }

  &__page--exited {
    left: 100%;
  }

  &__page--stack-position-0 {
    z-index: 1;
  }

  &__page--stack-position-1 {
    z-index: 2;
  }

  &__page--stack-position-2 {
    z-index: 3;
  }
}
%info-icon {
  @extend %icon;
  display: inline-block;
  width: 25px;
  height: 25px;

  color: rgba(#000, 0.35);
  font-size: 25px;
  line-height: 24px;

  transform: rotate(180deg);

  transition-duration: 0.3s;

  content: 'error_outlined';
}

.MuiSnackbar-root .MuiPaper-root {
  display: flex !important;
}

.MuiSnackbar-root.success-message .MuiPaper-root {
  color: #2e703b;

  background-color: #eaf4de;
}
