@import '../../../style/foundation/typography';
@import '../../../style/foundation/spacings';
@import '../../../style/foundation/colors';

.lock-dialog {
  &_content {
    margin-right: $spacing-MD;
    margin-left: $spacing-MD;
  }

  &__description {
    margin-bottom: $spacing-MD;

    text-align: left;
  }

  &__order-number {
    text-align: left;
  }
}
