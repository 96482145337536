@import '../style/foundation/spacings';
@import '../style/foundation/typography';
@import '../style/foundation/colors';

.vertical-button {
  @extend %display-sans-XL;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  max-width: 100%;
  padding-top: $spacing-LG;

  text-align: right;

  transform: rotate(180deg);
  cursor: pointer;
  writing-mode: vertical-rl;

  &--back {
    top: -1px;
    bottom: 0;

    grid-row: 1/3;
    grid-column: 1/2;

    background-color: $dark-blue;
  }

  &__title {
    color: $text-dark-secondary;

    transition-duration: 0.3s;
  }

  &--remove {
    width: 60px;

    .vertical-button__title {
      opacity: 0;
    }

    &:hover {
      .vertical-button__title {
        opacity: 1;
      }
    }
  }

  &__icon-container {
    position: relative;

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;
    margin-top: $spacing-MD * 1.25;
    margin-bottom: $spacing-MD;

    border-radius: 4000px;
    transform: scale(0.9) rotate(180deg);
    opacity: 0.44;

    transition-timing-function: ease-out;
    transition-duration: 0.2s;
  }

  &:hover {
    color: $text-dark-primary;

    .vertical-button__icon-container {
      transform: scale(1) rotate(180deg);
      opacity: 0.64;
    }
  }

  &--seperator {
    border-left: 1px solid $very-dark-blue;
  }
}
