/* roboto-100 - latin */
@font-face {
  font-weight: 100;
  font-family: Roboto;
  font-style: normal;
  src: url('roboto-v20-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin'), local('Roboto-Thin'),
    url('roboto-v20-latin-100.eot?#iefix') format('embedded-opentype'),
    url('roboto-v20-latin-100.woff2') format('woff2'),
    url('roboto-v20-latin-100.woff') format('woff'),
    url('roboto-v20-latin-100.ttf') format('truetype'),
    url('roboto-v20-latin-100.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-100italic - latin */
@font-face {
  font-weight: 100;
  font-family: Roboto;
  font-style: italic;
  src: url('roboto-v20-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
    url('roboto-v20-latin-100italic.eot?#iefix') format('embedded-opentype'),
    url('roboto-v20-latin-100italic.woff2') format('woff2'),
    url('roboto-v20-latin-100italic.woff') format('woff'),
    url('roboto-v20-latin-100italic.ttf') format('truetype'),
    url('roboto-v20-latin-100italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300 - latin */
@font-face {
  font-weight: 300;
  font-family: Roboto;
  font-style: normal;
  src: url('roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
    url('roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'),
    url('roboto-v20-latin-300.woff2') format('woff2'),
    url('roboto-v20-latin-300.woff') format('woff'),
    url('roboto-v20-latin-300.ttf') format('truetype'),
    url('roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-300italic - latin */
@font-face {
  font-weight: 300;
  font-family: Roboto;
  font-style: italic;
  src: url('roboto-v20-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
    url('roboto-v20-latin-300italic.eot?#iefix') format('embedded-opentype'),
    url('roboto-v20-latin-300italic.woff2') format('woff2'),
    url('roboto-v20-latin-300italic.woff') format('woff'),
    url('roboto-v20-latin-300italic.ttf') format('truetype'),
    url('roboto-v20-latin-300italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-weight: 400;
  font-family: Roboto;
  font-style: normal;
  src: url('roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
    url('roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('roboto-v20-latin-regular.woff2') format('woff2'),
    url('roboto-v20-latin-regular.woff') format('woff'),
    url('roboto-v20-latin-regular.ttf') format('truetype'),
    url('roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-weight: 400;
  font-family: Roboto;
  font-style: italic;
  src: url('roboto-v20-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Italic'), local('Roboto-Italic'),
    url('roboto-v20-latin-italic.eot?#iefix') format('embedded-opentype'),
    url('roboto-v20-latin-italic.woff2') format('woff2'),
    url('roboto-v20-latin-italic.woff') format('woff'),
    url('roboto-v20-latin-italic.ttf') format('truetype'),
    url('roboto-v20-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-weight: 500;
  font-family: Roboto;
  font-style: normal;
  src: url('roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'),
    url('roboto-v20-latin-500.woff2') format('woff2'),
    url('roboto-v20-latin-500.woff') format('woff'),
    url('roboto-v20-latin-500.ttf') format('truetype'),
    url('roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin */
@font-face {
  font-weight: 500;
  font-family: Roboto;
  font-style: italic;
  src: url('roboto-v20-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
    url('roboto-v20-latin-500italic.eot?#iefix') format('embedded-opentype'),
    url('roboto-v20-latin-500italic.woff2') format('woff2'),
    url('roboto-v20-latin-500italic.woff') format('woff'),
    url('roboto-v20-latin-500italic.ttf') format('truetype'),
    url('roboto-v20-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-mono-regular - latin */
@font-face {
  font-weight: 400;
  font-family: 'Roboto Mono';
  font-style: normal;
  src: url('roboto-mono-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono'), local('RobotoMono-Regular'),
    url('roboto-mono-v7-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('roboto-mono-v7-latin-regular.woff2') format('woff2'),
    url('roboto-mono-v7-latin-regular.woff') format('woff'),
    url('roboto-mono-v7-latin-regular.ttf') format('truetype'),
    url('roboto-mono-v7-latin-regular.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-mono-500 - latin */
@font-face {
  font-weight: 500;
  font-family: 'Roboto Mono';
  font-style: normal;
  src: url('roboto-mono-v7-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Mono Medium'), local('RobotoMono-Medium'),
    url('roboto-mono-v7-latin-500.eot?#iefix') format('embedded-opentype'),
    url('roboto-mono-v7-latin-500.woff2') format('woff2'),
    url('roboto-mono-v7-latin-500.woff') format('woff'),
    url('roboto-mono-v7-latin-500.ttf') format('truetype'),
    url('roboto-mono-v7-latin-500.svg#RobotoMono') format('svg'); /* Legacy iOS */
}
/* roboto-condensed-regular - latin */
@font-face {
  font-weight: 400;
  font-family: 'Roboto Condensed';
  font-style: normal;
  src: url('roboto-condensed-v18-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
    url('roboto-condensed-v18-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    url('roboto-condensed-v18-latin-regular.woff2') format('woff2'),
    url('roboto-condensed-v18-latin-regular.woff') format('woff'),
    url('roboto-condensed-v18-latin-regular.ttf') format('truetype'),
    url('roboto-condensed-v18-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
@font-face {
  font-weight: 400;
  font-family: 'Material Icons';
  font-style: normal;
  src: url(MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(MaterialIcons-Regular.woff2) format('woff2'),
    url(MaterialIcons-Regular.woff) format('woff'),
    url(MaterialIcons-Regular.ttf) format('truetype');
}
