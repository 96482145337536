%marker {
  display: inline-block;

  &::before {
    @include respond-to('xl') {
      width: 9px;
      height: 9px;
      margin-bottom: 0;
    }
    @extend %marker-base-properties;
    background-color: $yelling-yellow;
  }
}
%correction-factor-marker {
  display: inline-block;

  &::after {
    @include respond-to('xl') {
      width: 9px;
      height: 9px;
      margin-bottom: 2px;
    }
    @extend %marker-base-properties;
    margin-left: 5px;

    background-color: $active-blue;
  }
}
%marker-base-properties {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: $spacing-XS;

  border-radius: 100%;
  transform: translateY(-6px);

  transition-timing-function: ease-in-out;
  animation: pulsing-dot-animation 1.5s infinite;

  content: ' ';
}
