@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';
@import '../../style/foundation/colors';

.test-phases__data-dialog-windload {
  outline: 0;

  .dialog__content {
    padding: 1rem;
  }

  &-button {
    margin-bottom: $spacing-MD;
  }

  &-form {
    grid-template-areas:
      'area glas angle windload . . . .'
      'minDrives maxDrives driveType lock . . . .'
      'headlinePosition headlinePosition headlinePosition headlinePosition . . . .'
      'opposite side traverse . . . . .'
      'headlineFormat headlineFormat headlineFormat headlineFormat . . . .'
      'tall across . . . . . .'
      'headlineOpeningDirection headlineOpeningDirection headlineOpeningDirection headlineOpeningDirection . . . .'
      'in out . . . . . .';

    &-area {
      grid-area: area;
    }

    &-glas {
      grid-area: glas;
    }

    &-angle {
      grid-area: angle;
    }

    &-windload {
      grid-area: windload;
    }

    &-minDrives {
      grid-area: minDrives;
    }

    &-maxDrives {
      grid-area: maxDrives;
    }

    &-driveType {
      grid-area: driveType;
    }

    &-lock {
      grid-area: lock;
    }

    &-headlinePosition {
      grid-area: headlinePosition;
      margin-top: $spacing-LG;
      margin-bottom: $spacing-MD;
    }

    &-opposite {
      grid-area: opposite;
    }

    &-side {
      grid-area: side;
    }

    &-traverse {
      grid-area: traverse;
    }

    &-headlineFormat {
      grid-area: headlineFormat;
      margin-top: $spacing-LG;
      margin-bottom: $spacing-MD;
    }

    &-tall {
      grid-area: tall;
    }

    &-across {
      grid-area: across;
    }

    &-headlineOpeningDirection {
      grid-area: headlineOpeningDirection;
      margin-top: $spacing-LG;
      margin-bottom: $spacing-MD;
    }

    &-in {
      grid-area: in;
    }

    &-out {
      grid-area: out;
    }
  }
}
