@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';
@import '../../style/foundation/colors';

.drives-group-data-dialog {
  display: grid;
  grid-template-areas:
    'name name name . .'
    'test-phase test-phase test-phase . .'
    'select-group select-group select-group . .';
  grid-template-rows: auto auto 1fr;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  background-color: #fff;

  &__name {
    grid-area: name;
  }

  &__test-phase {
    grid-area: test-phase;
  }

  &__select-group {
    grid-area: select-group;
  }
}
