@import './../style/foundation/spacings';
@import './../style/foundation/typography';
@import './../style/foundation/colors';

.form-layout {
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: $spacing-MD;
  grid-column-gap: $spacing-MD;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  max-width: 100%;
  padding: $spacing-MD;

  &--four-columns {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &--inline {
    padding: initial;
  }

  &__element--horizontal-centered {
    display: flex;
    align-items: center;
  }

  &__input-field-title {
    margin-bottom: $spacing-XL;
  }

  &__sub-group-title {
    @extend %font-sans-MD;
    color: $text-dark-secondary;
    font-weight: 500;
  }

  &__sub-group {
    display: grid;
    grid-template-rows: auto auto;

    //align at the top
    align-items: start;

    column-gap: $spacing-LG;

    .form-layout__sub-group-title {
      grid-row: 1;
      width: 100%;
      margin-top: $spacing-SM;
      margin-bottom: $spacing-MD;
    }

    .input-field {
      height: 100%;
    }
  }

  &__sub-group--2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .form-layout__sub-group-title {
      grid-column: 1/3;
      margin-top: auto;
    }

    .input-field {
      margin-top: auto;
    }
  }

  &__sub-group--3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    .form-layout__sub-group-title {
      grid-column: 1/4;
      margin-top: auto;
    }

    .input-field {
      margin-top: auto;
    }
  }

  &__sub-group--4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    .form-layout__sub-group-title {
      grid-column: 1/5;
    }
  }

  &__sub-group--6 {
    grid-column: 6;
  }
}
