@import './../../../style/foundation/typography';
@import './../../../style/foundation/spacings';
@import '@material/textfield/mdc-text-field';

.number-box {
  display: block;

  &__input {
    width: 43%;
  }
}
