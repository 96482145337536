@import '../../../style/foundation/colors';
@import '../../../style/foundation/typography';
@import '../../../elements/Marker';
@import '../../../app/App';

// SPACINGS
$editable-box-inset-top: 1px;
$editable-box-inset-right: $spacing-SM;
$editable-box-inset-bottom: $spacing-SM;
$editable-box-inset-left: $spacing-SM;
$editable-box-inline-left: $spacing-SM;
$editable-box-inline-right: $spacing-SM;
$editable-box-title-inline-left: $spacing-SM;

// COLORS
$editable-box-background: $white;
@keyframes pulsing-dot-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(2);
  }

  100% {
    transform: scale(1);
  }
}

.editable-box {
  margin-top: $spacing-XS * 1.5;
  margin-right: $editable-box-inline-right;
  margin-bottom: $spacing-SM;
  margin-left: $editable-box-inline-left;
  padding: $editable-box-inset-top $editable-box-inset-right
    $editable-box-inset-bottom $editable-box-inset-left;

  direction: ltr;
  text-align: left;

  background-color: $editable-box-background;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);

  transition-duration: 0.1s;

  &:last-child {
    margin-bottom: $spacing-XL;
  }

  &__content {
    position: relative;
  }

  &__range {
    @extend %font-sans-SM;
    color: $text-dark-secondary;

    opacity: 0;

    transition-timing-function: ease-in;
    transition-duration: 0.25s;
  }

  &__range--active {
    opacity: 1;
  }

  &__title {
    @extend %font-sans-MD;
    display: inline-flex;
    margin-top: $spacing-SM * 0.8;
    margin-bottom: $spacing-SM * 1.5;
    overflow: hidden;

    color: $text-dark-secondary;
    font-weight: 500;
  }

  &:first-child {
    margin-top: $spacing-XXS;
  }

  &__close-button-title {
    @extend %font-sans-MD;
    position: relative;
    right: -100%;

    display: inline-block;
    width: 0;

    opacity: 0;

    transition-duration: 0.05s;
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 26px;
    margin-left: auto;
    padding-right: 0;
    padding-left: $spacing-XS;
    overflow: hidden;

    color: $text-dark-secondary;

    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    box-shadow: 0 0 0 6px #fff;
    cursor: pointer;

    transition-timing-function: ease-in;
    transition-duration: 0.05s;

    &::before {
      @extend %icon;
      right: 0;

      display: flex;
      align-items: center;
      height: 100%;
      padding-right: $spacing-XS;

      color: $text-dark-secondary;
      font-size: 16px;

      background-color: #fff;

      content: 'unfold_less';
    }

    &:hover {
      width: auto;
      padding-right: $spacing-SM;

      border: 1px solid $text-dark-disabled;

      transition-timing-function: ease-in;

      .editable-box__close-button-title {
        right: 0%;

        width: auto;

        opacity: 1;
      }
    }
  }

  &__close-button-hover-area {
    position: absolute;
    top: $base-unit * 5;
    right: 0;

    height: 26px;
    padding-left: $spacing-MD * 1.5;

    color: transparent;

    &:hover {
      .editable-box__close-button {
        border: 1px solid #dcdcdc;

        transition-timing-function: ease-in;
      }

      .editable-box__close-button-title {
        right: 0%;

        width: auto;

        opacity: 1;
      }
    }
  }

  &__information-icon-container {
    display: block;
    width: 25px;
    height: 25px;
    margin-top: -10px;
    margin-left: auto;
    padding: 0;
    overflow: hidden;

    text-align: center;

    transform: translate(5px, 2.5px);
    cursor: pointer;

    &::after {
      @extend %info-icon;
    }
  }
}

.editable-box--snow-load-invalid {
  .editable-box__range {
    @extend %marker;
    white-space: nowrap;

    opacity: 1;
  }
}

.editable-box--angle-of-installation-invalid {
  .editable-box__range {
    @extend %marker;
    opacity: 1;
  }
}

.editable-box--number-of-drives-invalid {
  .editable-box__range {
    @extend %marker;
    opacity: 1;
  }
}

.editable-box--wind-load-invalid {
  .editable-box__range {
    @extend %marker;
    white-space: nowrap;

    opacity: 1;
  }
}

.editable-box--sash-weight-invalid {
  .tab-bar-with-values__range--sashWeight {
    @extend %marker;
  }

  .editable-box__range {
    @extend %marker;
    opacity: 1;
  }
}

.editable-box--sash-width-invalid {
  .window-area-box__width .editable-box__range {
    @extend %marker;
    opacity: 1;
  }
}

.editable-box--sash-height-invalid {
  .window-area-box__height .editable-box__range {
    @extend %marker;
    opacity: 1;
  }
}

.editable-box--distance-to-hinge-invalid {
  .tab-bar-with-values__range {
    @extend %marker;
  }
}

.editable-box--opening-angle-invalid {
  .tab-bar-with-values__range {
    @extend %marker;
  }
}

.editable-box--glass-thickness-invalid {
  .tab-bar-with-values__range--glassThickness {
    @extend %marker;
  }
}

.editable-box--opening-stroke-invalid {
  .opening-angle-stroke-box__parameter--opening-stroke .editable-box__range {
    @extend %marker;
    white-space: nowrap;

    opacity: 1;
  }
}

.window-area-box__tested-area--invalid {
  .window-area-box__tested-area-label {
    @extend %marker;
    opacity: 1;
  }

  .window-area-box__locking-drive-marker {
    @extend %marker;
    top: 50%;

    opacity: 1;
  }

  .window-area-box__locking-drive-label {
    @extend %font-sans-SM;
    color: $text-dark-secondary;
  }
}

.window-area-box__tested-ratio--invalid {
  .window-area-box__tested-ratio-label {
    @extend %marker;
    white-space: nowrap;

    opacity: 1;
  }
}

.editable-box--sash-frame-distance-invalid {
  .window-area-box__sash-frame-distance .editable-box__range {
    @extend %marker;
    white-space: nowrap;

    opacity: 1;
  }
}

.editable-box--ratio-width-to-double-flap-height-invalid {
  .window-area-box__tested-double-flap-ratio--invalid {
    @extend %marker;
    display: inline-flex;
    align-items: center;

    white-space: nowrap;

    opacity: 1;
  }
}
