@import './../../style/foundation/colors';
@import './../../style/foundation/typography';
@import './../../elements/Button';

.project-dialog {
  grid-template-areas:
    'project-name project-name project-name project-name . .'
    'project-description project-description project-description project-description  . .'
    'project-location project-location . . . .';

  &__name {
    grid-area: project-name;
  }

  &__description {
    grid-area: project-description;
  }

  &__location {
    grid-area: project-location;
  }
}
