@use './../style/foundation/colors';

//@use "@material/theme" with (
//  $primary: colors.$dh-red,
//  $secondary: colors.$dh-red,
//  $on-primary: colors.$dh-red,
//  $on-secondary: colors.$dh-red,
//);
@use '@material/switch';
@use '@material/theme/shadow-dom';
@import './../style/foundation/spacings';
@import './../style/foundation/typography';

.switch {
  @include switch.theme(
    (
      selected-track-color: colors.$dh-red,
      selected-focus-track-color: colors.$dh-red,
      selected-hover-track-color: colors.$dh-red,
      selected-icon-size: 0,
      unselected-icon-size: 0,
      selected-focus-handle-color: colors.$white,
      selected-handle-color: colors.$white,
      selected-hover-handle-color: colors.$white,
      selected-pressed-handle-color: colors.$white,
      unselected-focus-handle-color: colors.$white,
      unselected-handle-color: colors.$white,
      unselected-hover-handle-color: colors.$white,
      unselected-pressed-handle-color: colors.$white,
      selected-focus-state-layer-color: colors.$dh-red,
      selected-hover-state-layer-color: colors.$dh-red,
      selected-pressed-state-layer-color: colors.$dh-red,
      unselected-focus-state-layer-color: colors.$dh-red,
      unselected-hover-state-layer-color: colors.$dh-red,
      unselected-pressed-state-layer-color: colors.$dh-red,
    )
  );
  position: relative;

  .mdc-switch__handle {
    border-color: rgb(0 0 0 / 20%);
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
      0 1px 5px 0 rgb(0 0 0 / 12%);
  }
}

label {
  @extend %font-sans-MD;
  margin-left: $spacing-SM;

  color: colors.$text-dark-primary;

  cursor: pointer;
}

.switch.mdc-switch {
  margin-right: $spacing-SM;
  margin-left: $spacing-SM;

  border-radius: 20px;

  .mdc-switch__thumb {
    width: 22px;
    height: 22px;

    background-color: white;
    border: 1px solid rgba(#000, 0.2) !important;
  }

  .mdc-switch__underlay {
    background-color: #dcdcdc;
  }

  .mdc-switch__track {
    background-color: #dcdcdc;
  }
}

.mdc-switch.mdc-switch--selected {
  border-radius: 20px;

  .mdc-switch__underlay {
    background-color: #dcdcdc;
  }

  .mdc-switch__thumb {
    width: 22px;
    height: 22px;

    background-color: white;
    border: 1px solid rgba(#000, 0.2);
  }
}
