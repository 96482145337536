@import './../../style/foundation/colors';
@import './../../style/foundation/typography';

// COLORS
$header-background: $white;

.header {
  @extend %font-sans-MD;
  display: grid;
  grid-area: header;
  grid-template-areas:
    'project-nav . app-header . project-options'
    'project-nav . project-information . .';
  grid-template-rows: 32px auto;
  grid-template-columns: 500px 1fr auto 1fr 500px;
  justify-content: center;
  box-sizing: content-box;
  width: 100%;
  max-width: 100vw;

  background-color: $header-background;
  border-bottom: 1px solid #e2e0e0;

  .select-search-container {
    position: relative;

    //position: relative;
    //display: flex;
    align-items: center;
    width: 84px !important;
    height: 32px !important;
    margin-right: 16px;

    border: none !important;

    &::after {
      position: absolute;
      top: 10px;
      right: 0;

      display: block;
      width: 10px;
      height: 10px;

      color: rgb(0 0 0 / 34%);
      font-size: 24px;
      font-family: 'Material Icons', sans-serif;

      transform: translate(-14px, -5px);

      content: 'arrow_drop_down';
    }

    &::before {
      position: absolute;

      //z-index: 4;
      top: 5px;

      display: inline-block;

      color: rgb(0 0 0 / 64%);
      font-size: 20px;
      font-family: 'Material Icons', sans-serif;

      content: 'public';
    }
  }

  .select-search-input {
    position: relative;
    z-index: 1;

    display: flex;
    height: 32px !important;
    padding-right: 0 !important;
    padding-left: 30px !important;

    font-size: 15px !important;
    line-height: 22px !important;

    background-color: transparent !important;
    border: none !important;
  }

  .select-search-value {
    height: 32px;
  }

  .select-search-options {
    margin-top: 4px !important;
    padding-left: 0;
  }

  .select-search-row {
    display: block;
  }

  .select-search-option {
    color: #202020;
    font-weight: 400 !important;
    font-size: 15px !important;

    cursor: pointer;
  }

  .select-search-option:hover {
    color: black !important;

    background-color: white !important;
  }

  .select-search-is-selected {
    color: black !important;

    background-color: rgb(244 244 244) !important;

    &:hover {
      background-color: rgb(244 244 244) !important;
    }
  }

  .select-search-select {
    position: absolute;
    top: 30px !important;
    z-index: 100 !important;

    box-sizing: border-box;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;

    background-color: rgb(255 255 255);
    border-color: rgb(155 155 155 / 50%) !important;
    border-width: 1px !important;
    border-radius: 4px !important;
    box-shadow: rgb(0 0 0 / 10%) 0 0 0 1px, rgb(0 0 0 / 10%) 0 4px 11px;
  }

  &__register-button {
    margin-top: auto;
    margin-right: $spacing-XS;
    margin-bottom: auto;
    padding: 1px $spacing-SM;

    color: white;
    text-decoration: none;

    background-color: $dh-red;
    border: 1px solid rgb(0 0 0 / 15%);
    border-radius: 3px;
    cursor: pointer;
  }

  &__login-button {
    display: flex;
    grid-column-gap: $spacing-XS * 1.25;
    grid-template-columns: auto auto;
    align-items: center;

    background: transparent;
    border: none;
    cursor: pointer;
  }

  &__project-navigation {
    display: grid;
    grid-area: project-nav;
    grid-template-areas:
      'icon label'
      'breadcrumb breadcrumb';
    grid-template-rows: 18px 34px;
    grid-template-columns: 18px auto;
    padding-top: $spacing-SM;
    padding-left: $spacing-SM * 1.5;

    color: $text-dark-secondary;

    cursor: pointer;
  }

  &__project-navigation-badges {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 1px;
    margin-left: $spacing-XS;

    column-gap: $spacing-XS * 0.75;
  }

  &__project-navigation-icon {
    grid-area: icon;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__project-navigation-breadcrumb {
    @extend %font-sans-LG;
    display: flex;
    flex-direction: row;
    grid-area: breadcrumb;
    align-items: center;
    overflow: hidden;

    color: $text-dark-primary;
  }

  &__project-navigation-label {
    @extend %font-sans-MD;
    display: flex;
    grid-area: label;
    align-items: center;
    margin-left: $spacing-SM;

    color: $text-dark-secondary;
  }

  &__project-navigation-breadcrumb-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: $spacing-XS;

    white-space: nowrap;

    &:first-child {
      margin-right: 0;
      margin-left: 0;

      &::after {
        @extend %icon;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 14px;

        color: $text-dark-secondary;
        font-size: 18px;
        line-height: 0;

        content: 'chevron_right';
      }
    }

    &:nth-child(2) {
      display: block;
      max-width: 100%;
      overflow-x: hidden; /* "overflow"-Wert darf nicht "visible" sein */
      overflow-y: visible;

      text-overflow: ellipsis;
    }
  }

  &__beta-badge {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 16px;
    margin-top: 1px;
    margin-left: $spacing-SM;
    padding-top: 1px;
    padding-right: 2px;
    padding-left: 2px;

    color: $text-dark-primary;
    font-size: 12px;
    letter-spacing: 1.1px;
    text-transform: uppercase;

    border: solid 1px $text-dark-primary;
    border-radius: 3px;
    transform: skew(-10deg);
  }

  &__select {
    @extend %font-sans-MD;
    position: relative;
    z-index: 4;

    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;
    margin-right: $spacing-MD;

    border-radius: 0 !important;

    &::before {
      @extend %icon;
      position: absolute;
      left: 0;

      display: inline-block;

      color: $text-dark-secondary;
      font-size: 20px;

      content: 'public';
    }

    &--overlay-active {
      z-index: 0;
    }
  }

  &--manager-page-active {
    background-color: $light-grey;
    border-bottom: 1px solid rgba(#fff, 0);
  }

  &__control {
    display: flex;
    align-items: center;
    height: 32px !important;
    min-height: 20px !important;

    background-color: transparent !important;
    border-width: 0;
    border-radius: 0 !important;
    cursor: pointer !important;

    &--is-focused {
      box-shadow: none !important;
    }

    &::after {
      @extend %icon;
      color: $text-dark-disabled;
      font-size: 24px;

      content: 'arrow_drop_down';
    }
  }

  &__value-container {
    display: flex;
    align-items: center;
    width: 60px !important;
    height: 32px !important;
    padding: 0 !important;
    padding-left: $spacing-MD * 1.75 !important;
  }

  &__indicators {
    display: none !important;
  }

  &__dropdown-indicator {
    display: none !important;
  }

  &__app-header {
    display: flex;
    grid-area: app-header;
    align-items: center;
    justify-content: center;
    height: 30px;
  }

  &__project-information {
    @extend %font-sans-LG;
    display: grid;
    grid-area: project-information;
    grid-template-areas: 'folder-icon name icon';
    grid-template-columns: 32px auto 32px;
    margin-left: $spacing-SM * -1.25;
  }

  &__project-name {
    grid-area: name;
    max-width: 400px;
    padding-top: $spacing-SM * 1.5;
    overflow: hidden;

    color: $text-dark-primary;
    line-height: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__project-icon-folder svg {
    @include respond-to('md') {
      width: 30px;
      height: 21px;

      transform: translateY(1px);
    }
    @include respond-to('lg') {
      width: 30px;
      height: 21px;

      transform: translateY(1px);
    }
    @include respond-to('xl') {
      width: 30px;
      height: 25px;

      transform: translateY(-1.5px);
    }
    display: inline;
    grid-area: folder-icon;

    opacity: 0.34;
  }

  &__info-icon {
    @include respond-to('xl') {
      margin-top: -4px;
    }
    grid-area: icon;
    width: 32px;
    height: 32px;
    margin-top: 2px;
    margin-bottom: -2px;
    margin-left: 5px;

    cursor: pointer;
  }

  &__my-projects {
    @extend %font-sans-MD;
    display: flex;
    grid-area: my-projects;
    align-items: center;
    margin-right: $spacing-SM * 1.2;
    margin-left: auto;

    color: $text-dark-primary;

    svg {
      margin-right: $spacing-XS * 1.5;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__project-options {
    display: flex;
    grid-area: project-options;
    grid-template-areas: 'contact application-areas log-in-out language-select';
    grid-template-rows: auto;
    grid-template-columns: auto auto auto auto;
    margin-bottom: auto;
    margin-left: auto;

    .popup-menu,
    .header__login-button,
    .header__logout-button,
    .header__my-projects,
    .header__register-button {
      margin-right: $spacing-MD;
    }

    .header__help-button {
      margin: 6px 20px 0 0;
      padding-left: 7px;

      cursor: pointer;
    }
  }

  &__projects-icon {
    margin-right: $spacing-SM;

    opacity: 0.64;
  }

  &__login-hint {
    @extend %font-sans-LG;
    margin-bottom: $spacing-MD;

    text-align: left;
  }

  &__login-hint-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 80px;

    .button {
      margin-top: $spacing-LG;
      margin-left: 0;
    }
  }

  .icon-help svg {
    width: 20px;
    height: 20px;
    margin-top: 6px;

    cursor: pointer;
  }
}

.MuiPaper-root {
  display: block !important;
  flex-direction: unset !important;
}

.information-dialog__nav-container,
.information-dialog__content {
  float: left;
}

.clear {
  clear: both;
}
