@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';
@import '../../style/foundation/colors';

.admin-hint {
  @extend %font-sans-SM;
  margin-top: $spacing-SM;

  color: $text-dark-secondary;
  font-weight: 400;
}
