@import '../style/foundation/typography';
@import '../style/foundation/spacings';
@import '../style/foundation/colors';

$button-red: $primary;
$item-hover: $light-grey;
$item-selected: $light-blue;
$table-column-background: #fdfdfd;
@keyframes sucess-animation {
  0% {
    transform: scale(1, 1) translateY(0);
    opacity: 1;
  }

  3% {
    transform: scale(1.1, 0.9) translateY(0);
    opacity: 1;
  }

  6% {
    transform: scale(0.9, 1.1) translateY(-40px);
    opacity: 1;
  }

  8% {
    transform: scale(1, 1) translateY(0);
    opacity: 1;
  }

  10% {
    transform: scale(1, 1) translateY(-14px);
    opacity: 1;
  }

  14% {
    transform: scale(1, 1) translateY(0);
    opacity: 1;
  }

  16% {
    transform: scale(1, 1) translateY(0);
    opacity: 1;
  }

  100% {
    transform: scale(1, 1) translateY(0);
    opacity: 1;
  }
}

.system-series-dialog {
  @include respond-to('xl') {
    margin-top: -6px;
  }
  display: grid;
  grid-template-areas:
    'searchbar'
    'label'
    'table';
  grid-template-rows: auto auto 1fr;
  grid-template-columns: auto;
  height: 536px;
  margin-top: -7px;

  &--wizard {
    position: relative;

    grid-template-areas:
      'navBar'
      'searchbar'
      'label'
      'table';
    grid-template-rows: auto auto auto 1fr;
    height: 100%;
    overflow: hidden;
  }

  &__sucess-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-top: $spacing-XL * 1.5;
  }

  &__sucess-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing-LG;

    animation: sucess-animation 6.25s;
  }

  &__sucess-text {
    @extend %font-sans-LG;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    margin-right: auto;
    margin-left: auto;

    color: $text-dark-secondary;
    text-align: center;
  }

  &__search-bar-bg {
    z-index: 500;

    grid-area: searchbar;
    padding-top: 20px;
    padding-right: $spacing-MD;
    padding-bottom: $spacing-MD;
    padding-left: $spacing-MD;

    background-color: white;
    background-color: $light-grey;
  }

  &__table-label {
    @extend %font-sans-MD;
    z-index: 800;

    grid-area: label;
    padding: $spacing-SM * 1.75 $spacing-MD;

    background-color: white;
  }

  &__add-button-label {
    @extend %font-sans-LG;
    position: relative;

    display: inline-block;
    width: 0;
    padding-right: 0;

    color: $text-dark-secondary;
    color: $dh-red;

    opacity: 0;

    transition-duration: 0;
  }

  &__profile-no-exchange {
    color: $text-dark-disabled;
  }

  &__add-button {
    position: absolute;
    top: 0;
    right: 0;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin-left: auto;
    padding-right: $spacing-MD;
    padding-left: $spacing-MD;

    color: $text-dark-secondary;

    background-color: transparent;
    border: none;
    outline-width: 0;
    cursor: pointer;

    &::before {
      @extend %icon;
      display: flex;
      align-items: center;
      height: 100%;
      margin-right: $spacing-XS;

      color: $text-dark-disabled;
      font-size: 22px;

      transition-duration: 0.2s;

      content: 'add_circle_outline';
    }
  }

  &__button {
    @include respond-to('lg') {
      padding: $spacing-SM * 1.5 $spacing-MD;
    }
    @include respond-to('xl') {
      padding: $spacing-SM * 1.5 $spacing-MD;
    }
    @extend %font-sans-LG;
    display: flex;
    grid-area: button;
    max-width: 280px;
    margin-bottom: $spacing-MD;
    padding: $spacing-SM * 1.5 $spacing-MD;

    color: $text-dark-secondary;
    font-weight: 500;
    text-decoration: none;

    background-color: transparent;
    border: 2px solid rgba(#212121, 0.1);
    cursor: pointer;

    transition: border-color 0.25s;

    &:hover {
      border: 2px solid rgba(#212121, 0.3);
    }
  }

  &__button-icon {
    @extend %icon;
    margin-left: auto;

    color: $text-dark-disabled;
    font-size: 24px;
  }

  &result {
    display: flex;
  }

  &__navigation-bar {
    @include respond-to('xl') {
      padding-right: $spacing-MD;
      padding-bottom: $spacing-MD;
      padding-left: $spacing-MD;
    }
    display: flex;
    flex-direction: row;
    grid-area: navBar;
    max-width: 100%;
    height: 48px;
    padding-right: $spacing-MD;
    padding-bottom: $spacing-SM * 1.25;
    padding-left: $spacing-MD;
    overflow: hidden;
  }

  &__navigation-bar-item-close-button {
    position: absolute;
    top: 0;
    right: 0;

    width: 32px;
    height: 32px;

    cursor: pointer;

    &::after {
      @extend %icon;
      position: absolute;
      top: 0;
      right: 4px;

      color: rgba($text-dark-secondary, 0.4);
      font-size: 16px;

      content: 'cancel';
    }
  }

  &__navigation-bar-item-content {
    @extend %font-sans-LG;
    overflow-x: hidden;

    color: $text-dark-primary;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
    hyphens: auto;
  }

  &__navigation-bar-item {
    @include respond-to('xl') {
      height: 55px;
      padding-right: $spacing-MD;
      padding-left: $spacing-SM;
    }
    @extend %font-sans-MD;
    position: relative;

    box-sizing: border-box;
    min-width: 110px;
    max-width: 200px;
    height: 50px;
    margin-right: 10px;
    padding-right: $spacing-SM;
    padding-left: $spacing-XS;

    background-color: white;
    border: 1px solid #e8e8e8;
    border-radius: 5px;
  }

  &__navigation-bar-item-container {
    flex-direction: column;
  }

  &__navigation-bar-item-label {
    @extend %font-sans-SM;
    margin-bottom: $spacing-XS * -0.4;
    margin-left: $spacing-XS;

    color: rgba($text-dark-secondary, 0.5);
    text-transform: uppercase;

    opacity: 0;

    &--filled {
      opacity: 1;
    }
  }

  &__navigation-bar-item--empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: $spacing-MD;
    padding-left: $spacing-SM;

    color: $text-dark-disabled;

    background-color: #fbfbfb;
    border: 1px dashed rgb(0 0 0 / 20%);
    box-shadow: inset 0 0 4px rgb(0 0 0 / 15%);

    &::before {
      @extend %icon;
      margin-right: $spacing-SM;

      color: rgba($text-dark-disabled, 0.15);
      font-size: 25px;

      content: 'control_point';
    }
  }

  &__navigation-bar-item--selected {
    border: solid 2px $dh-red;
  }

  &__navigation-bar-item--filled {
    display: flex;
    align-items: center;
    padding-right: $spacing-MD * 1.5;
    padding-left: $spacing-SM * 1.75;

    color: $text-dark-primary;

    box-shadow: 0 2px 4px rgb(0 0 0 / 12%);

    .system-series-dialog__navigation-bar-item-label {
      @extend %font-sans-SM;
    }
  }
}

.result-table {
  position: relative;

  grid-area: table;
  margin-right: $spacing-MD;
  margin-left: $spacing-MD;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &__table {
    @extend %font-sans-MD;
    position: relative;

    width: 100%;
    overflow: visible;

    table-layout: auto;
    border: none;
    border-spacing: 0;
  }

  &__header-row {
    width: 100%;
  }

  &__header-cell {
    position: sticky;
    top: 0;
    z-index: 1;

    min-width: 20%;
    max-width: 40%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-left: $spacing-SM;

    color: $text-dark-secondary;
    font-weight: 300;
    text-align: left;

    background-color: #f2f2f2;
    background-color: $light-grey;
  }

  &__row {
    position: relative;

    cursor: pointer;

    &:last-child {
      margin-bottom: $spacing-LG;
    }
  }

  &__cell {
    @extend %font-sans-LG;
    position: relative;

    min-width: 10px;
    max-width: 280px;
    margin-top: 0;
    padding-top: $spacing-SM * 1.5;
    padding-right: 40px;
    padding-bottom: $spacing-SM * 1.5;
    padding-left: 10px;
    overflow: hidden;

    color: $text-dark-secondary;
    text-overflow: ellipsis;

    cursor: pointer;
  }
}

.result-table__row.result-table__row--selected,
.result-table__row:hover {
  .result-table__cell {
    color: $text-dark-primary;
  }

  .system-series-dialog__add-button {
    right: 0%;

    width: auto;
    padding-right: 0;

    transition-timing-function: ease-in;

    &::before {
      color: $dh-red;
    }
  }

  .system-series-dialog__add-button-label {
    right: 0%;

    width: auto;
    padding-right: $spacing-MD;

    opacity: 1;

    transition-timing-function: ease-out;
    transition-duration: 0.1s;
  }
}

.result-table__row.result-table__row--selected {
  background-color: $item-selected;

  .system-series-dialog__add-button {
    background-color: $item-selected;
  }
}

.result-table__row.result-table__row--selected:hover {
  background-color: $item-selected;
}

.result-table__row:hover {
  background-color: $item-hover;

  .system-series-dialog__add-button {
    background-color: $item-hover;
  }
}

.result-table__head {
  position: absolute;
  z-index: 600;

  grid-area: table-header;
}
