@import './../../style/foundation/typography';
@import './../../style/foundation/spacings';
@import './../../style/foundation/colors';

// SPACINGS
$parameter-section-header-inset-top: $spacing-SM * 0.85;
$parameter-section-header-inset-right: $spacing-SM * 1.15;
$parameter-section-header-inset-bottom: 0;
$parameter-section-header-inset-left: $spacing-SM * 1.6;
$parameter-list-item-inset-left: $spacing-SM;
$parameter-list-item-inset-right: $spacing-SM;

// COLORS
$parameter-background: $light-grey;

.nrwg-definition-section {
  z-index: 0;

  grid-row-start: 1;
  grid-row-end: 2;
  grid-column-start: 1;
  height: 100%;

  &__body {
    height: calc(100% - #{$base-unit * 73});
    margin-right: $spacing-SM;
    margin-left: $spacing-SM;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: $parameter-section-header-inset-top
      $parameter-section-header-inset-right
      $parameter-section-header-inset-bottom
      $parameter-section-header-inset-left;

    background-color: $parameter-background;
  }

  &__title {
    @extend %display-sans-MD;
    color: $black-64;
    line-height: 0;
  }

  &__empty-state {
    display: block;
    margin: $spacing-SM;
    padding: $spacing-LG;

    color: $text-dark-disabled;

    background-color: $darker-grey;
  }

  &__list-item {
    @include respond-to('lg') {
      padding-top: $spacing-XS * 0.8;
      padding-bottom: $spacing-XS * 0.8;
    }
    @include respond-to('xl') {
      padding-top: $spacing-XS;
      padding-bottom: $spacing-XS;
    }
    @extend %font-sans-MD;
    position: relative;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: $spacing-XS * 1.25;
    margin-bottom: $spacing-XS * 1.25;
    padding-top: $spacing-XS * 0.75;
    padding-bottom: $spacing-XS * 0.75;
    overflow: hidden;

    color: $text-dark-primary;
    line-height: 18px;

    transition-duration: 0.1s;

    &::after {
      @include respond-to('lg') {
        line-height: 26px;
      }
      @include respond-to('xl') {
        line-height: 26px;
      }
      @extend %icon;
      position: absolute;
      top: 0;
      right: $spacing-MD * -1;
      bottom: 0;

      display: inline-block;
      width: 20px;
      margin-right: $spacing-XS;

      color: $text-dark-secondary;
      line-height: 26px;

      transform: scale(0);
      opacity: 0;

      transition-duration: 0.15s;

      content: 'cancel';
    }

    &:hover {
      padding-right: $spacing-MD * 1.25;

      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
      cursor: pointer;

      &::after {
        @extend %icon;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        margin-right: $spacing-XS;

        color: $text-dark-secondary;

        transform: scale(1);
        opacity: 1;

        content: 'cancel';
      }
    }

    &:last-child {
      margin-bottom: $spacing-LG;
    }
  }

  &__list-item-name {
    padding-left: $parameter-list-item-inset-left;

    text-align: left;
  }

  &__list-item-data {
    max-width: 50%;
    padding-right: $parameter-list-item-inset-right;

    text-align: right;
    word-wrap: break-word;
  }
}
