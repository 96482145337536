@import '@material/textfield/mdc-text-field';
@import './../../../style/foundation/spacings';
@import './../../../style/foundation/colors';
@import './../../../style/foundation/typography';

.number-field {
  width: 100%;

  &--small {
    width: 50%;
  }

  &__input {
    position: relative;

    width: 100%;
    height: $base-unit * 44;

    border: solid 1px #d3d3d3;
    box-shadow: inset 0 0 0 0 white;

    transition-timing-function: ease-in;
    transition-duration: 0.2s;

    &-input-wrap {
      display: block;
      height: 100%;
    }

    &--focused {
      .number-field__input {
        border-bottom: solid 1px white;
        box-shadow: inset 0 -2px 0 0 $primary;

        transition-timing-function: ease-in;
        transition-duration: 0.2s;
      }
    }

    input {
      display: inline-block;
      width: calc(100% - 46px);
      height: 100%;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: $spacing-SM * 1.25;

      background-color: transparent;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }

  &--focused {
    .number-field__input {
      border-bottom: solid 1px white;
      box-shadow: inset 0 -2px 0 0 $primary;

      transition-timing-function: ease-in;
      transition-duration: 0.25s;
    }
  }

  &__input-field-icon {
    @extend %icon;
    position: absolute;
    right: 0;

    width: 24px;
    height: 50%;
    padding-right: $spacing-SM;

    font-size: 20px;
    line-height: $base-unit * 23;
    text-align: right;

    cursor: pointer;

    &--top {
      top: 0;

      padding-top: 3px;
    }

    &--bottom {
      bottom: 0;

      padding-bottom: 3px;
    }
  }

  &--disabled {
    .number-field__input {
      position: relative;

      &::after {
        @extend %icon;
        position: absolute;
        top: 25%;
        right: 0;
        bottom: 0;

        display: inline;
        height: 100%;
        padding-top: $spacing-SM * 1.3;
        padding-right: $spacing-SM * 1.3;

        color: $text-dark-disabled;
        font-size: 15px;
        line-height: 0;

        content: 'lock';
      }
    }
  }
}
