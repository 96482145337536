$breakpoint-SM: 767px;
$breakpoint-MD: 992px;
$breakpoint-LG: 1420px;
$breakpoint-XL: 1890px;
$breakpoint-XL-height: 975px;
$footer-breakpoint-MD: 1435px;
$breakpoints: (
  'md': '(min-width: #{$breakpoint-MD})',
  'lg': '(min-width: #{$breakpoint-LG})',
  'xl':
    '(min-width: #{$breakpoint-XL}) and (min-height: #{$breakpoint-XL-height})',
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{map-get($breakpoints, $breakpoint)} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. " + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
