@import './../../style/foundation/colors';
@import './../../style/foundation/typography';
@import './../../elements/Button';

$item-hover: rgb(255 255 255 / 65%);

.templates-manager {
  .manager-page__table table .manager-page__list-item {
    td:first-child {
      min-width: 300px;
      max-width: 400px;
    }

    td:nth-child(2) {
      min-width: 0;
      max-width: 280px;
    }
  }

  .manager-page__list-item-cell {
    cursor: default;
  }
}
