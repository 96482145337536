@import './../../../style/foundation/colors';
@import './../../../style/foundation/typography';
@import './../../../style/foundation/spacings';

.select-field-box {
  @extend %font-sans-MD;
  position: relative;
  z-index: 1;

  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 4;
  height: $base-unit * 44;
  padding-bottom: $spacing-MD;

  text-align: center;
}
