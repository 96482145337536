@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';
@import '../../style/foundation/colors';

.general-drives-data-dialog {
  overflow: auto;

  &__layout-basic {
    display: grid;
    grid-template-areas:
      'article-nr article-nr . . . .'
      'label label . . . .'
      'info info . . . .'
      'family family . . . .'
      'active active . . . .'
      'preview preview . . . .'
      'scheme scheme scheme . . .'
      'tested-facade tested-facade tested-facade . . .'
      'tested-roof tested-roof tested-roof . . .';
  }

  &__layout-basic-information {
    display: grid;
    grid-template-areas:
      'voltage voltage . . . .'
      'type type type . . .'
      'power high-speed-power . . . .'
      'power-consumption high-speed-power-consumption . . . .'
      'laufzeit high-speed-laufzeit . . . .'
      //'high-speed-laufzeit . . . . .'
      'push-force . . . . .'
      'pull-force . . . . .'
      'holding-force . . . . .'
      'reserve-push-force . . . . .'
      'reserve-pull-force . . . . .'
      'points-amount . . . . .'
      'broad-side . . . . .'
      'max-amount . . . . .';
  }

  &__layout-basic-information--stroke-variants {
    grid-template-areas:
      'voltage voltage . . . .'
      'type type type . . .'
      'laufzeit high-speed-laufzeit . . . .'
      'push-force . . . . .'
      'pull-force . . . . .'
      'holding-force . . . . .'
      'reserve-push-force . . . . .'
      'reserve-pull-force . . . . .'
      'points-amount . . . . .'
      'broad-side . . . . .'
      'max-amount . . . . .';
  }

  &__layout-stroke-types {
    display: grid;
    grid-template-areas:
      'action action . . . .'
      'table table table table table table';
  }

  &__layout-drive-stroke-variants {
    padding: $spacing-MD;
  }

  &__stroke-type-dialog {
    display: grid;
    grid-template-areas:
      'stroke-label stroke-label . . . .'
      'stroke-size stroke-size . . . .'
      'drive-type . . . . .'
      'active . . . . .'
      'diagram-table-sub-header diagram-table-sub-header diagram-table-sub-header diagram-table-sub-header diagram-table-sub-header diagram-table-sub-header'
      'diagram-table diagram-table diagram-table diagram-table diagram-table diagram-table';
  }

  &__drive-stroke-table {
    grid-area: table;
  }

  &__drive-type {
    grid-area: drive-type;
  }

  &__type {
    grid-area: type;
  }

  &__stroke-size {
    grid-area: stroke-size;
  }

  &__stroke-label {
    grid-area: stroke-label;
  }

  &__stroke-types {
    padding: $spacing-MD;
  }

  &__diagram-table-sub-header {
    grid-area: diagram-table-sub-header !important;
  }

  &__diagram-table {
    grid-area: diagram-table;
  }

  &__stroke-table {
    grid-area: table;
    margin-top: $spacing-MD;
  }

  &__high-speed-laufzeit {
    grid-area: high-speed-laufzeit;
  }

  &__article-nr {
    grid-area: article-nr;
  }

  &__label {
    grid-area: label;
  }

  &__info {
    grid-area: info;
  }

  &__family {
    grid-area: family;
  }

  &__active {
    grid-area: active;
  }

  &__preview {
    grid-area: preview;
    margin-bottom: $spacing-LG;
  }

  &__scheme {
    grid-area: scheme;
  }

  &__tested-facade {
    grid-area: tested-facade;
  }

  &__tested-roof {
    grid-area: tested-roof;
  }

  &__power {
    grid-area: power;
  }

  &__power-consumption {
    grid-area: power-consumption;
  }

  &__high-speed-power {
    grid-area: high-speed-power;
  }

  &__high-speed-power-consumption {
    grid-area: high-speed-power-consumption;
  }

  &__push-force {
    grid-area: push-force;
  }

  &__pull-force {
    grid-area: pull-force;
  }

  &__holding-force {
    grid-area: holding-force;
  }

  &__reserve-push-force {
    grid-area: reserve-push-force;
  }

  &__reserve-pull-force {
    grid-area: reserve-pull-force;
  }

  &__laufzeit {
    grid-area: laufzeit;
  }

  &__amount-points {
    grid-area: amount-points;
  }

  &__rwa {
    grid-area: rwa;
  }

  &__points-amount {
    grid-area: points-amount;
  }

  &__broad-side {
    grid-area: broad-side;
  }

  &__max-amount {
    grid-area: max-amount;
  }

  &__stroke-table-button-column {
    display: flex;

    button:first-child {
      margin-right: $spacing-LG;
    }
  }
}
