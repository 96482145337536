@import './../../../style/foundation/typography';
@import './../../../style/foundation/spacings';
@import './../../../style/foundation/colors';
@import './../../../style/foundation/shadows';
@import '../../../elements/Marker';
@import '../Column';

.performance-class-column {
  position: relative;
  z-index: 1;

  grid-area: performance;

  &__title {
    @extend %display-sans-MD;
    margin-bottom: $spacing-MD;
  }

  &__icon-check,
  &__icon-warning {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 25px;

    svg {
      margin-top: auto;

      transform: scale(0.9);
    }
  }

  &__icon-unfold {
    @extend %icon;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: $spacing-MD;

    color: $text-dark-disabled;
    font-size: 28px;

    transform: rotate(90deg);
  }

  &__expand-action {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;

    display: none;
  }

  &__content {
    @include respond-to('xl') {
      padding: $column-top-inset-XL $column-inset-XL;
    }
    width: 400px;
    height: 100%;
    padding: $column-top-inset-LG $column-inset-LG;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  &__expand-lable {
    @extend %display-sans-LG;
    display: flex;
    align-items: center;
    width: 40px;

    color: $text-dark-secondary;
    white-space: nowrap;
    text-orientation: mixed;

    transform: rotate(-180deg);
    writing-mode: vertical-rl;
  }

  &__class-list {
    @extend %font-sans-LG;
    display: block;

    color: $text-dark-primary;
  }

  &__class-list-item {
    @extend %font-sans-LG;
    display: flex;
    grid-column-gap: $spacing-SM * 1.25;
    align-items: center;
  }

  &__class-list-item-text {
    @extend %font-sans-LG;
    margin: 0 0 0 32px;

    color: $text-dark-secondary;
  }

  &__empty-state-invalid-parameters {
    @extend %font-sans-MD;
    @extend %shadow-elevation-1;
    position: relative;
    z-index: 1;

    display: grid;
    grid-column-gap: $spacing-SM;
    grid-template-columns: auto auto;
    margin-right: auto;
    margin-bottom: $spacing-MD;
    margin-left: auto;
    padding: $spacing-MD;

    color: #b58107;
    font-weight: 500;
    line-height: 22px;

    background-color: #fff8db;
    border-radius: 4px;
  }

  &__empty-state-invalid-parameters-marker {
    @extend %marker;
  }

  &__collapse-action {
    @extend %icon;
    position: absolute;
    top: 0;
    right: 0;

    display: block;
    width: 60px;
    height: 60px;

    color: $text-dark-secondary;
    font-size: 24px;

    background-color: transparent;
    border: none;
    transform: rotate(90deg);
    cursor: pointer;
  }

  &--closed {
    width: 40px;

    .performance-class-column__content {
      opacity: 0;
    }

    .performance-class-column__expand-action {
      display: block;

      cursor: pointer;
    }

    .performance-class-column__collapse-action {
      display: none;
    }
  }
}
