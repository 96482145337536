@import '../../../style/foundation/typography';
@import '../../../style/foundation/spacings';
@import '../../../style/foundation/colors';
@import '../../../elements/Button';
@import '../../../elements/title';
@import '../../../elements/byline';

.console-details {
  @include respond-to('lg') {
    padding: 1.5vh;
  }
  @include respond-to('xl') {
    padding-top: 4vh;
    padding-left: 4vh;
  }
  display: grid;
  grid-area: console-details;
  grid-template-areas:
    'console-details-title'
    'console-details-description' 'console-add-button';
  grid-template-rows: auto auto auto 1fr;
  grid-template-columns: 1fr;
  padding: $spacing-MD * 1 $spacing-MD * 1;

  &__description-errors {
    margin-top: $spacing-MD;
    margin-bottom: $spacing-MD;
  }

  &__button {
    @extend %button;
    @extend %button--large;
    @extend %button--secondary;
    grid-area: console-add-button;
    width: 330px;
  }

  &__confirm-button {
    grid-area: console-add-button;
    width: 330px;
  }

  &__button-icon {
    @extend %icon;
    margin-left: auto;

    color: $text-dark-disabled;
    font-size: 24px;
  }

  &__description-title {
    grid-area: console-details-title;
  }

  &__title-byline {
    @extend %byline;
  }

  &__description-name {
    @extend %title;
  }

  &__description-text-container {
    @extend %font-sans-LG;
    grid-area: console-details-description;
    margin-bottom: $spacing-MD * 1.5;

    color: $text-dark-secondary;
  }

  &__description-small-headline {
    @extend %byline;
  }

  &__description-text {
    margin-bottom: $spacing-LG;

    ul {
      padding-left: 24px;
    }

    a {
      color: $text-dark-primary;
    }
  }
  
  &__assembly-message::first-letter {
    text-transform: lowercase;
  }
}
