@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';
@import '../../style/foundation/colors';

.cv-data-dialog {
  display: grid;
  grid-template-areas:
    'name name name name . .'
    'curve curve curve curve . .'
    'add-curve add-curve add-curve add-curve . .'
    ' values values values values . .';
  overflow: auto;

  background-color: #fff;

  &__values {
    grid-area: values;
    grid-template-areas:
      'title'
      'content';

    .field-label {
      display: none;
    }

    textarea {
      height: 230px;
    }
  }

  &__curve {
    grid-area: curve;
    grid-template-areas:
      'title'
      'content';
  }

  &__title-number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: $spacing-SM;
    margin-bottom: $spacing-MD;

    color: $dh-red;
    font-weight: 500;

    border: 1px solid $input-field-border;
    border-radius: 100%;
  }

  &__curve-select {
    @extend %font-sans-LG;
    display: block;
    width: 100%;
    height: 230px;

    color: $text-dark-primary;

    border: 1px solid $input-field-border;

    &:focus {
      outline: none;
    }

    option {
      display: flex;
      align-items: center;
      height: 44px;
      padding-left: $spacing-SM;
    }

    option:checked {
      background-color: $dark-grey !important;
    }
  }

  &__curve-title {
    display: flex;
    flex-direction: row;
    grid-area: title;
    align-items: center;
    justify-content: space-between;
    height: 44px;

    button {
      @extend %icon;
      width: 34px;
      height: 34px;
      margin-bottom: $spacing-XS;

      color: $dh-red;
      font-weight: 400;
      font-size: 18px;

      background-color: transparent;
      border: solid 1px $input-field-border;
      border: solid 2px $dh-red;
      border-radius: 4px;
      cursor: pointer;

      button {
        height: 44px;

        color: $dh-red;

        background-color: transparent;
        border: solid 2px $dh-red;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        cursor: pointer;
      }

      &:first-child {
        margin-right: 0;

        border-right: 1px solid $input-field-border;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:nth-child(2) {
        margin-right: 2px;

        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__curve-content {
    grid-area: curve-content;
  }

  &__add-curve {
    grid-area: add-curve;
    grid-template-areas:
      'title'
      'content';
  }

  &__add-curve-content {
    display: grid;
    grid-area: content;
    grid-template-rows: 44px;
    grid-template-columns: 120px 100px 170px 100px 100px 250px;

    input,
    .select-field__control {
      border-right: 1px transparent !important;
    }

    .field-label {
      display: none;
    }
  }

  &__add-curve-title {
    @extend %font-sans-MD;
    grid-area: title;

    line-height: 40px;
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__name {
    display: block;
    grid-area: name;
    width: 100%;
  }

  &__switch {
    grid-area: switch;
  }

  &__file-upload {
    width: 150px;
    margin-bottom: $spacing-MD;
  }

  &__input {
    display: none;
  }
}

.cv-data {
  grid-area: data-view;
  max-height: 100%;
  overflow: hidden;

  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
