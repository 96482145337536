@import './../style/foundation/spacings';
@import './../style/foundation/typography';
@import './../style/foundation/colors';

.badge {
  @extend %font-sans-XS;
  display: inline;
  padding: 0 5px;

  line-height: 19px;

  border-radius: 3px;

  &--default {
    color: white;

    background-color: #a9a9a9;
  }

  &--nrwg {
    color: white;

    background-color: #ff5722;
  }

  &--rwa {
    color: white;

    background-color: #47b760;
  }
}
