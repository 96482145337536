@import './../../style/foundation/colors';
@import './../../style/foundation/typography';
@import './../../style/foundation/spacings';

// SPACINGS
$footer-inset-top-SM: 0;
$footer-inset-right-SM: $spacing-SM;
$footer-inset-bottom-SM: 0;
$footer-inset-left-SM: $spacing-SM;
$footer-inset-top-LG: 0;
$footer-inset-right-LG: $spacing-MD;
$footer-inset-bottom-LG: $spacing-SM;
$footer-inset-left-LG: $spacing-LG;
$logo-inset-top: $spacing-MD;
$logo-inset-right: $spacing-MD;
$logo-stack: $spacing-XXS * -1;
$footer-background-color: $dark-blue;

.footer {
  grid-area: footer;
  margin-top: $spacing-MD;
  padding: $footer-inset-top-SM $footer-inset-right-SM $footer-inset-bottom-SM
    $footer-inset-left-SM;

  &__logo {
    @include respond-to('xl') {
      width: 125 * $base-unit;
    }
    display: inline-block;
    width: 95 * $base-unit;
    height: auto;
    margin-bottom: $logo-stack;
    padding-top: $logo-inset-top;
    padding-right: $logo-inset-right;
  }

  &__cta {
    @media (min-width: $breakpoint-XL) and (max-height: 1200px) {
      display: none;
    }
    @media (min-width: $breakpoint-LG) and (max-height: 850px) {
      display: none;
    }
    @media (max-height: 1050px) {
      display: none;
    }
    @include respond-to('xl') {
      margin-bottom: $spacing-SM;
    }
    @extend %display-sans-XL;
    display: inline-block;
    width: 100%;
    margin-bottom: $spacing-SM;
    padding-top: $spacing-SM * 1.5;

    font-style: italic;

    border-top: 1px solid $black-14;
    border-top: 1px solid rgba(#000, 0.1);
  }

  &__cta-extended {
    @media (max-width: $footer-breakpoint-MD) {
      display: inline-block;
    }
    display: none;
  }

  &__contact-information {
    @include respond-to('xl') {
      margin-bottom: $spacing-XS;
    }
    @extend %font-sans-LG;
    display: block;
    margin-bottom: $spacing-XS * 0.8;

    color: $text-dark-secondary;
    font-style: normal;
    line-height: 28 * $base-unit;
  }

  &__contact-information-icon {
    display: inline-flex;
    max-width: 20px;
    margin-right: 3px;
    margin-left: 3px;

    transform: translateY(6.5px);
  }

  &__contact-information-text {
    display: inline-block;
    margin-right: $spacing-XS;

    line-height: 40px;
  }

  &__contact-link {
    display: inline-block;
    margin-right: $spacing-XS;

    color: $primary;
    text-decoration: none;

    border-radius: 4px;

    transition: color 0.3s;

    &:hover {
      color: $primary;
      text-decoration: underline;
    }
  }

  &__legal-information {
    @extend %font-sans-SM;
    display: block;

    color: $text-dark-disabled;
    font-style: normal;
  }

  &__legal-link {
    color: $text-dark-disabled;
    text-decoration: none;

    cursor: pointer;

    transition: color 0.3s;

    &:hover {
      color: $black-54;
    }
  }

  &__legal-link-extended {
    @media (max-width: $footer-breakpoint-MD) {
      display: none;
    }
    display: inline-block;
  }
}
