@import '../../../style/foundation/spacings';
@import '../../../style/foundation/typography';
@import '../../../style/foundation/colors';
@import '../../../elements/Button';
@import '../../../elements/title';
@import '../../../elements/byline';

.console-column {
  @include respond-to('lg') {
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 60px minmax(400px, 64%) 1fr;

    column-gap: $base-unit * 25;
  }
  @include respond-to('xl') {
    grid-template-rows: auto auto auto auto auto;
    grid-template-columns: 60px minmax(600px, 56%) 1fr;

    column-gap: 5%;
  }
  display: grid;
  grid-area: console-column;
  grid-template-areas:
    '. . .'
    '. CTA .'
    '. . .';
  grid-template-rows: auto auto auto 1fr;
  grid-template-columns: 60px minmax(400px, 60%) 1fr;

  background-color: $light-blue;

  &__add-locking-console-button {
    padding: 2rem 0 0;

    border-top: 1px solid $very-dark-blue;
  }

  &__no-console-message {
    @extend %title;
    display: flex;
    grid-row: 1/2;
    grid-column: 1/6;
    align-items: center;
    justify-content: center;

    &--select-console {
      margin-left: 40px;
    }
  }

  &__description {
    @include respond-to('lg') {
      padding-top: 2vh;
      padding-left: 100px;
    }
    @include respond-to('xl') {
      padding-top: 4vh;
      padding-left: 100px;
    }
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-left: 100px;
  }

  &__vertical-button {
    grid-row: 1/3;
    grid-column: 1/2;
  }

  &__CTA {
    @include respond-to('xl') {
      padding-top: $spacing-LG;
      padding-right: $spacing-XL;
      padding-left: $spacing-XL;
    }
    display: inline-block;
    grid-area: CTA;
    padding-top: $spacing-LG;
    padding-right: $spacing-MD;
    padding-left: $spacing-MD;

    color: $text-dark-secondary;

    &--with_vld {
      border-top: 1px solid $very-dark-blue;
    }
  }

  &--console-selected {
    grid-template-areas:
      '. description .'
      '. description .'
      '. . .';
    overflow: auto;

    background-color: $dark-blue;
    border-left: 1px solid $very-dark-blue;

    .console-column__description {
      display: grid;
      grid-area: description;
      grid-template-areas:
        'heading'
        'text'
        'console-details'
        'SelectedConsoleCTA'
        'button'
        'reload';
      grid-template-rows: auto auto auto auto;

      .console-details {
        padding: 2rem 0 0;

        border-top: 1px solid #a7c0cc;
      }
    }

    .console-column__CTA {
      grid-area: SelectedConsoleCTA;
      margin-right: auto;
      margin-left: 0;

      text-align: left;

      border-top: 1px solid $very-dark-blue;
    }
  }

  &__title {
    grid-area: heading;
  }

  &__description-small-headline {
    @extend %byline;
  }

  &__description-name {
    @extend %title;
  }

  &__description-text-container {
    @extend %font-sans-LG;
    grid-area: text;

    color: $text-dark-secondary;
  }

  &__description-text {
    margin-bottom: $spacing-LG;

    ul {
      padding-left: 24px;
    }

    a {
      color: $text-dark-primary;
    }
  }

  &__CTA-headline {
    @extend %display-sans-XXL;
    width: auto;
    margin-right: auto;
    margin-bottom: $spacing-MD * 1.5;

    font-style: italic;
    text-align: center;
  }

  &__CTA-button-primary {
    @extend %button;
    @extend %button--large;
    @extend %button--primary;
    grid-area: button;
  }

  &__remove-action {
    grid-area: removeAction;
  }

  &__assembly-data {
    margin-bottom: $spacing-LG;
  }

  &__reload-action {
    @extend %button;
    @extend %button--tertiary;
    grid-area: reload;
    margin-right: auto;
    margin-bottom: $spacing-MD;
    margin-left: auto;

    &::before {
      @extend %icon;
      margin-right: $spacing-SM;

      color: $text-dark-disabled;
      font-size: 22px;

      transition-duration: 0.3s;

      content: 'refresh';
    }

    &:hover {
      border: none;

      .console-column::before {
        transform: rotate(40deg);
      }
    }
  }
}
