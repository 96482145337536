.tutorial-dialog {
  &__heading {
    display: block;

    font-weight: bold;
    font-size: 24px;
    font-family: Roboto, sans-serif;
    font-style: normal;
  }

  &__message {
    width: 1284px;
    height: 723px;

    line-height: 723px;

    background-color: lightgray;
  }

  &__paragraph {
    font-size: 16px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    text-align: center;
    vertical-align: center;
  }

  .information-dialog__nav-container {
    width: 200px;
  }

  .information-dialog__content {
    width: 1288px;
    padding-bottom: 1.5rem;
  }
}
