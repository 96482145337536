@import '../../style/foundation/spacings';

.roof-sash-profile-data-dialog {
  width: 100%;

  background-color: #fff;

  &__layout-basic {
    display: grid;
    grid-template-areas:
      'nav nav nav nav nav nav'
      'article-nr article-nr . . . .'
      'info info info info  . .' 'serie serie . . . .'
      'switch switch switch . . .'
      'preview preview preview . . .';
  }

  &__layout-basic-information {
    display: grid;
    grid-template-areas:
      'sashgroup sashgroup sashgroup . . .'
      'min-strength min-strength min-strength max-strength max-strength max-strength'
      'min-filling min-filling min-filling max-filling max-filling max-filling'
      'max-weight max-weight max-weight . . .'
      'max-sash-width max-sash-width max-sash-width max-sash-height max-sash-height max-sash-height'
      'min-tilt min-tilt min-tilt max-tilt max-tilt max-tilt'
      'distance-40 distance-40 distance-40 distance-50 distance-50 distance-50'
      'distance-60 distance-60 distance-60 distance-70 distance-70 distance-70'
      'distance-80 distance-80 distance-80 . . .';
  }

  &__hint {
    color: rgb(249 191 59 / 100%);
  }

  &__min-strength {
    grid-area: min-strength;
  }

  &__max-strength {
    grid-area: max-strength;
  }

  &__min-filling {
    grid-area: min-filling;
  }

  &__max-filling {
    grid-area: max-filling;
  }

  &__max-weight {
    grid-area: max-weight;
  }

  &__max-sash-width {
    grid-area: max-sash-width;
  }

  &__max-sash-height {
    grid-area: max-sash-height;
  }

  &__min-tilt {
    grid-area: min-tilt;
  }

  &__max-tilt {
    grid-area: max-tilt;
  }

  &__distance-40 {
    grid-area: distance-40;
  }

  &__distance-50 {
    grid-area: distance-50;
  }

  &__distance-60 {
    grid-area: distance-60;
  }

  &__distance-70 {
    grid-area: distance-70;
  }

  &__distance-80 {
    grid-area: distance-80;
  }

  &__layout-properties {
    display: grid;
    grid-template-areas:
      ' isolation isolation isolation . . .'
      'opening-direction opening-direction opening-direction . . .'
      'application application application application  . . ';
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__installation {
    grid-area: installation;
  }

  &__opening-direction {
    grid-area: opening-direction;
    margin-bottom: $spacing-SM;
  }

  &__opening-direction--disabled {
    pointer-events: none;
  }

  &__application {
    grid-area: application;
    margin-bottom: $spacing-SM;
  }

  &__min-chuck {
    grid-area: min-chuck;
  }

  &__max-chuck {
    grid-area: max-chuck;
  }

  &__integrated {
    grid-area: isolation;
    margin-bottom: $spacing-SM * 1.5;
  }

  &__profile-type {
    grid-area: profile-type;
  }

  &__integrated-measurement {
    grid-area: integrated-measurement;
    margin-bottom: $spacing-SM * 1.5;
  }

  &__sash-group {
    grid-area: sashgroup;
  }

  &__uf {
    grid-area: uf;
  }

  &__building-depth {
    grid-area: building-depth;
  }

  &__max-sash-addition {
    grid-area: max-sash-addition;
  }

  &__max-sash-weight {
    grid-area: max-sash-weight;
  }

  &__ix {
    grid-area: ix;
  }

  &__iy {
    grid-area: iy;
  }

  &__measurement-inside {
    grid-area: measurement-inside;
  }

  &__measurement-outside {
    grid-area: measurement-outside;
  }

  &__article-nr {
    grid-area: article-nr;
  }

  &__info {
    grid-area: info;
  }

  &__serie {
    grid-area: serie;
  }

  &__switch {
    grid-area: switch;
  }

  &__preview {
    grid-area: preview;
  }
}

.roof-sash-profile-data {
  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
