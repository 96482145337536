@import '../../style/foundation/spacings';

.basic-profile-data-dialog {
  width: 100%;

  background-color: #fff;

  &__layout-basic {
    display: grid;
    grid-template-areas:
      'nav nav nav nav nav nav'
      'article-nr serie . . . .'
      'info info . .  . .'
      'switch switch switch . . .'
      'preview preview preview . . .';
  }

  &__layout-basic-information {
    display: grid;
    grid-template-areas:
      'measurement-inside measurement-outside . . . .'
      'pfosten pfosten . . . .'
      'building-depth . max-sash-weight max-sash-weight . .'
      'max-sash-addition . . . . .';
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__pfosten {
    grid-area: pfosten;
  }

  &__building-depth {
    grid-area: building-depth;
  }

  &__measurement-inside {
    grid-area: measurement-inside;
  }

  &__measurement-outside {
    grid-area: measurement-outside;
  }

  &__article-nr {
    grid-area: article-nr;
  }

  &__info {
    grid-area: info;
  }

  &__serie {
    grid-area: serie;
  }

  &__switch {
    grid-area: switch;
  }

  &__preview {
    grid-area: preview;
  }
}

.basic-profile-data {
  grid-area: data-view;
  max-height: 100%;
  overflow: hidden;

  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
