@import './../style/foundation/spacings';
@import './../style/foundation/typography';
@import './../style/foundation/colors';

.info-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: $spacing-MD;

  &__icon {
    justify-content: center;
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    text-align: center;
  }

  &__text {
    @extend %display-sans-LG;
    width: 100%;
    max-width: 340px;
    margin-right: auto;
    margin-left: auto;

    color: $text-dark-secondary;
    text-align: center;
  }
}
