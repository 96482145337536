.loading-skeleton {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  width: 100%;
  height: 100%;
  overflow: hidden;

  background-color: rgb(247 247 247);
  cursor: default;

  &::before {
    position: absolute;
    top: 0;
    left: -150px;
    z-index: 200;

    display: block;
    width: 150px;
    height: 100%;

    background: linear-gradient(
      to right,
      transparent 0%,
      #e8e8e8 50%,
      transparent 100%
    );

    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;

    content: '';
  }

  .console-item__name {
    opacity: 0;
  }
}
