@import './../style/foundation/spacings';
@import './../style/foundation/typography';
@import './../style/foundation/colors';

.input-field {
  @extend %font-sans-MD;
  box-sizing: border-box;
  margin-top: auto;

  &__field {
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    padding-left: $spacing-MD;

    border: 1px solid $input-field-border;
  }

  &__error {
    color: $dh-red;
  }

  &__small {
    width: 30px;
  }
}

.input-field-number .input-field-number-input {
  @extend %font-sans-MD;
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  margin-top: auto;
  padding-left: $spacing-MD;

  border: 1px solid $input-field-border;
}
