@import './../../style/foundation/colors';
@import './../../style/foundation/typography';

.mdc-drawer {
  min-width: 420px;

  transition-delay: 0s;
}

.mdc-drawer--open {
  transition-delay: 0.25s;
}

.project-navigation {
  display: grid;
  grid-template-areas:
    'header'
    'content'
    'action';
  grid-template-rows: 85px 1fr 64px;
  grid-template-columns: auto;
  min-width: 400px;
  overflow-y: hidden;

  background-color: $light-grey;

  &__window-locked-icon {
    @extend %icon;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 32px;

    color: $text-dark-disabled;
    text-align: center;
  }

  &__header {
    @extend %display-sans-LG;
    display: flex;
    grid-area: header;
    align-items: center;
    justify-content: space-between;
    height: 84px;
    padding-left: $spacing-MD * 1.5;

    background-color: white;
    border-bottom: 1px solid rgba(#000, 0.1);
    border-radius: 0;
  }

  &__icon-close {
    @extend %icon;
    padding: $spacing-MD * 1.5;

    color: $text-dark-disabled;

    cursor: pointer;

    &:hover {
      color: $text-dark-secondary;
    }
  }

  &__content {
    position: relative;

    grid-area: content;
    padding: $spacing-SM * 1.5;
    overflow-y: auto;
  }

  &__new-window-object {
    display: grid;
    grid-column-gap: 0;
    grid-template-areas: 'icon text';
    grid-template-columns: 20px 1fr;
    padding: $spacing-XS $spacing-XS;

    text-align: left;

    background-color: transparent;
    border: solid 1px rgba(#000, 0.1);
    border-top: solid 1px rgba(#000, 0);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    cursor: pointer;

    transition-duration: 0.25s;
  }

  &__new-window-object-icon {
    @extend %icon;
    display: flex;
    grid-area: icon;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;

    color: $text-dark-disabled;
    font-size: 14px;
    line-height: 0;

    transition-duration: 0.25s;
  }

  &__new-window-object-label {
    display: flex;
    grid-area: text;
    align-items: center;

    color: $text-dark-secondary;
    font-size: 13px;
    font-family: Roboto, sans-serif;
    line-height: 0;

    transition-duration: 0.25s;
  }

  &__new-window-action {
    margin-bottom: $spacing-SM;
    padding-right: $spacing-SM;
    padding-left: $spacing-SM;

    border: none;

    transition-duration: 0.25s;

    &:active {
      outline: none;
    }

    :focus {
      outline: none;
    }

    &:hover {
      padding-top: $spacing-SM;
      padding-right: 0;
      padding-left: 0;

      transition-delay: 0.15s;

      .project-navigation__new-window-object {
        grid-column-gap: 2px;
        padding: $spacing-SM * 1.5 $spacing-SM * 1.5;

        background-color: white;
        border: solid 1px rgba(#000, 0);
        border-top: solid 1px rgba(#000, 0);
        border-radius: 4px;
        box-shadow: 0 2px 4px rgb(0 0 0 / 12%);

        transition-delay: 0.15s;
      }

      .project-navigation__new-window-object-label {
        @extend %font-sans-MD;
        color: $text-dark-primary;

        transition-delay: 0.15s;
      }

      .project-navigation__new-window-object-icon {
        font-size: 16px;

        transition-delay: 0.15s;
      }
    }
  }

  &__window {
    display: grid;
    grid-column-gap: 4px;
    grid-template-areas: 'name quantity';
    grid-template-columns: minmax(280px, 1fr) 1fr;

    &:focus {
      outline: none;
    }
  }

  &__window-name {
    grid-area: name;
    margin-top: auto;
    margin-bottom: 1px;
    overflow: hidden;

    color: $text-dark-secondary;
    font-weight: 500;
    font-size: 18px;
    font-family: Roboto, sans-serif;
    font-style: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__window-container {
    position: relative;

    display: grid;
    grid-template-areas:
      'window-data action'
      'selected-component-info selected-component-info';
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    margin-bottom: $spacing-SM;
    padding: $spacing-XS * 1.5 $spacing-SM $spacing-XS * 1.5 $spacing-SM * 1.25;

    font-size: $base-unit * 15;
    font-family: Roboto, sans-serif;
    font-style: normal;
    line-height: $base-unit * 22;

    background-color: white;
    border-radius: 6px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 14%), 0 8px 6px rgb(0 0 0 / 6%);

    &--menu-open {
      z-index: 5;
    }

    &--selected {
      border-radius: 6px;

      .project-navigation__window-name {
        color: $primary;
      }

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        width: 4px;
        height: 100%;

        background-color: $primary;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        content: '';
      }
    }
  }

  &__window-click-target {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    grid-area: window-data;
    width: 100%;
    height: 100%;
  }

  &__window-data-container {
    display: grid;
    grid-area: window-data;
    grid-template-areas:
      'name quantity .'
      'badges badges .'
      'window-information window-information .';
    grid-template-rows: auto auto auto;
    grid-template-columns: auto auto auto;

    color: $text-dark-primary;

    cursor: pointer;
  }

  &__selected-component-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-area: selected-component-info;
    margin: 1px -8px -6px -10px;
    padding-top: 5px;
    padding-bottom: 2px;

    color: $text-dark-secondary;
    font-weight: 500;
    font-size: $base-unit * 13;
    line-height: $base-unit * 20;
    letter-spacing: 0.25px;

    background-color: $light-grey;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    cursor: pointer;

    div::before {
      display: inline-block;
      width: 5px;
      height: 5px;
      margin-top: 1px;
      margin-right: $spacing-SM * 0.5;
      margin-bottom: 2px;
      margin-left: 11px;

      background-color: $text-dark-secondary;
      border-radius: 7.5px;

      content: '';
    }
  }

  &__selected-component-info--drive {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__selected-component-info--console {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  &__window-information {
    display: grid;
    grid-area: window-information;
    grid-template-columns: auto auto auto;
    align-items: flex-end;
    width: fit-content;
    margin-top: $spacing-XS;

    color: #858585;
    font-weight: 500;
    font-size: $base-unit * 13;
    line-height: $base-unit * 24;
    letter-spacing: 0.25px;
    column-gap: 11px;
  }

  &__window-information-item {
    display: grid;
    grid-template-areas: 'icon information';
    align-items: center;
    width: fit-content;

    column-gap: 3px;

    svg {
      margin-bottom: 1.5px;
    }
  }

  &__window-id {
    @extend %font-sans-XS;
    display: inline-flex;
    align-items: center;
    padding: 0 $spacing-XS;

    color: $text-dark-disabled;
    font-weight: 500;
    font-family: 'Roboto Mono', monospace;
    line-height: 0;
    white-space: nowrap;

    background-color: rgba(#000, 0.085);
    border-radius: 3px;

    transition-duration: 0.25s;
  }

  &__window-options {
    position: relative;
    z-index: 3;

    grid-area: action;

    color: $text-dark-primary;

    transform: translateY(-3px) translateX(5px);
  }

  &__window-badges {
    display: grid;
    grid-area: badges;
    grid-template-rows: auto;
    grid-template-columns: repeat(8, 0fr);
    margin-top: 3px;

    white-space: pre;
    column-gap: $spacing-XS * 0.75;
  }

  &__building-area {
    margin-bottom: $spacing-SM;
  }

  &__building-area-header {
    @extend %font-sans-MD;
    position: relative;

    display: grid;
    grid-column-gap: 4px;
    grid-template-areas: 'area-icon area-name area-options';
    grid-template-columns: auto minmax(auto, 1fr) auto;
    width: calc(100% - 24px);
    padding: $spacing-XS $spacing-XS $spacing-XS $spacing-SM;

    background-color: $darker-grey;
  }

  &__building-area-icon {
    @extend %icon;
    display: flex;
    grid-area: area-icon;
    align-items: center;

    color: $text-dark-secondary;
    font-size: 15px;
  }

  &__building-area-name {
    display: flex;
    grid-area: area-name;
    align-items: center;
  }

  &__building-area-options {
    grid-area: area-options;
  }

  &__button-new-area {
    @extend %font-sans-LG;
    position: absolute;
    bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    padding-right: $spacing-MD;

    color: $text-dark-primary;

    background-color: white;
    border: none;
    border-top: 2px solid #ededed;
    cursor: pointer;

    &::before {
      @extend %icon;
      margin-right: $spacing-MD;

      color: $text-dark-secondary;
      font-size: 18px;

      content: 'subdirectory_arrow_right';
    }
  }
}

.drive-details-item {
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    margin-left: 2px;
  }
}

.MuiAccordionSummary-content.Mui-expanded {
  margin-bottom: 0 !important;
}

.MuiAccordionDetails-root {
  padding-top: 0 !important;
}

.MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  margin-top: 20px;
}
