@import './../../style/foundation/typography';
@import './../../style/foundation/colors';

$parameter-background: $light-grey;

.parameters-column {
  @extend %font-sans-MD;
  display: grid;
  grid-area: parameters;
  grid-template-rows: auto auto auto auto 1fr;
  grid-template-columns: 1fr;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  text-align: center;

  background-color: $parameter-background;

  &--section-open {
    overflow: hidden;
  }
}
