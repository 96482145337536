@import '../../style/foundation/breakpoints';
@import '../../style/foundation/colors';
@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';
@import '../../admin/Admin';

.article-number-data-view {
  &__footer {
    display: flex;
    justify-content: flex-end;
    max-width: calc(100vw - 260px);
    margin-top: $spacing-LG;
  }

  &__delete-button {
    @extend %font-sans-LG;
    @extend %admin-button;
    grid-area: download-button;
    width: 30px;
    margin-right: $spacing-SM;

    background: $light-grey;

    &::before {
      content: 'delete';
    }
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    margin-left: $spacing-MD;
  }
}
