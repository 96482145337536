@import '../../../style/foundation/spacings';
@import '../../../style/foundation/typography';
@import '../../../style/foundation/colors';
@import '../../../elements/Button';

$console-results-background: #fdfdfd;

// SPACINGS
$console-results--top-inset-XL: $spacing-SM * 1.5;
$console-results-inset-XL: $spacing-MD * 3;
$console-results-top-inset-LG: $spacing-SM * 1.5;
$console-results-inset-LG: $spacing-MD;

.console-results {
  grid-area: console-results;
  overflow: hidden;

  background-color: $light-grey;
  border: 8px solid $dark-blue;

  &__content {
    @include respond-to('xl') {
      padding: $console-results--top-inset-XL $console-results-inset-XL;
    }
    height: 100%;
    padding: $console-results-top-inset-LG $console-results-inset-LG;
    overflow-y: auto;

    background-color: $console-results-background;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  &__title {
    @extend %display-sans-MD;
    margin-bottom: $spacing-MD * 1.25;

    color: $text-dark-secondary;
    font-weight: 300;
  }

  &__section {
    margin-bottom: $spacing-LG;
  }

  &__sub-title {
    @extend %display-sans-SM;
    margin-top: $spacing-SM;
    margin-bottom: $spacing-MD;

    color: $text-dark-secondary;
  }

  &__advice-container {
    margin-bottom: $spacing-LG;

    text-align: center;

    a {
      display: block;

      color: darken($active-blue, 25%);
    }
  }

  &__empty-state-advice {
    @extend %font-sans-MD;
    margin-right: auto;
    margin-bottom: $spacing-SM;
    margin-left: auto;

    color: $text-dark-secondary;
    text-align: center;
  }

  &__empty-state-list {
    @extend %font-sans-MD;
    color: $text-dark-secondary;
    text-align: left;

    b {
      font-weight: 500;
    }
  }
}
