@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';
@import '../../style/foundation/colors';

.general-drive-families-data-dialog {
  display: grid;
  grid-template-areas:
    'family locking .'
    '. . .'
    'installation installation installation';
  grid-template-rows: auto auto;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding-top: $spacing-MD;

  background: $light-grey;

  &__new-entry {
    display: flex;
    justify-content: flex-end;
    margin-top: $spacing-SM;
  }

  &__config-table-cell {
    background: white !important;
  }

  &__config-table-heading {
    font-weight: bold;
  }

  &__scope-of-application-general-data {
    margin-bottom: $spacing-MD;
  }

  &__config-table-cell:first-child {
    padding-left: 0;
  }

  &__config-table {
    margin-top: $spacing-SM;
  }

  &__family {
    grid-area: family;
  }

  &__locking {
    grid-area: locking;
  }

  &__opening-type-heading {
    margin-bottom: 0;
  }

  &__assembly-type-heading {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__installation {
    grid-area: installation;
  }

  &__family-block {
    grid-row: 1/3;
    grid-column: 1/2;

    // display: grid;
    grid-template-areas:
      '. . . . . . .'
      'drive-family drive-family drive-family drive-family switch switch switch '
      'family-name family-name family-name family-name drive-series drive-series drive-series'
      'technology technology . . . . .'
      'mirrored mirrored mirrored mirrored mirrored mirrored mirrored'
      'left-right left-right left-right left-right left-right left-right left-right'
      'draw-bridge draw-bridge draw-bridge draw-bridge draw-bridge draw-bridge draw-bridge'
      'max-opening-angle max-opening-angle max-opening-angle max-opening-angle max-opening-angle max-opening-angle max-opening-angle';
  }

  &__empty-block {
    grid-row: 1/3;
    grid-column: 3/5;
    grid-template-areas: '. . . .  . . . . ';
  }

  &__locking-block {
    grid-row: 1/2;
    grid-row-gap: $spacing-MD;
    grid-column: 2/3;
    grid-template-areas:
      'title title title title title title title'
      'supports-locking-drive supports-locking-drive supports-locking-drive supports-locking-drive . . .'
      'locking-note locking-note locking-note locking-note locking-note locking-note . ';
  }

  &__console-set-block {
    grid-row: 2/3;
    grid-column: 2/3;
  }

  &__title {
    grid-area: title;
    grid-row: 0/1;
    grid-column: 1/8;
    min-height: 0;
  }

  &__supports-locking-drive {
    grid-area: supports-locking-drive;
  }

  &__locking-note {
    grid-area: locking-note;
  }

  &__drive-family {
    grid-area: drive-family;
  }

  &__drive-family-name {
    grid-area: family-name;
  }

  &__drive-series {
    grid-area: drive-series;
  }

  &__mirrored-symmetrical {
    grid-area: mirrored;
  }

  &__left-right {
    grid-area: left-right;
  }

  &__draw-bridge {
    grid-area: draw-bridge;
  }

  &__technology {
    grid-area: technology;
  }

  &__switch {
    grid-area: switch;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__max-opening-angle-side-inst-bottom-hung {
    grid-area: max-opening-angle;

    // margin-top: auto;
    // margin-bottom: auto;
  }
}
