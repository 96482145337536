@import '../../style/foundation/spacings';
@import '../../style/foundation/colors';
@import '../../style/foundation/typography';

.company-data-view-dialog {
  display: grid;
  grid-template-areas:
    'parent-company parent-company parent-company parent-company parent-company .'
    'name name name shortname shortname .'
    'mycalc bluekit . . . .'
    'debtor-number debtor-number type type . .'
    'street street street house-number . .'
    'zip city city country country country'
    'cpr-facade cpr-facade cpr-facade cpr-roof cpr-roof cpr-roof'
    'cpr-facade-steel cpr-facade-steel cpr-facade-steel . . .'
    'switch switch . . . .'
    'show-drive-performances-in-label-switch show-drive-performances-in-label-switch show-drive-performances-in-label-switch show-drive-performances-in-label-switch . .';

  background-color: #fff;

  &__cpr-roof {
    grid-area: cpr-roof;
  }

  &__cpr-facade {
    grid-area: cpr-facade;
  }

  &__cpr-facade-steel {
    grid-area: cpr-facade-steel;
  }

  &__parent-company {
    grid-area: parent-company;
  }

  &__name {
    grid-area: name;
  }

  &__shortname {
    grid-area: shortname;
  }

  &__street {
    grid-area: street;
  }

  &__house-number {
    grid-area: house-number;
  }

  &__zip {
    grid-area: zip;
  }

  &__city {
    grid-area: city;
  }

  &__country {
    grid-area: country;
  }

  &__mycalc-option {
    grid-area: mycalc;
  }

  &__bluekit-option {
    grid-area: bluekit;
  }

  &__debtor-number {
    grid-area: debtor-number;
  }

  &__type {
    grid-area: type;
  }

  &__switch {
    grid-area: switch;
  }

  &__show-drive-performances-in-label-switch {
    grid-area: show-drive-performances-in-label-switch;
  }

  &__certificate {
    @extend %font-sans-MD;
    box-sizing: border-box;
    margin: $spacing-MD;
    padding: $spacing-MD;

    color: $text-dark-secondary;
    text-align: center;

    border: dashed 1px rgba(#000, 0.3);
    border-radius: 4px;

    &:hover {
      background-color: rgba(#000, 0.04);
      border: solid 1px rgba(#000, 0.04);
    }
  }
}

.dropzone {
  &__icon {
    svg {
      width: 48px;
      height: 48px;
    }
  }

  &__content-title {
    @extend %font-sans-LG;
    max-width: 100%;
    margin-bottom: $spacing-MD;

    text-align: left;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: $spacing-MD * -1.2;
    padding: $spacing-MD;

    text-align: center;

    background-color: white;

    button {
      display: inline-block;
    }
  }
}

.company-data {
  grid-area: data-view;
  max-height: 100%;

  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
