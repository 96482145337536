@import './../style/foundation/spacings';
@import './../style/foundation/typography';
@import './../style/foundation/shadows';
@import './../style/foundation/colors';

.popup-menu {
  &__trigger {
    @extend %font-sans-LG;
    display: flex;
    align-items: center;
    height: 100%;

    color: $text-dark-primary;
  }

  &__empty-state {
    @extend %font-sans-LG;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 210px;
    margin-right: auto;
    margin-left: auto;
    padding: $spacing-MD $spacing-SM;

    color: $text-dark-secondary;
    text-align: center;
  }

  &__empty-state-action {
    @extend %font-sans-LG;
    margin-top: $spacing-MD;
    padding: $spacing-XS $spacing-MD;

    color: $text-dark-secondary;
    text-decoration: none;

    border: 2px solid rgba(#000, 0.15);

    transition-duration: 0.2s;

    &:hover {
      color: $text-dark-primary;

      border: 2px solid rgba(#000, 0.35);
    }
  }

  &__empty-state-icon {
    @extend %icon;
    width: 100%;
    margin-bottom: $spacing-MD;

    color: $text-dark-disabled;
    font-size: 44px;
    text-align: center;
  }

  &__menu-card {
    @extend %shadow-elevation-1;
    position: absolute;
    z-index: 5;

    display: grid;
    grid-column-gap: $spacing-MD;
    grid-template-rows: auto;
    grid-template-columns: auto auto;
    padding: $spacing-SM * 1.65 $spacing-SM * 1.65 0 $spacing-SM * 1.65;
    overflow: hidden;

    background-color: white;
    border-radius: 3px;
    transform: translateX(-15px);
  }

  &__item {
    @extend %font-sans-SM;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 70px;
    min-height: 60px;
    padding-bottom: $spacing-SM;

    line-height: 1.1rem;
    text-align: center;

    cursor: pointer;

    svg {
      max-width: 28px;
      max-height: 40px;
      margin-bottom: 0;
    }
  }

  &__item-label {
    word-break: keep-all;
    hyphens: manual;
  }

  &__item-icon {
    margin-bottom: 4px;
  }
}
