@import './../../../style/foundation/typography';
@import './../../../style/foundation/spacings';
@import './../../../style/foundation/colors';

.tab-bar-without-values {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  margin-bottom: $spacing-SM * 1.5;

  background-color: #f2f2f2;
  border-radius: 5px;

  &__annotation {
    @extend %font-sans-SM;
    padding-top: $spacing-SM;

    color: $text-dark-secondary;
  }

  .mdc-tab__ripple {
    display: none;
    box-sizing: border-box;
  }
}
