@import './../../style/foundation/typography';
@import './../../style/foundation/spacings';
@import './../../style/foundation/colors';

// SPACINGS
$parameter-section-header-inset-top: $spacing-SM * 0.85;
$parameter-section-header-inset-right: $spacing-SM * 1.15;
$parameter-section-header-inset-bottom: 0;
$parameter-section-header-inset-left: $spacing-SM * 1.6;
$parameter-list-item-inset-left: $spacing-SM;
$parameter-list-item-inset-right: $spacing-SM;

// COLORS
$parameter-background: $light-grey;
@keyframes pulsing-dot-animation {
  0% {
    @include respond-to('xl') {
      width: 10px;
      height: 10px;
    }
    width: 8px;
    height: 8px;
  }

  50% {
    @include respond-to('xl') {
      width: 12px;
      height: 12px;
    }
    width: 10px;
    height: 10px;
  }

  100% {
    @include respond-to('xl') {
      width: 10px;
      height: 10px;
    }
    width: 8px;
    height: 8px;
  }
}
@keyframes appearing-dot-container-animation {
  0% {
    width: 0;
  }

  100% {
    width: 13px;
  }
}

.parameter-section {
  display: grid;
  grid-row-start: 1;
  grid-row-end: 3;
  grid-template-rows: auto auto 1fr;

  &:first-child {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
  }

  &:nth-child(2) {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
  }

  &:nth-child(3) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
  }

  &__body {
    height: 100%;

    direction: rtl;

    &:last-child {
      margin-bottom: $spacing-MD;
    }
  }

  &:last-child {
    grid-row-start: 4;
    grid-row-end: 5;
    grid-column-start: 1;

    .parameter-section__body {
      padding-bottom: $spacing-LG;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: $parameter-section-header-inset-top
      $parameter-section-header-inset-right
      $parameter-section-header-inset-bottom
      $parameter-section-header-inset-left;

    background-color: $parameter-background;
  }

  &__title {
    @extend %display-sans-MD;
    color: $black-64;
    line-height: 0;
  }

  &__button {
    @extend %font-sans-MD;
    height: 26px;
    padding: $base-unit * 2 $base-unit * 11 0 $base-unit * 7;

    color: $text-dark-primary;
    font-weight: 500;

    border: none;
    border-radius: 5px;
    cursor: pointer;

    &::before {
      @include respond-to('lg') {
        font-size: 13 * $base-unit;

        transform: translateY(1px);
      }
      @extend %icon;
      display: inline-block;
      margin-right: $spacing-XS;

      color: $black-74;
      font-size: 12 * $base-unit;
      line-height: 0;

      transform: translateY(2px);

      content: '';
    }

    &--edit {
      z-index: 0;

      background-color: $white;

      &::before {
        content: 'edit';
      }
    }

    &--close {
      z-index: 1;

      background-color: $parameter-background;
      border: 1px solid #dcdcdc;
      border-radius: 6px;

      &::before {
        font-size: 16 * $base-unit;

        transform: translateY(3px) translateX(-2px);

        content: 'unfold_less';
      }
    }
  }

  &__list {
    width: 100%;
    height: auto;

    direction: ltr;
  }

  &__list-item-marker-container {
    @include respond-to('xl') {
      width: 15px;
    }
    width: 13px;
    height: 100%;
    margin-top: auto;
    margin-bottom: auto;

    background: none !important;

    transition-timing-function: ease-out;
    animation: appearing-dot-container-animation 0.1s linear;
  }

  &__list-item-marker {
    @include respond-to('xl') {
      width: 10px;
      height: 10px;
    }
    z-index: 0; /* or some other value */

    width: 8px;
    height: 8px;

    background: $yelling-yellow;
    border-radius: 100%;

    transition-timing-function: ease-in-out;
    animation: pulsing-dot-animation 2s infinite;
  }

  &__list-item {
    @include respond-to('lg') {
      padding-top: $spacing-XS * 0.8;
      padding-bottom: $spacing-XS * 0.8;
    }
    @include respond-to('xl') {
      padding-top: $spacing-XS;
      padding-bottom: $spacing-XS;
    }
    @extend %font-sans-MD;
    position: relative;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: $spacing-XS * 1.25;
    margin-right: $spacing-SM;
    margin-bottom: $spacing-XS * 1.25;
    margin-left: $spacing-SM;
    padding-top: $spacing-XS * 0.75;
    padding-bottom: $spacing-XS * 0.75;
    overflow: hidden;

    color: $text-dark-primary;
    line-height: 18px;

    transition-duration: 0.1s;

    &::after {
      @include respond-to('lg') {
        line-height: 26px;
      }
      @include respond-to('xl') {
        line-height: 26px;
      }
      @extend %icon;
      position: absolute;
      top: 0;
      right: $spacing-MD * -1;
      bottom: 0;

      display: inline-block;
      width: 20px;
      margin-right: $spacing-XS;

      color: $text-dark-secondary;
      line-height: 26px;

      transform: scale(0);
      opacity: 0;

      transition-duration: 0.15s;

      content: 'edit';
    }

    &:hover {
      padding-right: $spacing-MD * 1.25;

      background-color: $white;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
      cursor: pointer;

      &::after {
        @extend %icon;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;

        margin-right: $spacing-XS;

        color: $text-dark-secondary;

        transform: scale(1);
        opacity: 1;

        content: 'edit';
      }
    }
  }

  &__list-item-name {
    padding-left: $parameter-list-item-inset-left;

    text-align: left;
  }

  &__list-item-data {
    max-width: 50%;
    margin-left: auto;
    padding-right: $parameter-list-item-inset-right;

    text-align: right;
    word-wrap: break-word;
  }
}

div.parameter-section.parameter-section--edit-mode {
  z-index: 3;

  grid-row-start: 1;
  grid-row-end: 5;
  grid-column-start: 1;
  grid-column-end: 2;
  width: 100%;
  height: 100vh;

  background: $parameter-background;

  .parameter-section__header::before {
    position: relative;

    display: block;
  }

  .parameter-section__body {
    position: relative;

    height: auto;
    overflow-y: auto;
  }
}
