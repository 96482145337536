@import '../../style/foundation/spacings';

.consoles-data-dialog {
  width: 100%;

  background-color: #fff;

  &__console-data {
    display: grid;
    grid-template-areas:
      'art-nr art-nr art-nr . . .'
      'name name name . . .'
      'consoletyp consoletyp consoletyp . . .'
      'switch switch . . . .'
      'preview preview . . . .';
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__art-nr {
    grid-area: art-nr;
    margin-bottom: $spacing-SM;
  }

  &__name {
    grid-area: name;
    margin-bottom: $spacing-SM;
  }

  &__consoletyp {
    grid-area: consoletyp;
    margin-bottom: $spacing-SM;
  }

  &__switch {
    grid-area: switch;
  }

  &__preview {
    grid-area: preview;
  }
}

.facade-console-data {
  grid-area: data-view;
  max-height: 100%;
  overflow: hidden;

  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
