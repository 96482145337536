@import '../style/foundation/breakpoints';
@import '../style/foundation/colors';
@import '../style/foundation/spacings';
@import '../style/foundation/typography';
%admin-button {
  @extend %font-sans-LG;
  display: flex;
  align-items: center;
  padding: $spacing-XS * 1.25 $spacing-MD $spacing-XS $spacing-SM;

  background: $almost-white;
  border: none;

  &::before {
    @extend %icon;
    margin-right: $spacing-SM;

    font-size: 18px;
    line-height: 0px;
  }

  &:hover {
    cursor: pointer;
  }
}

.admin {
  position: relative;

  display: grid;
  grid-template-areas:
    'header header header'
    '. main-title .'
    '. main .';
  grid-template-rows: min-content min-content min-content;
  grid-template-columns: 5% 90% 5%;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;

  background-color: #fafafa;

  &__block {
    display: grid;
    width: auto;
    max-width: none;
    margin: $spacing-XS;
    padding: $spacing-MD;

    background-color: white;
    border-bottom: solid rgba(#000, 0.15) 1px;
  }

  &__block-title {
    @extend %display-sans-MD;
    grid-row: 1;
    grid-column: 1/8;
  }

  & .dialog.full-view-dialog .mdc-dialog__container {
    width: 80vw;
  }

  .header {
    grid-area: header;

    background-color: transparent;
    border-bottom: none;

    &__project-options {
      display: none;
    }
  }

  &__login-form {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    width: 400px;
    height: 700px;
    margin-top: auto;
    margin-right: auto;
    margin-bottom: auto;
    margin-left: auto;

    input {
      margin-bottom: $spacing-MD;
    }

    .form-layout.login-dialog {
      margin-bottom: $spacing-LG;
    }

    button.button.button--primary {
      @extend %font-sans-MD;
      display: inline-block;
      margin-top: $spacing-MD * -1;
      margin-right: $spacing-MD;
      margin-left: $spacing-MD;
      padding-right: 0;
      padding-left: 0;

      text-align: center;
    }
  }

  &__login-button {
    grid-row: 1 / 4;
    grid-column: 2;
    margin: auto;
  }

  .sub-header {
    display: grid;
    grid-area: sub-header;
    grid-template-areas: 'title searchbar new-user-alert download-button button';
    grid-template-columns: auto 250px 1fr auto;
    align-items: center;
    max-width: calc(100vw - 260px);
    margin-bottom: $spacing-SM * 1.5;

    &__title {
      @extend %display-sans-MD;
      display: grid;
      grid-area: title;
      padding-right: $spacing-SM;

      font-weight: 400;
    }

    &__download-button {
      @extend %font-sans-LG;
      @extend %admin-button;
      grid-area: download-button;
      margin-right: $spacing-SM;

      &::before {
        content: 'cloud_download';
      }
    }

    &__new-user-alert {
      @extend %font-sans-LG;
      @extend %admin-button;
      grid-area: new-user-alert;
      width: fit-content;
      margin-left: $spacing-MD;
    }

    &__button {
      @extend %font-sans-LG;
      @extend %admin-button;
      grid-area: button;
      padding: $spacing-XS * 1.25 $spacing-SM $spacing-XS $spacing-SM;

      color: white;

      background-color: $dh-red;

      &--add {
        padding: $spacing-XS * 1.25 $spacing-MD $spacing-XS $spacing-SM;

        &::before {
          content: 'add';
        }
      }
    }
  }

  &__tab-nav {
    display: flex;
    grid-area: tab-nav;
    box-sizing: border-box;
    height: 50px;
    margin-bottom: $spacing-SM * 1.5;

    border-bottom: solid 1px rgba(#000, 0.1);
  }

  &__tab-nav-item {
    @extend %font-sans-MD;
    box-sizing: border-box;
    padding: $spacing-SM $spacing-MD;

    color: $text-dark-primary;
    text-decoration: none;

    border-bottom: solid 3px transparent;

    &--inactive {
      color: $text-dark-disabled;
    }
  }

  &__tab-nav-item--active {
    color: $dh-red;

    border-bottom: solid 3px $dh-red;
  }

  &__side-nav-item--active + div {
    box-shadow: none;
  }

  &__side-nav-item {
    @extend %font-sans-LG;
    position: relative;

    width: 75%;
    margin-top: $spacing-XS;
    margin-bottom: $spacing-XS;
    padding: $spacing-XS $spacing-MD * 1.25;

    color: $text-dark-secondary;
    text-decoration: none;

    cursor: pointer;

    transition-duration: 0.1s;

    &:first-child {
      box-shadow: none;
    }

    &:hover {
      color: $text-dark-primary;
      font-weight: 500;

      background-color: white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);

      + div {
        box-shadow: none;
      }
    }

    &::before {
      position: absolute;
      top: 15%;
      left: -4px;

      width: 4px;
      height: 70%;

      background-color: $dh-red;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      content: '';
    }
  }

  &__sub-nav {
    grid-area: sub-nav;
    width: 100%;
    margin-bottom: $spacing-MD;
    padding-bottom: $spacing-SM * 1.25;

    border-bottom: solid 1px rgba(#000, 0.1);

    .admin__tab-nav-item {
      @extend %font-sans-MD;
      margin-right: $spacing-MD;
      padding: $spacing-XS * 1.35 $spacing-MD;

      color: $text-dark-secondary;
      text-decoration: none;

      border-radius: 4px;
    }

    .admin__tab-nav-item--active {
      color: $text-dark-primary;

      background-color: rgba(#000, 0.08);
      border-bottom: 0;
    }
  }

  &__side-nav-item--active {
    position: relative;

    color: $dh-red;
    font-weight: 500;

    background-color: #fff;
    border-top: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);

    transition-duration: 0.3s;

    &:first-child {
      box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
    }

    &:hover {
      color: $dh-red;
    }

    &::before {
      position: absolute;
      left: 0;

      width: 4px;
      height: 70%;

      background-color: $dh-red;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      transform: translateX(0);

      transition-timing-function: ease-out;
      transition-duration: 0.25s;

      content: '';
    }
  }

  &__main {
    @extend %display-sans-SM;
    display: grid;
    grid-area: main;
    grid-template-areas:
      'tab-nav'
      'sub-nav'
      'content';
    grid-template-rows: minmax(1fr, 90px) minmax(auto, 50px) 1fr;
    grid-template-columns: 1fr;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  &__content {
    display: grid;
    grid-area: content;
    grid-template-areas:
      'sub-header'
      'data-view'
      'pagination';
    grid-template-rows: min-content max-content auto;
    grid-template-columns: 1fr;
    width: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  &__main-title {
    @extend %display-sans-LG;
    display: inline-block;
    grid-area: main-title;

    font-weight: 300;

    cursor: pointer;

    select {
      display: flex;
      padding-right: $spacing-MD;

      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .dialog.mdc-dialog {
    //  display: inline-flex;
    height: 100vh;
  }

  .full-view-dialog .mdc-dialog__surface {
    @include respond-to('xl') {
      height: 80vh;
    }
    min-width: 600px;
    max-width: 1600px;
    height: 92vh;
    max-height: 92vh;
  }

  .sub-dialog .mdc-dialog__surface {
    min-width: 600px;
    max-width: 1600px;
    height: 70vh;
    min-height: calc(760px + 30vh);
  }

  &__assign-select-group {
    display: grid;
    grid-template-areas:
      'title title title'
      'select-group-1 buttons select-group-2';
    grid-template-rows: 30px auto;
    grid-template-columns: auto 50px auto;
    padding: $spacing-SM $spacing-SM $spacing-SM $spacing-MD;

    background-color: #fafafa;

    select {
      @extend %font-sans-LG;
      width: 100%;
      height: 65%;

      border: solid 1px $input-field-border;

      option {
        display: flex;
        align-items: center;
        height: 32px;
        padding-left: $spacing-MD;
      }
    }
  }

  &__assign-select-group-title {
    @extend %font-sans-LG;
    grid-area: title;

    color: $text-dark-primary;
    font-weight: 500;
  }

  &__assign-select {
    width: 100%;
    padding-bottom: $spacing-LG;

    select {
      box-sizing: border-box;
      height: 100%;
    }
  }

  &__assign-select--small {
    select {
      box-sizing: border-box;
      height: 50%;
    }
  }

  &__assign-select-group-buttons {
    @extend %icon;
    display: flex;
    flex-direction: column;
    grid-area: buttons;
    margin-top: $spacing-LG;
    padding: $spacing-XS;

    font-size: 24px;

    button {
      width: 40px;
      height: 40px;
      margin-top: $spacing-SM;

      background: none;
      background-color: white;
      border: solid 1px $input-field-border;
      border-radius: 100%;

      &:hover {
        color: $dh-red;

        cursor: pointer;
      }
    }
  }
}
