@import '../../style/foundation/colors';
@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';

.pagination {
  @extend %font-sans-LG;
  display: flex;
  grid-area: pagination;
  justify-content: center;
  height: 100%;
  padding-top: $spacing-MD;
  padding-bottom: $spacing-MD;
  overflow: hidden;

  &__forward {
    @extend %icon;
    font-size: 18px;

    &::before {
      content: 'arrow_forward';
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__backward {
    @extend %icon;
    font-size: 18px;

    &::after {
      content: 'arrow_back';
    }

    &:hover {
      cursor: pointer;
    }
  }
}
