@import './../../style/foundation/spacings';
@import './../../style/foundation/typography';
@import './../../style/foundation/colors';

.cookie-consent {
  display: block;

  &__bg {
    position: absolute;
    z-index: 2;

    display: inline;
    width: 100%;
    height: 100vh;

    background-color: rgba(#000, 0.25);
  }

  &__message {
    @include respond-to('md') {
      max-width: 900px;
    }
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3001;

    margin-right: auto;
    margin-left: auto;
    padding: $spacing-MD;

    text-align: left;

    background-color: white;
  }

  &__text {
    @extend %font-sans-MD;
    margin-bottom: $spacing-MD * 1.5;

    color: $text-dark-secondary;
  }

  &__headline {
    @extend %display-sans-LG;
    margin-bottom: $spacing-MD;

    color: $text-dark-primary;
  }

  &__button {
    display: block;
    padding: 13px 30px;

    border: none;
  }

  &__link {
    color: $text-dark-secondary;
    text-decoration: underline;

    cursor: pointer;
  }

  &__button--primary {
    @extend %font-sans-MD;
    display: inline-block;
    margin-right: 1rem;

    color: $white;

    background-color: #9ccc65;
    cursor: pointer;
  }

  &__button--secondary {
    @extend %font-sans-MD;
    display: inline-block;
    margin-top: $spacing-SM * 1.5;
    margin-bottom: $spacing-XS;

    color: gray;

    background-color: transparent;
    border: 1px solid gray;
    cursor: pointer;
  }
}
