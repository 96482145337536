@import '../../style/foundation/spacings';

.console-set-data-view {
  width: 100%;

  background-color: #fff;

  &__applications {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__applications-entry {
    display: flex;
    flex-direction: column;
    width: 25%;
  }

  &__applications-entry-switch {
    display: flex;
    flex-direction: row;
    margin-top: $spacing-SM;
    margin-bottom: $spacing-SM;
  }

  &__opening-type-checkbox {
    margin-right: $spacing-XL;
  }

  &__console-set-general-data {
    display: grid;
    grid-template-areas:
      'nav nav nav nav nav nav'
      'art-nr art-nr art-nr . . .'
      'name name name . . .'
      'frame-console frame-console frame-console . . .'
      'sash-console sash-console sash-console . . .'
      'is-active is-active . . . .'
      'is-preview is-preview . . . .'
      'only-assignment only-assignment only-assignment . . .';
  }

  &__console-set-properties-data {
    display: grid;

    //grid-template-areas:
    //  'nav nav nav nav nav nav'
    //  'alu alu wood-alu wood-alu . .'
    //  'wood wood plastic plastic . .'
    //  'steel steel glass glass . .'
    //  'drive-series drive-series drive-series . . .'
    //  'assembly-pos assembly-pos assembly-pos assembly-pos assembly-pos .'
    //  'opening-type opening-type opening-type opening-type . .'
    //  'open-dir open-dir . . . .'
    //  'assembly assembly . . . .'
    //  'rotable-assembly rotable-assembly . . . .'
    //  'assembly-type assembly-type assembly-type . . .'
    //  'gas-spring gas-spring gas-spring  . . .';

    &-series {
      height: 260px;
    }

    &-systems {
      height: 260px;
    }
  }

  &__console-set-measurement-data {
    display: grid;
    grid-template-areas:
      'nav nav nav nav nav nav'
      'space-sash space-sash space-sash . . .'
      'space-frame space-frame space-frame . . .'
      'space-bt-sets space-bt-sets space-bt-sets . . .'
      'lagerschraube lagerschraube lagerschraube . . .'
      'distance-sash-drive distance-sash-drive distance-sash-drive . . .';
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__art-nr {
    grid-area: art-nr;
    margin-bottom: $spacing-SM;
  }

  &__name {
    grid-area: name;
    margin-bottom: $spacing-SM;
  }

  &__frame-console {
    grid-area: frame-console;
    margin-bottom: $spacing-SM;
  }

  &__sash-console {
    grid-area: sash-console;
    margin-bottom: $spacing-SM;
  }

  &__is-active {
    grid-area: is-active;
    margin-bottom: $spacing-SM;
  }

  &__is-preview {
    grid-area: is-preview;
    margin-bottom: $spacing-SM;
  }

  &__only-assignment {
    grid-area: only-assignment;
    margin-bottom: $spacing-SM;
  }

  &__space-sash {
    grid-area: space-sash;
  }

  &__space-frame {
    grid-area: space-frame;
  }

  &__space-bt-sets {
    grid-area: space-bt-sets;
  }

  &__lagerschraube {
    grid-area: lagerschraube;
  }

  &__distance-sash-drive {
    grid-area: distance-sash-drive;
  }
}

.console-set-data {
  grid-area: data-view;
  max-height: 100%;
  overflow: hidden;

  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
