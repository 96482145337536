// placeholder
@import '../style/foundation/typography';
@import '../style/foundation/spacings';
@import '../style/foundation/colors';

.mdc-dialog {
  @extend %font-sans-SM;
  z-index: -1;

  margin-right: auto;
  margin-left: auto;

  color: $text-dark-primary;

  p {
    margin-bottom: $spacing-SM;
  }
}

#download-link {
  display: none;
}

.mdc-dialog--open {
  z-index: 4000;
}

.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(#000, 0.3);

  &--scrim-closed {
    width: 1px;
    height: 1px;
  }
}

.dialog {
  &__header {
    grid-area: header;
    padding: $spacing-MD;

    border: solid 1px rgba(#000, 0.1);
  }

  &__content {
    grid-area: body;
    max-height: 100%;
    padding: $spacing-MD 0;
    overflow-x: hidden;
    overflow-y: auto;

    img {
      margin-top: $spacing-SM;
      margin-bottom: $spacing-SM;
    }
  }

  &__sub-headline {
    @extend %display-sans-LG;
    margin-top: $spacing-MD * 1.5;
    margin-bottom: 0;
    margin-bottom: $spacing-XS;

    color: $text-dark-primary;
    font-weight: 400;
  }

  &__title-byline {
    @include respond-to('xl') {
      margin-bottom: $spacing-SM * -1;
    }
    @extend %font-sans-LG;
    margin-bottom: $spacing-MD * -1;
    padding: 0;

    color: $text-dark-secondary;
    font-weight: 400;
  }

  &__title {
    @extend %display-sans-XL;
    padding-left: 0;

    color: $text-dark-primary;
    font-weight: 400;

    border-bottom: none;
  }

  &--dialog-closed {
    width: 1px;
    height: 1px;
  }

  &--nested {
    .mdc-dialog__surface {
      box-shadow: none;
    }
  }

  &__footer {
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    grid-area: footer;
    align-items: center;
    justify-content: flex-end;
    height: $base-unit * 82;
    padding-right: $spacing-MD;

    background-color: #f5f5f5;
  }

  button.dialog__button,
  a.dialog__button {
    @extend %font-sans-LG;
    display: flex;
    grid-area: button;
    justify-content: center !important;
    box-sizing: content-box;
    min-width: $base-unit * 160;
    margin-bottom: 0 !important;
    margin-left: $spacing-MD;

    color: $text-dark-secondary;
    font-weight: 500;
    text-align: center;
    text-decoration: none;

    background-color: transparent;
    cursor: pointer;

    transition: border-color 0.25s;
  }

  a.dialog__button--primary,
  button.dialog__button--primary {
    color: white;

    background-color: $primary;

    &:hover {
      color: white;

      background-color: $primary;
      border-color: rgba(#000, 0);
    }
  }

  &__button--secondary {
    justify-content: center;

    background-color: white;
  }

  &__button--disabled {
    color: $text-dark-disabled;

    background-color: rgba(#000, 0.2) !important;
    border-color: rgba(#000, 0);

    &:hover {
      color: $text-dark-disabled;

      background-color: rgba(#000, 0.2) !important;
      border-color: rgba(#000, 0);
      cursor: default;
    }
  }

  // #downloadLink {
  //  display: none;
  // }
}

.dialog .mdc-dialog__container {
  @extend %font-sans-MD;
  position: relative;

  grid-row: 2;
  grid-column: 2;
  width: 840px;
  margin-top: 2vh;
  margin-right: auto;
  margin-bottom: 10vh;
  margin-left: auto;
  overflow: hidden;

  color: $text-dark-primary;
}

.mdc-dialog .mdc-dialog__surface {
  display: grid;
  grid-template-areas:
    'header'
    'body'
    'footer';
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  width: 64%;
  min-width: 790px;
  height: 54%;
  min-height: 600px;
  overflow: hidden;
}

.admin .mdc-dialog .mdc-dialog__surface {
  width: 100%;
}

.mdc-button.mdc-dialog__button {
  @extend %icon;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;

  font-size: 24px;

  background-color: white;
  border: none;
  outline: none;
  cursor: pointer;
}
