@import './../style/foundation/spacings';
@import './../style/foundation/typography';
@import './../style/foundation/colors';
@import '@material/radio/mdc-radio';

$button-red: $primary;

.radio-button {
  display: flex;
  align-items: center;

  label {
    line-height: 0px;
  }

  .mdc-radio {
    @include mdc-radio-checked-stroke-color($button-red);
    @include mdc-radio-ink-color($button-red);

    &__background {
      background-color: white !important;
      border-color: $text-dark-disabled !important;
    }

    &--selected {
      .mdc-radio__background {
        background-color: $primary !important;
        border-color: $primary !important;
      }
    }

    &__background path {
      stroke-width: 3 !important;
    }

    ::after,
    ::before {
      background-color: rgba($dh-red, 0.64) !important;
    }

    //&:indeterminate {
    //  ::after,
    //  ::before {
    //    background-color: rgb(blue 0.64) !important;
    //  }
    //}
  }
}
