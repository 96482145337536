@import './../style/foundation/typography';
@import './../style/foundation/spacings';
@import './../style/foundation/colors';

.mdc-menu {
  right: 12px;

  min-width: 180px;
  max-height: 274px;

  transform: translateX(-190px);
  transform-origin: right top 0;
}

.mdc-menu-surface--open {
  z-index: 200 !important;
}

ul.mdc-deprecated-list {
  z-index: 2000;

  min-width: 100%;
  margin-right: 0;
  margin-left: 0;

  .mdc-deprecated-item {
    margin-right: 0;
    margin-left: 0;
    padding-left: $spacing-MD;

    border-radius: 0;
  }

  .mdc-deprecated-list-item__text {
    @extend %font-sans-LG;
  }
}

.options-menu {
  &__icon {
    @extend %icon;
    @extend %font-sans-LG;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    color: $text-dark-disabled;

    border-radius: 100%;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: black, $alpha: 5%);
    }

    &--active {
      background-color: rgba($color: black, $alpha: 5%);
    }
  }
}
