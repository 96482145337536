@import '../../style/foundation/spacings';

.console-set-assignment-data-dialog {
  width: 100%;

  background-color: #fff;

  &__console-set-assignment-data {
    display: grid;
    grid-template-areas:
      //'nav nav nav nav nav nav'

      'system system system . . .'
      'series series series . . .'
      'sash-profile sash-profile sash-profile . . .'
      'frame-profile frame-profile frame-profile . . .'
      'console-set console-set console-set console-set console-set console-set'
      'chain chain chain . . .'
      'switch switch . . . .'
      'preview preview . . . .';
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__system {
    grid-area: system;
    margin-bottom: $spacing-SM;
  }

  &__series {
    grid-area: series;
    margin-bottom: $spacing-SM;
  }

  &__sash-profile {
    grid-area: sash-profile;
    margin-bottom: $spacing-SM;
  }

  &__frame-profile {
    grid-area: frame-profile;
  }

  &__console-set {
    grid-area: console-set;

    & .select-search-option {
      height: auto;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  &__chain {
    grid-area: chain;
  }

  &__switch {
    grid-area: switch;
  }

  &__preview {
    grid-area: switch;
  }
}
