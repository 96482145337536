@import '../../../style/foundation/typography';
@import '../../../style/foundation/spacings';
@import '../../../style/foundation/colors';

.console-item {
  position: relative;

  margin-bottom: $spacing-SM;
  padding: $spacing-SM * 1.5 $spacing-SM * 0.55 $spacing-SM * 1.5 $spacing-SM *
    1.5;

  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 25%), 0 2px 10px 0 rgb(0 0 0 / 2.5%);
  cursor: pointer;

  transition-duration: 0.3s;

  &__options-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__label-serie {
    margin-left: $spacing-XS;

    color: $primary;
  }

  &__name {
    @extend %display-sans-LG;
    display: flex;
    width: 100%;

    color: $text-dark-secondary;
  }

  &--selected,
  &:active {
    background-color: $item-selected;
    border-color: darken($item-selected, 6%);

    .recommended-item__label::after {
      color: $text-dark-secondary;
    }
  }

  &--confirmed {
    position: relative;

    color: $text-dark-primary;

    border-left: red solid 4px;

    &:hover:hover {
      border-left: red solid 4px;
    }
  }

  &:hover {
    border-color: darken($item-hover, 6%);

    .recommended-item__name {
      color: $text-dark-primary;
    }
  }

  &--empty {
    @extend %font-sans-MD;
    position: relative;

    color: $text-dark-disabled;

    background-color: $light-grey;
    box-shadow: none;
    box-shadow: 0 0 4px 0 $light-grey;

    .console-item__name {
      opacity: 0;
    }
  }

  &__empty-state-message {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    text-align: center;
  }
}
@keyframes load {
  from {
    left: -150px;
  }

  to {
    left: 100%;
  }
}
