@import '../../style/foundation/spacings';

.frame-profile-data-dialog {
  width: 100%;

  background-color: #fff;

  &__layout-basic {
    display: grid;
    grid-template-areas:
      'nav nav nav nav nav nav'
      'article-nr article-nr . . . .'
      'info info info info  . .' 'serie serie . . . .'
      'switch switch switch switch switch .'
      'preview preview preview preview preview .';
  }

  &__layout-basic-information {
    display: grid;
    grid-template-areas:
      'measurement-inside measurement-inside measurement-outside measurement-outside . .'
      'building-depth building-depth max-sash-weight max-sash-weight . .'
      'max-sash-addition max-sash-addition . . . .';
  }

  &__layout-properties {
    display: grid;
    grid-template-areas:
      'tip-tronic tip-tronic . . . .'
      'integrated integrated integrated-measurement integrated-measurement . .'
      'basic-profile basic-profile exchange-profile exchange-profile . .'
      'opening-direction opening-direction . . . .'
      'mountage mountage mountage . . .'
      'application application application application  . . '
      'profile-type profile-type profile-type profile-type profile-type .'
      'installation installation installation . . . ';
  }

  .admin__tab-nav {
    grid-area: nav;
  }

  &__installation {
    grid-area: installation;
  }

  &__opening-direction {
    grid-area: opening-direction;
    margin-bottom: $spacing-SM;
  }

  &__opening-direction--disabled {
    pointer-events: none;
  }

  &__mountage {
    grid-area: mountage;
    margin-bottom: $spacing-SM;
  }

  &__application {
    grid-area: application;
    margin-bottom: $spacing-SM;
  }

  &__tip-tronic {
    grid-area: tip-tronic;
  }

  &__integrated {
    grid-area: integrated;
    margin-top: $spacing-LG;
    margin-bottom: $spacing-SM * 1.5;
  }

  &__profile-type {
    grid-area: profile-type;
  }

  &__integrated-measurement {
    grid-area: integrated-measurement;
    margin-bottom: $spacing-SM * 1.5;
  }

  &__basic-profile {
    grid-area: basic-profile;
    margin-bottom: $spacing-MD;
  }

  &__exchange-profile {
    grid-area: exchange-profile;
    margin-bottom: $spacing-MD;
  }

  &__building-depth {
    grid-area: building-depth;
  }

  &__max-sash-addition {
    grid-area: max-sash-addition;
  }

  &__max-sash-weight {
    grid-area: max-sash-weight;
  }

  &__measurement-inside {
    grid-area: measurement-inside;
  }

  &__measurement-outside {
    grid-area: measurement-outside;
  }

  &__article-nr {
    grid-area: article-nr;
  }

  &__info {
    grid-area: info;
  }

  &__serie {
    grid-area: serie;
  }

  &__switch {
    grid-area: switch;
  }

  &__preview {
    grid-area: preview;
  }
}

.frame-profile-data-view {
  grid-area: data-view;
  max-height: 100%;
  overflow: hidden;

  th {
    width: auto;
    max-width: 50px;
    overflow: hidden;
  }
}
