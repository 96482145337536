@import './../style/foundation/spacings';
@import './../style/foundation/typography';
@import './../style/foundation/colors';
@import './../style/foundation/shadows';

.tile {
  @extend %shadow-elevation-1;
  position: relative;

  padding: $spacing-SM * 1 $spacing-SM * 1.5 $spacing-SM * 1 $spacing-SM * 1.5;
  overflow: hidden;

  background-color: white;
  border-radius: 3px;
}
