@import './../../../style/foundation/typography';
@import './../../../style/foundation/spacings';
@import './../../../style/foundation/colors';

.system-series-box {
  position: relative;

  &__button {
    position: relative;
    right: 0;
    left: 0;

    display: block;
    width: 98%;
    height: 42px;
    margin-right: auto;
    margin-bottom: $spacing-MD;
    margin-left: auto;
    margin-left: 3px;
    padding-left: $spacing-SM * 1.5;

    color: $text-dark-secondary;

    background-color: $super-light-grey;
    border: none;
    border-radius: 3px;
    box-shadow: 0 0 3px 1px $super-light-grey;
    cursor: pointer;

    transition-duration: 0.25s;

    &::before {
      @extend %icon;
      position: absolute;
      top: $spacing-SM;
      left: $spacing-SM * 1.5;

      font-size: 24px;

      transition-duration: 0.25s;

      content: 'playlist_add';
    }

    &:hover {
      color: $text-dark-primary;

      background-color: rgba($super-light-grey, 0.8);
      box-shadow: 0 0 3px 1px rgba($super-light-grey, 0.8);

      &::before {
        color: $dh-red;
      }
    }
  }

  &__values {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto auto;
    box-sizing: border-box;
    width: 100%;
    padding-top: $spacing-SM;

    border: solid 1px rgba(#000, 0.15);
    border-radius: 3px;

    transition-duration: 0.3s;
  }

  dt {
    margin-bottom: $spacing-SM;
    padding-left: $spacing-MD;
  }

  dd {
    margin-bottom: $spacing-SM;
    padding-right: $spacing-MD;

    text-align: right;
  }

  &__values-button {
    @extend %font-sans-LG;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    font-weight: 500;

    background-color: white;
    border: none;
    opacity: 0;

    transition-duration: 0.3s;

    &::before {
      @extend %icon;
      align-self: center;
      margin-right: $spacing-SM;
      margin-bottom: $spacing-XS;

      font-size: 18px;

      content: 'edit';
    }

    &:hover {
      border: none;
      border: solid 1px rgba(#000, 0.1);
      border-radius: 3px;
      cursor: pointer;
      opacity: 0.87;
    }
  }
}
