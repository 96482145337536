@use 'sass:math';

$base-unit: math.div(1rem, 16);
$spacing-XXS: $base-unit * 2;
$spacing-XS: $base-unit * 4;
$spacing-SM: $base-unit * 8;
$spacing-MD: $base-unit * 16;
$spacing-LG: $base-unit * 32;
$spacing-XL: $base-unit * 64;
$spacing-XXL: $base-unit * 128;
$spacing-XXXL: $base-unit * 256;
