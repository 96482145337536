@import './../../../style/foundation/spacings';
@import './../../../style/foundation/typography';
@import './../../../style/foundation/colors';
@import './../../../style/foundation/shadows';
@import './../../../elements/Button';
@import '../../../elements/byline';
@import '../../../elements/Marker';

$drive-details-background: $dark-blue;

.drive-details-column {
  @include respond-to('lg') {
    min-width: 580px;
  }
  z-index: 301;

  grid-area: drive-details;
  width: 100%;
  min-width: 720px;
  overflow-x: hidden;
  overflow-y: auto;

  background-color: $drive-details-background;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &__download-button {
    grid-area: button;
  }

  &__content {
    @include respond-to('lg') {
      grid-template-rows: auto auto 1fr auto;

      // grid-template-columns: auto 40% 1fr;
      grid-template-columns: auto minmax(400px, 40%) 1fr;
      padding: 1.5vh;

      column-gap: $base-unit * 25;
    }
    @include respond-to('xl') {
      grid-template-rows: auto auto 1fr auto;
      grid-template-columns: auto minmax(400px, 48%) 1fr;
      padding-top: 4vh;
      padding-left: 4vh;

      column-gap: 5%;
    }
    display: grid;
    flex: 1 1 auto;
    grid-area: drive-details;
    grid-template-areas:
      'card description description'
      'auto description description'
      'auto description description'
      'footer footer footer';
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: auto minmax(300px, 40%) 1fr;
    height: 92%;
    padding: $spacing-SM * 1 $spacing-SM * 1;

    column-gap: $base-unit * 20;
  }

  &__title {
    @extend %display-sans-LG;
    margin-bottom: $spacing-LG;
    padding: $spacing-SM * 1.4;
  }

  &__description {
    display: grid;
    grid-area: description;
    grid-template-areas:
      'heading'
      'description'
      'locking-drive-information'
      'message'
      'installationDiagram'
      'button'
      'errors'
      'remainingSpace';
    grid-template-rows: 0fr 0fr 0fr 0fr 0fr 0fr auto;
    max-width: 700px;
  }

  &__errors-more-items {
    @extend %shadow-elevation-1;
    position: absolute;
    top: -6px;
    left: 6px;

    width: 100%;
    height: 100%;

    background-color: transparentize($color: #e2dabd, $amount: 0.3);
    border-radius: 4px;
    outline: solid rgba(#a4853f, 0.02);
  }

  &__number-remaining-errors {
    @extend %font-sans-SM;
    @extend %shadow-elevation-1;
    position: absolute;
    right: -4%;
    bottom: -8px;
    z-index: 1;

    padding: 0 8 * $base-unit;

    color: #b58107;
    font-weight: 500;
    line-height: 22px;

    background-color: $yelling-yellow;
    border-radius: 30px;
    transform: translateY(-60%) translateX(0);
  }

  &__errors {
    position: relative;

    display: block;
    grid-area: errors;
    max-width: 330px;

    font-weight: 400;

    .drive-details-column__errors-more-items {
      display: none;
    }

    .drive-details-column__number-remaining-errors {
      display: none;
    }
  }

  &__description-heading {
    grid-area: heading;
  }

  &__title-byline {
    @include respond-to('xl') {
      margin-top: $spacing-SM * -0.8;
    }
  }

  &__title-byline-property {
    margin-right: $spacing-SM * 1.5;
  }

  &__description-small-headline {
    @extend %byline;
  }

  &__description-errors-icon svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 5px;
    margin-left: 3px;
    padding-top: 6px;

    opacity: 0.64;
  }

  &__description-errors-headline {
    @include respond-to('xl') {
      max-width: 330px;
    }
    @extend %font-sans-LG;
    @extend %byline;
    flex-direction: row;
    align-items: center;
    margin-bottom: $spacing-XS;
  }

  &__description-name {
    @extend %display-sans-XXL;
    margin-bottom: $spacing-SM * 1.25;

    white-space: nowrap;
  }

  &__description-text {
    @extend %font-sans-LG;
    grid-area: description;
    margin-bottom: $spacing-MD * 1.5;

    color: $text-dark-secondary;

    ul {
      margin-top: 0;
      margin-bottom: 0;
      padding-left: 24px;
    }

    a {
      color: $text-dark-primary;
    }
  }

  &__description-errors-head-area {
    position: relative;

    max-width: 330px;
    margin-bottom: $spacing-SM;
  }

  &__description-errors {
    grid-area: errors;

    &--expanded {
      z-index: 1;

      grid-area: errors / remainingSpace;
      padding-top: $spacing-SM;

      background-color: $drive-details-background;

      .drive-details-column__errors {
        cursor: default;
      }
    }

    &-close-button {
      @extend %font-sans-MD;
      position: absolute;
      top: 0;
      right: 0;

      display: flex;
      flex-direction: row;
      align-items: center;
      padding: $spacing-XS $spacing-SM $spacing-XS $spacing-MD;

      color: $text-dark-secondary;

      background-color: rgba(#000, 0.1);
      border: none;
      border-radius: 40px;
      outline: none;
      cursor: pointer;

      &::after {
        @extend %icon;
        right: 0;

        display: flex;
        align-items: center;
        height: 100%;
        padding-left: $spacing-XS;

        font-size: 20px;

        content: 'unfold_less';
      }
    }
  }

  &__description-button {
    @extend %button;
    @extend %button--large;
    @extend %button--secondary;
    grid-area: button;
    max-width: 330px;

    font-weight: 500;

    &--disabled {
      color: rgba($text-dark-disabled, 0.15);

      background-color: rgba(#000, 0.025);
      outline: none;
      cursor: not-allowed;

      .button__icon {
        opacity: 0.25;
      }
    }
  }

  &__description-button-icon {
    @extend %icon;
    margin-left: auto;

    color: $text-dark-disabled;
    font-size: 24px;
  }

  &__button-icon {
    @extend %icon;
    margin-left: auto;

    color: $text-dark-disabled;
    font-size: 24px;
  }

  &__drive-values-card {
    @include respond-to('lg') {
      min-width: 265px;
      max-width: 265px;
      padding: $spacing-XS * 0.3 $spacing-XS * 0.2 0 $spacing-XS * 1.4;
    }
    @include respond-to('xl') {
      min-width: 380px;
      max-width: 380px;
      padding: $spacing-XS * 0.3 $spacing-XS * 0.2 0 $spacing-XS * 1.4;
    }
    grid-area: card;
    grid-template-areas:
      'title title'
      'line data';
    grid-template-rows: auto auto;
    grid-template-columns: 1px auto;
    min-width: 245px;
    max-width: 245px;
    height: auto;
    padding: $spacing-XS $spacing-XS 0 $spacing-SM * 0.8;

    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 20px rgb(0 0 0 / 15%), 0 0 3px rgb(0 0 0 / 15%);
  }

  &__drive-values-card-graph {
    @include respond-to('lg') {
      margin-bottom: $spacing-XS * 1.5;
    }
    margin-bottom: $spacing-SM;
  }

  &__drive-values-card-text {
    @include respond-to('lg') {
      padding: $spacing-XS $spacing-MD $spacing-XS * 0.2 $spacing-SM * 1.5;
    }
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    padding: $spacing-XS $spacing-MD $spacing-SM * 0.5 $spacing-SM * 1.5;
  }

  &__drive-values-headline {
    @include respond-to('lg') {
      margin-bottom: $spacing-SM;
    }
    @extend %font-sans-SM;
    display: inline-block;
    margin-right: $spacing-SM * 1.5;
    margin-bottom: $spacing-SM;
    margin-left: $base-unit * -4;

    color: $text-dark-secondary;
    font-weight: 500;

    svg {
      @include respond-to('lg') {
        width: 15px;
        margin-left: -2.5px;
      }
      width: 15px;
      height: auto;
      margin-right: $spacing-SM;
      margin-left: -3px;
      stroke-linejoin: bevel;
    }

    #icon-force2 {
      @include respond-to('lg') {
        width: 19px;
        margin-left: -3px;
        stroke-width: 1.5px;
      }
      @include respond-to('xl') {
        width: 26px;
        margin-left: -5px;

        transform: translateY(-1.5px) translateX(-1.5px) scale(1.25);
        stroke-width: 2px;
      }
      stroke: $active-blue;
    }

    #icon-force1 {
      @include respond-to('lg') {
        width: 18px;
        margin-left: -4px;
        stroke-width: 1.3px;
      }
      @include respond-to('xl') {
        width: 25px;
        margin-left: -5px;
        stroke-width: 1.25px;
      }
      fill: $active-blue;
    }

    #icon-force-line {
      @include respond-to('lg') {
        width: 9px;
        height: 3.5px;
      }
      @include respond-to('xl') {
        width: 10px;
        height: 4px;

        transform: translateY(-2px);
      }
      fill: $active-blue;
    }
  }

  &__drive-values-headline--drive {
    display: flex;
    align-items: center;

    svg {
      @include respond-to('lg') {
        width: 15px;
        margin-left: -4px;
      }
      @include respond-to('xl') {
        width: 20px;
        margin-left: -5px;
      }
      width: 15px;
      height: auto;
      margin-right: $spacing-SM;
      margin-left: -3px;
      stroke-linejoin: bevel;
    }

    #icon-drive {
      @include respond-to('lg') {
        stroke-width: 1.75px;
      }
      @include respond-to('xl') {
        stroke-width: 2px;
      }
      stroke: $dh-red;
    }
  }

  &__drive-graph-legend {
    display: grid;

    column-count: 2;
  }

  &__drive-values-headline-icon {
    margin-right: $spacing-XS * 1.25;
    padding-top: $base-unit * 1;
  }

  &__drive-values-border {
    position: relative;

    display: flex;
    grid-area: border;
    align-items: center;
    justify-content: center;

    &::after {
      @include respond-to('xl') {
        transform: translateY(-8px);
      }
      width: 2px;
      height: 94%;

      background-color: #e6e6e6;
      transform: translateY(-6px);

      content: '';
    }
  }

  &__drive-values-group {
    position: relative;

    margin-top: 0;
  }

  &__drive-values-group-performance-values {
    @include respond-to('xl') {
      grid-column-gap: 22px;
      grid-template-columns: 10px auto;
      margin-bottom: $spacing-MD;
    }
    display: grid;
    grid-column-gap: 14px;
    grid-template-areas:
      'headline headline'
      'border drive-value-1'
      'border drive-value-2'
      'border drive-value-3'
      'border drive-value-4'
      'border drive-value-5';
    grid-template-columns: 3px auto;
    margin-bottom: $spacing-XS * 1.5;
  }

  &__headline {
    display: grid;
    grid-area: headline;
    grid-template-areas: 'headline-icon headline-data headline-required-data';
    grid-template-columns: 10% 75% 15%;
    width: 100%;
  }

  &__headline-icon {
    display: flex;
    grid-area: headline-icon;
    align-items: center;
    justify-content: center;
  }

  &__drive-value-1 {
    grid-area: drive-value-1;
  }

  &__drive-value-2 {
    grid-area: drive-value-2;
  }

  &__drive-value-3 {
    grid-area: drive-value-3;
  }

  &__drive-value-4 {
    grid-area: drive-value-4;
  }

  &__drive-value-5 {
    grid-area: drive-value-5;
  }

  &__drive-values-group--drive-data {
    margin-bottom: $spacing-MD;
  }

  &__errors--many {
    @include respond-to('xl') {
      max-width: 330px;
    }
    cursor: pointer;

    .drive-details-column__errors-more-items {
      display: inline-block;
    }

    .drive-details-column__number-remaining-errors {
      display: inline-block;
    }
  }

  &__errors-items {
    @extend %font-sans-MD;
    @extend %shadow-elevation-1;
    position: relative;

    grid-template-areas:
      'error-message'
      'confirmation';
    margin-bottom: $spacing-MD;
    padding: $spacing-SM;

    color: #906d1a;
    font-weight: 500;

    background-color: #f6eecc;
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__errors-items--confirmed {
    color: darken($color: $dark-green, $amount: 17%);

    background-color: #f4fede;
  }

  &__error-message {
    display: grid;
    grid-area: error-message;

    line-height: 24px;
  }

  &__error-confirmation {
    @extend %font-sans-MD;
    display: grid;
    grid-area: confirmation;
    grid-column-gap: 10px;
    grid-template-columns: 30px 1fr;
    align-items: center;
    margin: -$spacing-SM;
    margin-top: $spacing-SM;
    padding: $spacing-XS $spacing-SM;

    font-weight: 500;
    text-transform: uppercase;

    background-color: #ede7d3;
    border-radius: 0 0 4px 4px;

    transition-duration: 300ms;

    &:hover {
      background-color: #e6e0cc;
    }
  }

  &__error-confirmation--confirmed {
    background-color: #e1f0d5;

    transition-duration: 300ms;

    &:hover {
      background-color: #d8e6cc;
    }
  }

  &__error-confirmation-dialog-content {
    padding: $spacing-SM * 1.5 $spacing-LG;
  }

  &__error-confirmation-dialog-content-top {
    display: grid;
    margin-bottom: $spacing-LG;
  }

  &__error-confirmation-dialog-content-bottom {
    @extend %font-sans-SM;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 30px 1fr;
    align-items: flex-start;
    padding-top: $spacing-MD;

    color: rgb(0 0 0 / 44%);
    line-height: 18px;

    border-top: solid 1px rgba(#000, 0.1);
  }

  &__error-confirmation-dialog-content-bottom svg {
    width: 100%;
    height: auto;

    transform: translateY(-4px);
  }

  &__error-confirmation-switch {
    & svg {
      width: 100%;
      height: auto;

      transform: translateY(2px);
      cursor: pointer;
    }

    &--disabled svg {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__error-confirmation-text {
    display: grid;

    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  &__drive-values {
    @include respond-to('xl') {
      margin-right: $spacing-SM * 1.5;
    }
    position: relative;

    display: grid;
    grid-template-areas:
      'name .'
      'data calculated-data';
    margin-right: $spacing-SM * 1.5;
  }

  &__drive-values-name {
    @extend %font-sans-SM;
    grid-area: name;

    color: $text-dark-secondary;
    line-height: 20px;
    text-align: left;
  }

  &__drive-values-hint {
    @extend %font-sans-MD;
    grid-area: name;
    margin-right: $spacing-SM;

    color: $text-dark-secondary;
    font-style: italic;
  }

  &__drive-values-data {
    @include respond-to('xl') {
      margin-bottom: $spacing-SM;
    }
    @extend %font-sans-LG;
    position: relative;

    grid-area: data;
    margin-bottom: $spacing-XS;
    margin-left: 0;

    color: $text-dark-primary;
    font-weight: 500;
    line-height: 20px;
    text-align: left;

    cursor: default;
  }

  &__drive-value-data--with-correction-factor {
    @extend %correction-factor-marker;
  }

  &__drive-values-data-loading {
    display: inline-block;
    height: 12px;
    margin-right: auto;
    overflow: hidden;

    border-radius: 4px;

    .loading-skeleton {
      max-width: 74px;
    }
  }

  &__drive-values-data-calculation {
    grid-area: calculated-data;
    width: 100%;
    margin-top: $spacing-XS * 0.5;
    margin-bottom: $spacing-XS * 1.5;
    margin-left: auto;

    font-weight: 300;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    line-height: 16px;
    letter-spacing: 0.08px;
    text-align: right;
  }

  &__drive-message {
    display: block;
    grid-area: message;
    margin-bottom: $spacing-MD;

    line-height: 20px;
    hyphens: auto;
  }

  &__drive-message-label {
    @extend %font-sans-SM;
    display: inline;
    margin-right: $spacing-XS * 0.8;
    padding: $spacing-XS * 0.45 $spacing-XS * 0.9 $spacing-XS * 0.7;

    color: $drive-details-background;
    letter-spacing: 0.54px;

    background-color: rgba(#000, 0.54);
    border-radius: 3px;
  }

  &__drive-message-text {
    @extend %font-sans-SM;
    display: inline;

    color: $text-dark-primary;
  }

  .value {
    @include respond-to('xl') {
      margin-top: -4px;
      margin-right: -10px;
    }
    @extend %font-sans-LG;
    display: inline-block;
    margin-top: -4px;
    margin-right: -10px;
    padding: 2px 6px;

    font-weight: 450;
    line-height: 16px;

    border-radius: 20px;
  }

  .value--valid {
    color: darken($color: $dark-green, $amount: 17%);

    background-color: transparentize($color: $light-green, $amount: 0.79);
  }

  .value--invalid {
    color: #906d1a;

    background-color: #f9ecbf;
  }

  .value--exact {
    @extend %icon;
    color: darken($color: $dark-green, $amount: 10%);

    background-color: transparentize($color: $light-green, $amount: 0.79);
  }

  .locking-drive-information {
    display: grid;
    grid-area: locking-drive-information;
    grid-template-rows: auto auto;
    margin-bottom: $spacing-MD;

    &__heading {
      @extend %byline;
    }

    &__value {
      @extend %display-sans-XXL;
    }
  }
}
