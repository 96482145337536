@import './../style/foundation/spacings';
@import './../style/foundation/typography';
@import './../style/foundation/colors';

.check-box {
  .mdc-checkbox {
    &__background {
      background-color: white !important;
      border-color: $text-dark-disabled !important;
    }

    &--selected {
      .mdc-checkbox__background {
        background-color: $primary !important;
        border-color: $primary !important;
      }
    }

    &__background path {
      stroke-width: 3 !important;
    }

    ::after,
    ::before {
      background-color: rgba($dh-red, 0.64) !important;
    }
  }
}
