@import '../../style/foundation/breakpoints';
@import '../../style/foundation/colors';
@import '../../style/foundation/spacings';
@import '../../style/foundation/typography';

.assign-component {
  position: relative;

  &__assign-select-group {
    display: grid;
    grid-template-areas:
      'title title title'
      'select-group-1 buttons select-group-2';
    grid-template-rows: 30px 1fr;
    grid-template-columns: auto 50px auto;
    height: 100%;
    max-height: 250px;
    padding: 0;

    background-color: #fafafa;

    &--white {
      background-color: white;
    }

    select {
      @extend %font-sans-LG;
      width: 100%;
      overflow: auto;

      border: solid 1px $input-field-border;

      option {
        display: flex;
        align-items: center;
        height: 32px;
        padding-left: $spacing-MD;
      }
    }
  }

  &__option-highlight-path {
    background-color: $dark-grey;
  }

  &__assign-select-group-title {
    @extend %font-sans-LG;
    grid-area: title;

    color: $text-dark-primary;
    font-weight: 500;
  }

  &__assign-select {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__assign-select--small {
    select {
      box-sizing: border-box;
      height: 100%;
    }
  }

  &__assign-select-group-buttons {
    @extend %icon;
    display: flex;
    flex-direction: column;
    grid-area: buttons;
    margin-top: $spacing-LG;
    padding: $spacing-XS;

    font-size: 24px;

    button {
      width: 40px;
      height: 40px;
      margin-top: $spacing-SM;

      background: none;
      background-color: white;
      border: solid 1px $input-field-border;
      border-radius: 100%;

      &:hover {
        color: $dh-red;

        cursor: pointer;
      }
    }
  }
}
