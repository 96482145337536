@import '../style/foundation/breakpoints';
@import '../style/foundation/colors';
@import '../style/foundation/spacings';
@import '../style/foundation/typography';

.search-field {
  position: relative;

  display: flex;
  flex-direction: row;
  width: 600px;

  &::before {
    @extend %display-sans-MD;
    @extend %icon;
    position: absolute;
    top: 14px;
    left: 10px;

    color: $text-dark-disabled;
    font-size: 25px;

    content: 'search';
  }

  &__search-bar {
    @extend %font-sans-LG;
    box-sizing: border-box;
    width: 100%;
    height: 54px;
    padding-left: $spacing-LG * 1.3;

    color: $text-dark-primary;

    border: none;
    border-radius: 5px;
    outline: none;
    box-shadow: 0 0 6px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%);

    &:focus {
      box-shadow: 0 0 6px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%);

      transition-timing-function: ease-in;
      transition-duration: 0.15s;
    }

    &::placeholder {
      color: $text-dark-disabled;
    }
  }

  &--small {
    .search-field__search-bar {
      display: grid;
      height: 44px;

      box-shadow: none;

      &:focus {
        box-shadow: 0 0 6px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 10%);

        transition-duration: 0s;
      }
    }

    &::before {
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
