@import '../../../style/foundation/colors';
@import '../../../style/foundation/spacings';
@import '@material/radio/mdc-radio';
@import './../../../style/foundation/typography';
@keyframes pulsing-dot-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

$button-red: $primary;

.radio-box {
  width: 100%;
  padding-left: $spacing-XS;

  transform: translateX(-$spacing-SM);

  .mdc-radio {
    @include mdc-radio-checked-stroke-color($button-red);
    @include mdc-radio-ink-color($button-red);

    ::after,
    ::before {
      background-color: rgba($button-red, 0.64) !important;
    }
  }

  label {
    cursor: pointer;
  }
}

.radio-box.mdc-form-field {
  cursor: pointer;
}

.validation-label {
  margin-bottom: 5px;
}
