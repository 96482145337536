@import '../style/foundation/colors';
@import '../style/foundation/typography';
@import '../elements/Button';

$item-hover: rgb(255 255 255 / 65%);
$item-height: 60px;

.manager-page {
  @include respond-to('xl') {
    margin-top: $spacing-XXL;
  }
  @extend %font-sans-MD;
  display: grid;
  grid-area: mid;
  grid-template-areas:
    'headline button'
    'tab-nav tab-nav'
    'search search'
    'table table';
  grid-template-rows: 40px auto auto 1fr;
  grid-template-columns: 1fr auto;
  width: 100%;
  height: auto;
  margin-top: $spacing-XL;

  .tab-nav-section {
    grid-area: tab-nav;
  }

  .empty-state-container {
    grid-area: table;
    max-height: 250px;
  }

  &__search {
    grid-area: search;
    max-width: 480px;
    margin-bottom: $spacing-MD;
  }

  .button {
    grid-area: button;
  }

  &__list-item-cell--number-area {
    @extend %font-sans-MD;
    z-index: 200;

    height: 100%;

    color: $text-dark-secondary;
    font-family: 'Roboto Mono', monospace;
    letter-spacing: 0.75px;
    white-space: nowrap;

    cursor: text;
  }

  &__headline {
    grid-area: headline;
  }

  &__tab-nav {
    display: flex;
    grid-area: tab-nav;
    box-sizing: border-box;
    margin: 0;

    list-style: none;
    border-bottom: solid 1px rgba(#000, 0.1);
    padding-inline-start: 0;
  }

  &__tab-nav-item {
    @extend %font-sans-MD;
    box-sizing: border-box;
    margin-right: $spacing-MD * 1.25;
    padding: $spacing-SM $spacing-XS;

    color: $text-dark-primary;
    text-decoration: none;

    border-bottom: solid 3px transparent;
    cursor: pointer;
  }

  &__tab-nav-item--active {
    color: $dh-red;

    border-bottom: solid 3px $dh-red;
  }

  &__list-cells {
    @extend %font-sans-LG;
    display: grid;
    grid-area: content;
    grid-template-areas: 'icon name . .';
    grid-template-columns: auto 1fr auto;
    align-items: center;

    color: $text-dark-secondary;

    border-bottom: $light-grey;
    cursor: pointer;
  }

  &__table {
    position: relative;

    grid-area: table;
    height: 65vh;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    & .infinite-scroll-component {
      padding-bottom: 80px;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 1;

      min-width: 20%;
      max-width: 40%;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-left: $spacing-SM;

      color: $text-dark-secondary;
      font-weight: 300;
      text-align: left;

      background-color: #f2f2f2;
      background-color: $light-grey;
    }

    table {
      position: relative;

      width: 100%;
      overflow: visible;

      table-layout: auto;
      border-collapse: collapse;
    }

    tbody {
      overflow-x: hidden; /* Hide the horizontal scroll */
      overflow-y: auto; /* Trigger vertical scroll    */
    }
  }

  &__table-labels {
    @extend %font-sans-MD;
    color: $text-dark-secondary;
  }

  &__table-label {
    padding-bottom: $spacing-SM;

    text-align: left;

    &:last-child {
      text-align: right;
    }
  }

  &__table-label-icon {
    width: 60px;
  }

  &__list-item {
    grid-column-gap: 20px;
    grid-template-areas: 'content project-id options';
    grid-template-rows: auto;
    grid-template-columns: 1fr auto auto auto 60px;

    background-color: white;
    border-bottom: solid 2px #eaeaea;
  }

  &__list-item--clickable {
    cursor: pointer;

    &:hover {
      background-color: $item-hover;
    }
  }

  &__list-item--not-clickable {
    cursor: pointer;
  }

  &__list-item-icon {
    position: absolute;
    left: 6px;

    grid-area: icon;
    width: 54px;
    height: 34px;
    margin: 0 $spacing-SM * 1.35;
    margin-bottom: -6px;

    opacity: 0.34;
  }

  &__list-item-cell {
    @extend %font-sans-MD;
    height: $item-height;
    padding-right: $spacing-SM;
    overflow: hidden;

    color: $text-dark-primary;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;

    .options-menu__icon {
      margin-right: 10px;
      margin-left: auto;
    }

    &:first-child {
      padding-left: $spacing-LG * 1.75;
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__list-item-options {
    display: flex;
    grid-area: options;
    align-items: center;
    justify-content: flex-end;
    height: $item-height;
    margin-top: auto;
    margin-right: $spacing-SM;
    margin-bottom: auto;
    margin-left: $spacing-MD;
  }

  &__projects-container {
    height: 100%;
  }

  &__avatar {
    position: absolute;
    right: 10px;

    display: grid;
    grid-area: right;
    align-content: center;

    &:hover {
      cursor: pointer;
    }

    &::before {
      @extend %icon;
      font-size: 50 * $base-unit;

      content: 'account_circle';
    }
  }

  &__empty-state-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $spacing-XL;

    background-color: #ededed;
    box-shadow: 0 0 5px #ededed;
  }

  &__empty-state-text {
    @extend %display-sans-MD;
    color: rgb(0 0 0 / 34%);
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.lds-ellipsis {
  position: relative;

  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;

  width: 13px;
  height: 13px;

  background: $black-64;
  border-radius: 50%;

  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;

  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;

  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;

  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;

  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}
