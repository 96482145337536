@import './../../../style/foundation/typography';
@import './../../../style/foundation/spacings';
@import './../../../style/foundation/colors';

.mdc-tab-bar {
  box-sizing: border-box;
  max-height: 34px;
  overflow: hidden;
}

.tab-bar {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  margin-bottom: $spacing-SM * 1.5;

  background-color: $super-light-grey;
  border-radius: 5px;

  &__annotation {
    @extend %font-sans-SM;
    padding-top: $spacing-SM;

    color: $text-dark-secondary;
  }

  &__scroll-area,
  &__scroll-content,
  &__scroll-tab,
  .mdc__scroll-tab {
    display: inline-flex;
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    overflow: hidden;
  }

  &__unit {
    @extend %font-sans-MD;
    margin-top: $spacing-XS;

    color: $text-dark-secondary;
    text-align: left;
  }

  &__scroller,
  .mdc-tab-scroller {
    width: 100%;
    height: 100%;
  }

  .mdc-tab-scroller__scroll-area--scroll {
    width: 100%;
    overflow: hidden;
  }

  &__label {
    @extend %font-sans-MD;
    z-index: 20;

    align-items: center;
    box-sizing: border-box;
    height: 0 * $base-unit;

    color: $text-dark-secondary;
    font-weight: 400;
    line-height: 0px;
    letter-spacing: 0;
    text-transform: none;
  }

  &__tab.mdc-tab {
    display: flex;
    align-items: center;
    height: 29px !important;
    margin: 2px;
    padding-right: 4px !important;
    padding-left: 4px !important;
    overflow: visible;

    &--active {
      border-radius: 5px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);

      .tab-bar__label {
        color: $text-dark-primary;
        font-weight: 500 !important;
      }
    }

    .mdc-tab-indicator {
      align-items: center;
      height: 100%;

      border: none;

      &::after {
        position: absolute;
        bottom: -5px;

        width: 70%;
        height: 2px;

        box-shadow: 0 0 0 0 $primary;

        transition-timing-function: ease-in;
        transition-duration: 0.25s !important;

        content: '';
      }
    }

    &:focus {
      transition-timing-function: ease-in;
      transition-duration: 0.25s !important;

      .mdc-tab-indicator {
        &::after {
          position: absolute;
          bottom: -5px;

          width: 70%;
          height: 2px;

          box-shadow: 0 -2px 0 0 $primary;

          transition-timing-function: ease-in;

          content: '';
        }
      }
    }
  }

  .mdc-tab__content,
  .mdc-tab-indicator__content,
  .mdc-tab-indicator__content--underline {
    align-items: center;
    height: 100%;
  }

  .mdc-tab-indicator__content--underline {
    display: inline-block;
    height: 100%;

    border: none;

    &::before {
      z-index: -20;

      display: inline-block;
      width: 100%;
      height: 100%;

      background-color: white;
      border-radius: 5px;

      content: '';
    }
  }

  .mdc-tab__ripple {
    display: none;
    box-sizing: border-box;
  }
}
