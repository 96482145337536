// placeholder
@import './../style/foundation/typography';
@import './../style/foundation/spacings';
@import './../style/foundation/colors';

.information-dialog {
  @include respond-to('xl') {
    grid-template-rows: 114px 1fr;
    grid-template-columns: minmax(260px, auto) 1fr;
  }
  position: absolute;
  top: 0;
  bottom: 0;

  display: grid;
  grid-template-areas: 'auto auto' 'content content';
  grid-template-rows: 100px 1fr;
  grid-template-columns: minmax(240px, auto) 1fr;
  width: 100%;
  padding: 0;

  &__image {
    max-width: 600px;
    max-height: 300px;
  }

  &--navigation {
    grid-template-areas: 'auto auto' 'nav content';
  }

  &__sub-headline {
    @extend %display-sans-LG;
    margin-top: $spacing-MD * 1.5;
    margin-bottom: $spacing-SM;

    color: $text-dark-primary;
    font-weight: 400;
  }

  &__sub-headline + img {
    margin-top: 0;
  }

  &__content {
    grid-area: content;
    padding-right: $spacing-MD * 1.5;
    padding-left: $spacing-MD * 1.5;
    overflow-y: auto;
  }

  &__nav-container {
    grid-area: nav;
    padding-top: $spacing-MD;
    overflow-y: auto;

    background-color: #f5f5f5;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  &__nav-item--active + div {
    box-shadow: none;
  }

  &__nav-item {
    @extend %font-sans-LG;
    position: relative;

    width: 75%;
    margin-top: $spacing-XS;
    margin-bottom: $spacing-XS;
    padding: $spacing-XS $spacing-MD * 1.25;

    color: $text-dark-secondary;

    box-shadow: inset 0 1px 0 rgb(0 0 0 / 5%);
    cursor: pointer;

    transition-duration: 0.1s;

    &:first-child {
      box-shadow: none;
    }

    &:hover {
      color: $text-dark-primary;

      background-color: white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);

      + div {
        box-shadow: none;
      }
    }

    &::before {
      position: absolute;
      top: 15%;
      left: -4px;

      width: 4px;
      height: 70%;

      background-color: $dh-red;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      content: '';
    }
  }

  &__nav-item--active {
    position: relative;

    color: $dh-red;

    background-color: #fff;
    border-top: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);

    transition-duration: 0.3s;

    &:first-child {
      box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
    }

    &:hover {
      color: $dh-red;
    }

    &::before {
      position: absolute;
      left: 0;

      width: 4px;
      height: 70%;

      background-color: $dh-red;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      transform: translateX(0);

      transition-timing-function: ease-out;
      transition-duration: 0.25s;

      content: '';
    }
  }

  .inactive {
    @extend %font-sans-LG;
    position: relative;

    width: 75%;
    margin-top: $spacing-XS;
    margin-bottom: $spacing-XS;
    padding: $spacing-XS $spacing-MD * 1.25;

    color: $dark-grey !important;

    box-shadow: inset 0 1px 0 rgb(0 0 0 / 5%);
    cursor: pointer;

    transition-duration: 0.1s;

    &:first-child {
      box-shadow: none;
    }

    &:hover {
      color: $text-dark-primary;

      background-color: white;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);

      + div {
        box-shadow: none;
      }
    }

    &::before {
      position: absolute;
      top: 15%;
      left: -4px;

      width: 4px;
      height: 70%;

      background-color: $dh-red;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      content: '';
    }
  }

  &__nav-item--active.inactive {
    position: relative;

    color: $dark-grey !important;

    background-color: #fff;
    border-top: none;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);

    transition-duration: 0.3s;

    &:first-child {
      box-shadow: 0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%);
    }

    &:hover {
      color: $dark-grey !important;
    }

    &::before {
      position: absolute;
      left: 0;

      width: 4px;
      height: 70%;

      background-color: $dark-grey !important;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      transform: translateX(0);

      transition-timing-function: ease-out;
      transition-duration: 0.25s;

      content: '';
    }
  }

  #dialog-content.mdc-dialog__content {
    img {
      margin-bottom: $spacing-SM;
    }
  }

  &__legend-list {
    display: grid;
    grid-gap: 4px;
    grid-template-columns: auto 1fr;

    dd {
      position: relative;
    }

    dd::before {
      position: absolute;
      left: -25px;

      content: '=';
    }
  }
}
