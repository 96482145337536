@import './../style/foundation/colors';
@import './../style/foundation/typography';
@import './../elements/Button';

.tab-nav-section {
  &__tab-nav {
    display: flex;
    grid-area: tab-nav;
    box-sizing: border-box;
    margin: 0;
    margin-bottom: $spacing-SM * 1.5;

    list-style: none;
    border-bottom: solid 1px rgba(#000, 0.1);
    padding-inline-start: 0;
  }

  &__tab-nav-item {
    @extend %font-sans-MD;
    box-sizing: border-box;
    margin-right: $spacing-MD * 1.25;
    padding: $spacing-SM $spacing-XS;

    color: $text-dark-primary;
    text-decoration: none;

    border-bottom: solid 3px transparent;
    cursor: pointer;
  }

  &__tab-nav-item--active {
    color: $dh-red;

    border-bottom: solid 3px $dh-red;
  }

  &__tab-nav-item--single-tab {
    color: $text-dark-secondary;

    cursor: default;

    pointer-events: none;
  }
}
